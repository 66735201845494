import { createSelector, createSlice } from "@reduxjs/toolkit";

const clientServiceSlice = createSlice({
  name: "clientService",
  initialState: {
    isSubmitting: false,
  },

  reducers: {
    submit: (state) => {
      state.isSubmitting = true;
    },
    resetSubmitFlag: (state) => {
      state.isSubmitting = false;
    },
    setClientService: (state, action) => {
      state.clientService = {
        attributes: [],
        requiredResourceTypes: [],
        ...action.payload,
      };
    },
    setClientServiceClient: (state, action) => {
      if (state.clientService) {
        state.clientService.client = action.payload;
      }
    },

    setClientServiceWithClient: (state, action) => {
      state.clientService = {
        attributes: [],
        requiredResourceTypes: [],
        ...action.payload.clientService,
      };
      state.clientService.client = action.payload.client;
    },

    clearClientServiceExceptClient: (state, action) => {
      const { client } = state.clientService;
      state.clientService = {
        attributes: [],
        requiredResourceTypes: [],
        client,
        ...action.payload,
      };
    },
    deleteAttribute: (state, action) => {
      const attributeIdToDelete = action.payload;
      if (state.clientService && state.clientService.attributes) {
        state.clientService.attributes = state.clientService.attributes.filter(
          (attribute) => attribute.id !== attributeIdToDelete
        );
      }
    },
    deleteResourceType: (state, action) => {
      const resourceTypeIdToDelete = action.payload;
      if (state.clientService && state.clientService.requiredResourceTypes) {
        state.clientService.requiredResourceTypes =
          state.clientService.requiredResourceTypes.filter(
            (resourceType) => resourceType.id !== resourceTypeIdToDelete
          );
      }
    },
    toggleClientServiceStatus: (state) => {
      state.clientService.isActive = !state.clientService.isActive;
    },
    toggleClientServiceResourceStatus: (state, action) => {
      const { id } = action.payload;

      const resource = state.clientService.requiredResourceTypes.find(
        (resource) => resource.id === id
      );

      if (resource) {
        resource.autoAssign = !resource.autoAssign;
      }
    },
    addAttribute: (state, action) => {
      state.clientService.attributes.push({
        ...action.payload,
      });
    },
    updateAttribute: (state, action) => {
      const index = state.clientService.attributes.findIndex(
        (attr) => attr.id === action.payload.id
      );
      if (index >= 0) {
        state.clientService.attributes[index] = { ...action.payload };
      }
    },
    addResource: (state, action) => {
      const { requiredResource } = action.payload;
      state.clientService.requiredResourceTypes.push(requiredResource);
    },

    updateResource: (state, action) => {
      const { updatedResource } = action.payload;

      const index = state.clientService.requiredResourceTypes.findIndex(
        (resource) => resource.id === updatedResource.id
      );

      if (index >= 0) {
        state.clientService.requiredResourceTypes[index] = updatedResource;
      } else {
        state.clientService.requiredResourceTypes.push(updatedResource);
      }
    },
  },
});

export const {
  submit,
  resetSubmitFlag,
  setClientService,
  deleteAttribute,
  deleteResourceType,
  toggleClientServiceStatus,
  addAttribute,
  updateAttribute,
  addResource,
  updateResource,
  setClientServiceClient,
  clearClientServiceExceptClient,
  setClientServiceWithClient,
  toggleClientServiceResourceStatus,
} = clientServiceSlice.actions;

export default clientServiceSlice.reducer;

export const isSubmitting = (state) => state.clientService.isSubmitting;

export const clientServiceSelector = (state) =>
  state.clientService.clientService;

export const clientResourcesSelector = (state) =>
  state.clientService.clientService.client.resources;

export const selectRequiredResourceTypeById = (state, id) =>
  state.clientService.clientService.requiredResourceTypes.find(
    (resource) => resource.id === id
  );

export const selectClientService = createSelector(
  clientServiceSelector,
  (state) => state
);
