import styled from "styled-components";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { cardColors } from "themes/secondaryTheme/secondaryThemeColors";

export const CategoryCardContainer = styled.div`
  border-radius: 12px;
  padding: 16px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 130px;
  cursor: pointer;
  transition: transform 0.2s ease;
  box-shadow: 1px 1px 8px ${(props) => hexToRGB(props?.theme?.colors?.black, 0.1)};
  background-color: ${(props) => cardColors[props.index % cardColors.length].background};

  &:hover {
    transform: translateY(-3px);
  }
`;

export const ImageIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 144px;
`;

export const StyledCardText = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  position: relative;
  display: inline-block;
  color: ${(props) => cardColors[props.index % cardColors.length].text};
`;

export const LineContainer = styled.div`
  width: 130px;
  height: 1px;
  background-color: ${(props) => cardColors[props.index % cardColors.length].text};
  margin-top: 10px;
`;
