import React from "react";
import { USER_ROLES } from "constants/userRoles";
import RequireAuthorization from "../RequireAuthorization";
import { Navigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useAuth } from "hooks/useAuth";

const RequireClientAdmin = () => {
  const { clientId } = useParams();
  const { isSuperAdmin } = useAuth();
  const user = useSelector(selectCurrentUser);
  const requiredRoles = [
    USER_ROLES.CLIENT_ADMIN.name,
    USER_ROLES.SUPER_ADMIN.name,
  ];
  if (clientId !== user?.clientId && !isSuperAdmin) {
    return <Navigate to={PAGES.BASE.route} />;
  } else {
    return <RequireAuthorization roles={requiredRoles} />;
  }
};

export default RequireClientAdmin;
