import Button from "components/Button/Button";
import styled from "styled-components";

export const LogInButtonContainer = styled(Button)`
  font-size: 24px;
  font-weight: 600;
  line-height: 32.74px;
  background-color: ${(props) =>
    props?.theme?.colors?.leftAuthContentBackground};
  color: ${(props) => props?.theme?.colors?.iconColor};
  width: 100%;
  &:hover {
    background-color: ${(props) =>
      props?.theme?.colors?.leftAuthContentBackground};
  }
`;
