import React, { useEffect, useState } from "react";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { editDescription, toggleFlag } from "features/clients/clientSlice";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { descriptionInitialValue } from "initialValues/clientDescriptionInitialValues";
import { AccordionStyled, TypographyStyled } from "components/ScheduleEditor/ScheduleEditor.styled";
import TextFieldStyled from "./DescriptionAccordion.styled";
import clientDescriptionValidation from "validations/clientDescriptionValidation";

export const DescriptionAccordion = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const formik = useFormik({
    initialValues: descriptionInitialValue(props?.description),
    validationSchema: clientDescriptionValidation,
    onSubmit: (values) => {
      dispatch(editDescription(values.description));
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    dispatch(editDescription(props?.description || ""));
  }, [dispatch]);

  const handleChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("description", value);
    dispatch(editDescription(value));
    dispatch(toggleFlag());
  };

  const handleAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <AccordionStyled expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TypographyStyled>
        {t("common.description")}
        </TypographyStyled>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={formik.handleSubmit}>
          <TextFieldStyled
            fullWidth
            multiline
            rows={4}
            name="description"
            value={formik.values.description}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && t(formik.errors.description)
            }
            helperText={formik.touched.description && t(formik.errors.description)}
          />
        </form>
      </AccordionDetails>
    </AccordionStyled>
  );
};

DescriptionAccordion.propTypes = {
  description: PropTypes.string,
};