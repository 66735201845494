import React from "react";
import PropTypes from "prop-types";
import { PlusButtonContainer, PlusIcon } from "./PlusButtonRenderer.styled";

const PlusButtonRenderer = (props) => {
  return (
    <PlusButtonContainer
      startIcon={<PlusIcon />}
      onClick={props.onClick}
    />
  );
};

PlusButtonRenderer.propTypes = {
  onClick: PropTypes.func,
};

export default PlusButtonRenderer;