import React from "react";
import { useTranslation } from "react-i18next";
import OrDividerStyled from "./OrDivider.styled";
import LongDash, { StyledTypography } from "../LongDash/LongDash.styled";

const OrDivider = () => {
  const { t } = useTranslation();

  return (
    <OrDividerStyled>
      <LongDash /> <StyledTypography>{t("common.or")} </StyledTypography>
      <LongDash />
    </OrDividerStyled>
  );
};

export default OrDivider;
