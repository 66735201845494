import { Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as LandingPageCalendarImage } from "../../assets/images/svg/landing-page-calendar-image.svg";
import { ReactComponent as LandingPageCalendarImageSecond } from "../../assets/images/svg/landing-page-calendar-image-second.svg";
import { ReactComponent as LandingPageCalendarImageSecondNotLogged } from "../../assets/images/svg/landing-page-calendar-image-second-notlogged.svg";
import { RightArrowStyled } from "components/ClientPanelForUsers/GalleryContent/GalleryContent.styled";
import { Link } from "react-router-dom";

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 20px;
  gap: 16px;
`;
export const CardSectionContainer = styled.div`
  display: flex;
  width: calc(100% - 45px);
  gap: 24px;
  margin: 0 45px 0 30px;
`;
export const CardFirstStyled = styled.div`
  display: flex;
  width: 56%;
  background-color: ${(props) => props?.theme?.colors?.textColor2};
  border: 1px solid ${(props) => props?.theme?.colors?.textBlack};
  border-radius: 12px;
  padding: 24px;
  gap: 24px;
`;

export const CardSecondStyled = styled(Link)`
  display: flex;
  width: 44%;
  background-color: ${(props) => props?.theme?.colors?.purpleBoxBackgournd};
  border: 1px solid ${(props) => props?.theme?.colors?.secondaryColor};
  border-radius: 12px;
  padding: 24px;
  gap: 24px;
  text-decoration: none;
  cursor: pointer;
`;

export const CardImageLeft = styled.img`
  align-self: left;
  min-width: 120px;
  min-height: 140px;
`;

export const CardImageRight = styled.img`
  align-self: left;
  min-width: 85px;
  min-height: 96px;
`;

export const CardImageContainer = styled(CardImageLeft).attrs({
  as: LandingPageCalendarImage,
})``;

export const CardImageContainerSecond = styled(CardImageRight).attrs({
  as: LandingPageCalendarImageSecond,
})``;

export const CardImageContainerSecondNotLogged = styled(CardImageRight).attrs({
  as: LandingPageCalendarImageSecondNotLogged,
})``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CardFirstTitle = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.white};
  font-size: 24px;
  font-weight: 700;
`;

export const CardFirstText = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.white};
  font-size: 16px;
  font-weight: 400;
`;

export const CardSecondTitle = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 18px;
  font-weight: 700;
`;

export const CardSecondText = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 12px;
  font-weight: 400;
`;

export const ArrowStyled = styled(RightArrowStyled)`
  display: flex;
  align-self: flex-end;
`;

export const TitleContainer = styled.div`
  text-align: left;
  margin-bottom: 16px;
  padding-left: 16px;
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.timeSlotText};
`;

export const ServiceCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding-left: 16px;
`;

export const CategoryList = styled.div`
  display: flex;
  gap: 16px;
  overflow: hidden;
  margin: 0 16px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;

export const ScrollButton = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  width: 1px;
  height: 130px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  ${(props) => (props.left ? "left: 0;" : "right: 0;")}
`;
