import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.resources.name",
    field: "name",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "common.status",
    field: "status",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 2,
    headerName: "common.labelEmail",
    field: "email",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 3,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer
        params={params}
        onClick={params.onClickEdit}
        onClickDelete={params.onClickDelete}
        value={"superAdmin.table.editButton"}
      />
    ),
  },
];
