import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const RightAuthContainer = styled.div`
  max-width: 1200px;
  width: 666px;
  height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 56px 48px 10px 48px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const UpperContainerLogIn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  gap: ${(props) => (props?.gap ? "0px" : "16px")};
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 275px;
          }
        `
      : css`
          & > div {
            width: 566px;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props?.flexDirection};
  text-align: ${(props) => props?.textAlign};
  font-size: ${(props) => props?.fontSize || "16px"};
  font-weight: ${(props) => props?.fontWeight};
  justify-content: ${(props) => props?.justifyContent};
  color: ${(props) => props?.color};
  align-items: center;
  gap: 5px;
`;

export const RegTitleContainer = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
`;

export const SentCodeMessageContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const SentCodeBtnContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const NextBackBtnsContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props?.twoButtons ? "space-between" : "right"};
`;

export const BottomTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
`;

export const FirstText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Urbanist" !important;
`;

export const ForgotPWLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  padding-right: 35px;
  font-family: "Urbanist" !important;
  color: ${(props) => props?.theme?.colors?.text3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const LogRegLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.color};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  padding-top: 25px;
  font-family: "Baloo 2" !important;
`;

export const LogTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const OrDividerGoogleBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

export const JustifiedRightItems = styled.div`
  display: flex;
  justify-content: right;
`;
