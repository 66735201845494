import styled from "styled-components";
import { Button } from "@mui/material";

const ButtonStyled = styled(Button)`
  background-color: ${(props) => props?.bgColor};
  color: ${(props) => props?.textColor};
  font-size: ${(props) => props?.fontSize};
  padding: ${(props) => props?.padding};
  max-width: ${(props) => props?.maxWidth};
  max-height: ${(props) => props?.maxHeight};
  border-radius: ${(props) => props?.borderRadius};
  text-transform: ${(props) => props?.textTransform};
  position: ${(props) => (props?.isClient ? "absolute" : "default")};

  border: ${(props) =>
    props?.border ? `2px solid ${props.theme.colors.text3}` : "none"};

  right: ${({ isClient, leftClientPageButton }) =>
    isClient ? (leftClientPageButton ? "10px" : "150px") : "auto"};
  &:hover {
    background-color: ${(props) => props?.bgColorHover};
  }
`;

export default ButtonStyled;
