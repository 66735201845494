import React from "react";
import { ClientInformationStyled } from "./ClientInformation.styled";
import PropTypes from "prop-types";
import { LeftInfoContent } from "./LeftInfoContent/LeftInfoContent";
import { RightInfoContent } from "./RightInfoContent/RightInfoContent";
import { useSelector } from "react-redux";
import { selectClient } from "features/clients/clientSlice";

export const ClientInformation = () => {
  const client = useSelector(selectClient);

  return (
    <ClientInformationStyled>
      <LeftInfoContent
        client={client}
        logoId={client?.logoId}
      ></LeftInfoContent>
      <RightInfoContent
        description={client?.description}
        isActive={client?.isActive}
        clientId={client?.id}
        workingDaysHours={client?.workingDaysHours}
        publicAppointmentEnabled={client?.publicAppointmentEnabled}
        appointmentStartTimeInMinutes={client?.appointmentStartTimeInMinutes}
      ></RightInfoContent>
    </ClientInformationStyled>
  );
};

ClientInformation.propTypes = {
  client: PropTypes.any,
};
export default ClientInformation;
