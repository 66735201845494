import styled from "styled-components";
import { Box, Button, Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { LogTitleContainer } from "components/RightAuthContent/RightAuthContent.styled";
import BackButton from "components/RightAuthContent/Buttons/BackButton/BackButton";
import { Logo } from "components/Header/Header.styled";

export const ForgotPasswordContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 50px 35px;
  border-radius: 16px;
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  width: 470px;
  ${(props) => (!props?.sentEmail ? "height: 476px" : "")};
  gap: 35px;
  background-color: ${(props) => props?.theme?.colors?.white};
`;

export const ResetPasswordContainer = styled(ForgotPasswordContainer)`
  height: 535px;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const LogoStyled = styled(Logo)`
  width: 170px;
  height: 45px;
`;

export const TitleAndInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const ForgotPasswordTitle = styled(Typography)`
  font-weight: 600;
  font-size: 32px;
  line-height: 38.4px;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 396px;
    height: 48px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  padding: 10px 32px;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.28px;
  text-transform: none;
  background-color: ${(props) =>
    props?.theme?.colors?.leftAuthContentBackground};
  color: ${(props) => props?.theme?.colors?.iconColor};
  &:hover {
    background-color: ${(props) =>
      props?.theme?.colors?.leftAuthContentBackground};
  }
`;

export const MessageAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const SentEmailMessage = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => props?.theme?.colors?.textBlack};
`;

export const LogTitleContainerStyled = styled(LogTitleContainer)`
  align-self: flex-start;
`;

export const BackButtonStyled = styled(BackButton)`
  width: min-content;
  padding: 8px 16px 8px;
  gap: 10px;
`;
