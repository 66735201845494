import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import brushEffectGreen from "../../assets/images/svg/brush-effect-green.svg";
import brushEffectPuple from "../../assets/images/svg/brush-effect-purple.svg";
import { ReactComponent as ChevronImg } from "../../assets/images/svg/chevron-right-solid.svg";
import { hexToRGB } from "util/colorHelper";
import { Link } from "react-router-dom";

export const StyledSmallCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 73 32px;
  width: 480px;
  height: 381px;
  box-shadow: 0 4px 8px ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  border-radius: 16px;
  background-color: ${({ purpleCard, theme }) =>
    purpleCard
      ? `${theme.colors.purpleBoxBackgournd}`
      : `${theme.colors.greenBoxBackground}`};
  border: 2px solid;
  border-color: ${({ purpleCard, theme }) =>
    purpleCard
      ? `${theme.colors.secondaryColor}`
      : `${theme.colors.primaryColor}`};
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 100px;
`;

export const LogoContainer = styled.div`
  margin-top: 40px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 100px;
`;

export const AlreadyGotAccountContainer = styled.div`
  padding-top: 30px;
`;

export const AlreadyGotAccount = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Urbanist" !important;
`;

export const LogLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  font-family: "Urbanist" !important;
  color: ${(props) => props.color};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledBigCardMiddle = styled(Card)`
  height: 759px;
  width: 1200px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
`;

export const StyledBigCard = styled(Card)`
  height: 759px;
  width: 1200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
`;

export const StyledTypography = styled(Typography)`
  text-align: ${({ textStyle }) => (textStyle ? "left" : "center")};
  font-size: ${({ textStyle }) => (textStyle ? "20px" : "32px")};
  font-weight: ${({ textStyle }) => (textStyle ? "400" : "600")};
  font-family: ${({ textStyle }) =>
    textStyle ? "Urbanist !important" : "Nunito !important"};
  margin-left: 30px;
  margin-right: 30px;
  font-weight: ${({ titleStyle }) => (titleStyle ? "bold" : "light")};
`;

export const StyledButton = styled(Button)`
  width: 416px;
  height: 56px;
  padding: 16 204px;
  justify-content: center;
  background-color: ${({ purpleButton, theme }) =>
    purpleButton ? theme.colors.secondaryColor : theme.colors.primaryColor};
  color: ${(props) => props?.theme?.colors?.completedRegistration};
  border-radius: 8px;
  text-transform: none;
  font-size: 24px;
  font-weight: 600px;
  line-height: 32.74px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ purpleButton, theme }) =>
      purpleButton
        ? theme.colors.secondaryColorHover
        : theme.colors.primaryColorHover};
  }

  & > svg {
    margin-left: 8px;
  }
`;

export const StyledBrushEffect = styled.span`
  background-image: url(${({ color }) =>
    color ? brushEffectPuple : brushEffectGreen});
  background-image: url(${({ color }) =>
    color ? brushEffectPuple : brushEffectGreen});
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 0;
  padding: 15px;
`;

export const CheckIcon = styled(ChevronImg)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.black};
    fill: ${(props) => props?.theme?.colors?.black};
  }
  width: 24px;
  height: 24px;
  display: inline-block;
`;
