import React from "react";
import PropTypes from "prop-types";
import {
  ClockIconStyled,
  ContentContainer,
  DurationAndPriceContainer,
  DurationContainer,
  GridContainer,
  ItemContainer,
  PriceContainer,
  RowContainer,
  ScheduleButton,
  ServiceIconStyled,
  ServiceNameText,
  ServiceDurationAndPriceText,
  WalletIconStyled,
} from "./ListingServicesComponent.styled";
import { useTranslation } from "react-i18next";
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import { useNavigate, useParams } from "react-router-dom";

const ListingServicesComponent = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rows = [];
  for (let i = 0; i < props.items.length; i += props.itemsPerRow) {
    rows.push(props.items.slice(i, i + props.itemsPerRow));
  }

  const { serviceCategoryId } = useParams();

  const onClickSchedule = (service) => {
    const pathParams = {
      serviceCategoryId: serviceCategoryId,
      clientId: props?.clientId,
      clientServiceId: service.id,
    };
    const resolvedRoute = replaceInRoute(
      PAGES.SCHEDULE_APPOINTMENT.route,
      pathParams
    );
    navigate(resolvedRoute);
  };

  return (
    <GridContainer>
      {rows.map((row, rowIndex) => (
        <RowContainer key={rowIndex}>
          {row.map((item, itemIndex) => (
            <ItemContainer key={itemIndex}>
              <ServiceIconStyled />
              <ContentContainer>
                <ServiceNameText>{item.name}</ServiceNameText>
                <DurationAndPriceContainer>
                  <DurationContainer>
                    <ClockIconStyled />
                    <ServiceDurationAndPriceText>
                      {item.durationInMinutes}min
                    </ServiceDurationAndPriceText>
                  </DurationContainer>
                  <PriceContainer>
                    <WalletIconStyled />
                    <ServiceDurationAndPriceText>
                      {item.price}din
                    </ServiceDurationAndPriceText>
                  </PriceContainer>
                </DurationAndPriceContainer>
                <ScheduleButton
                  onClick={() => onClickSchedule(item)}
                  value={t("common.schedule")}
                ></ScheduleButton>
              </ContentContainer>
            </ItemContainer>
          ))}
        </RowContainer>
      ))}
    </GridContainer>
  );
};

ListingServicesComponent.propTypes = {
  items: PropTypes.array,
  itemsPerRow: PropTypes.number,
  clientId: PropTypes.string,
};

export default ListingServicesComponent;
