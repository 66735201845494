import { Typography } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;
`;

export const ContentContainer = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
