import React from "react";
import PropTypes from "prop-types";
import { useGetAllCitiesQuery } from "features/lookup/lookupSlice";
import { SingleFormStyled } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomSelectField from "components/InputFields/CustomFields/CustomSelectField";
import { useTranslation } from "react-i18next";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const ClientCityField = (props) => {
  const handleChange = (changedValue) => {
    props?.formik?.setFieldValue("city", changedValue);
  };

  const { t } = useTranslation();

  const { data, isLoading } = useGetAllCitiesQuery();

  return (
    <SingleFormStyled>
      <CustomFieldLabel
        isRequired={false}
        label={t("superAdmin.table.clients.details.city")}
        isClient={true}
      />

      <CustomSelectField
        name={clientFormikParams.city}
        formik={props?.formik}
        placeholder={props?.formik?.values?.city}
        isClient
        height={"40px"}
        items={
          isLoading
            ? []
            : data?.data?.cityNames.map((name) => {
                return {
                  id: name,
                  name,
                };
              })
        }
        passValue={handleChange}
      />
    </SingleFormStyled>
  );
};

ClientCityField.propTypes = {
  formik: PropTypes.any,
};
