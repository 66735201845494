import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../InputFields/CustomFields/CustomTextField";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams";
import { Box } from "@mui/material";

const SentCodeField = (props) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    if (props?.clearErrorMessage) props?.clearErrorMessage();
    props?.formik?.handleChange(event);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomTextField
        type={props.type}
        name={props.name}
        placeholder={t(props.placeholder)}
        formik={props.formik}
        width={props.width}
        onChange={handleChange}
        helperText={props?.helperText}
      />
    </Box>
  );
};

SentCodeField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
};

SentCodeField.defaultProps = {
  label: "code.labelSentEmailCodeInput",
  name: FormikParams.sentEmailCode,
  placeholder: "common.codeInput",
};

export default SentCodeField;
