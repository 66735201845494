import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const registerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    RegisterClient: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.registration.register,
        method: "POST",
        body: { ...data },
      }),
    }),
    CheckClientEmail: builder.query({
      query: (email) => ({
        url: apiEndpoints.registration.checkEmail.replace("{email}", email),
      }),
    }),
    CheckClientRegistrationName: builder.query({
      query: ({ name, clientId = null }) => {
        const baseUrl = apiEndpoints.registration.checkRegistrationName.replace(
          "{regName}",
          name
        );

        const url = clientId ? `${baseUrl}?clientId=${clientId}` : baseUrl;

        return { url };
      },
    }),
    AddPendingUser: builder.mutation({
      query: ({ data }) => ({
        url: apiEndpoints.registration.addPendingApplicationUser,
        method: "POST",
        body: data,
      }),
    }),
    VerifyEmail: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.registration.verifyEmail,
        method: "POST",
        body: data,
      }),
    }),
    useRequestEmailVerificationCodeMutation: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.registration.verifyEmail,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useRegisterClientMutation,
  useCheckClientEmailQuery,
  useCheckClientRegistrationNameQuery,
  useAddPendingUserMutation,
  useVerifyEmailMutation,
  useLazyCheckClientRegistrationNameQuery,
  useLazyCheckClientEmailQuery,
} = registerApiSlice;
