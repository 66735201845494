import React from "react";
import PropTypes from "prop-types";
import {
  ButtonWrapper,
  TrashIconStyled,
  EditIconStyled,
} from "components/ClientEditTab/ClientContentTab/TabContent/Lincense/ClientLicenseContent.styled";
const UpdateRenderer = (props) => {
  return (
    <ButtonWrapper>
      <EditIconStyled onClick={props?.onClick} />
      <TrashIconStyled onClick={props?.onClickDelete} />
    </ButtonWrapper>
  );
};
UpdateRenderer.propTypes = {
  onClick: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default UpdateRenderer;
