import { Switch } from "@mui/material";
import styled from "styled-components";
import ThumbIconTrue from "../../assets/images/svg/switch-check.svg";
import ThumbIconFalse from "../../assets/images/svg/switch-false-icon.svg";

export const ClientSwitchStyled = styled(Switch)`
  margin: 10px;
  padding: 8px;
  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.notificationRed};
    border-radius: 11px;
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(50%);
      width: 16px;
      height: 16px;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.notificationGreen};
  }

  & .MuiSwitch-thumb {
    box-shadow: none;
    width: 16px;
    height: 16px;
    margin: 2px;
    color: ${({ theme }) => theme.colors.white};

    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${ThumbIconFalse});
  }

  & .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background-image: url(${ThumbIconTrue});
  }`;