import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AttributeContainer,
  AttributesContentStyled,
  CheckImgStyled,
  ChooseAttributeLabel,
  XImgStyled,
} from "./AttributesContent.styled";
import SwitchButton from "components/SwitchButton/SwitchButton";
import { secondaryThemeColors } from "themes/secondaryTheme/secondaryThemeColors";

const AttributesContent = (props) => {
  const { t } = useTranslation();
  //const [activeAttributes, setActiveAttributes] = useState([]);
  return (
    <AttributesContentStyled>
      <ChooseAttributeLabel>
        {t("scheduleAppointment.chooseAttribute")}
      </ChooseAttributeLabel>
      {props?.attributes?.map((attribute) => (
        <AttributeContainer key={attribute.id}>
          {attribute.name}
          <SwitchButton
            onSelect={() => props.onSelectAttribute(attribute)}
            onDeselect={() => props.onDeselectAttribute(attribute)}
            activeImg={<CheckImgStyled />}
            inactiveImg={<XImgStyled />}
            activeColor={secondaryThemeColors.statusActiveButton}
            inactiveColor={secondaryThemeColors.paginationSelected}
          />
        </AttributeContainer>
      ))}
    </AttributesContentStyled>
  );
};

AttributesContent.propTypes = {
  attributes: PropTypes.any,
  onSelectAttribute: PropTypes.func,
  onDeselectAttribute: PropTypes.func,
};

export default AttributesContent;
