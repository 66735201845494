import React from "react";
import PropTypes from "prop-types";
import {
  HeaderProfileMenu,
  HeaderProfileMenuItem,
  HeaderProfilePopoverContentContainer,
} from "./HeaderProfilePopoverContent.styled";
import { useTranslation } from "react-i18next";
import { logOut } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import { logoutUserRequest } from "request/loginRequest";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useAuth } from "hooks/useAuth";

const HeaderProfilePopoverContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useAuth();
  const handleLogout = async () => {
    await logoutUserRequest();
    dispatch(logOut());
    navigate(PAGES.LOGIN.route);
  };

  return (
    <HeaderProfilePopoverContentContainer>
      <HeaderProfileMenu>
        {isLoggedIn && (
          <HeaderProfileMenuItem onClick={handleLogout}>
            {t("common.logout")}
          </HeaderProfileMenuItem>
        )}
        {!isLoggedIn && (
          <>
            <HeaderProfileMenuItem to={PAGES.MIDDLEPAGE.route}>
              {t("register.registerTitle")}
            </HeaderProfileMenuItem>
            <HeaderProfileMenuItem to={PAGES.LOGIN.route}>
              {t("login.logIn")}
            </HeaderProfileMenuItem>
          </>
        )}
      </HeaderProfileMenu>
    </HeaderProfilePopoverContentContainer>
  );
};

HeaderProfilePopoverContent.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
};

export default HeaderProfilePopoverContent;
