import Button from "components/Button/Button";
import styled from "styled-components";

export const ServiceParamesContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SearchForAppointmentsButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 6px 32px;
  color: ${(props) => props?.theme?.colors?.white};
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.darkBlue};
  }
`;
