import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  InputFieldContainer,
  InputFieldsContainer,
  OrDividerGoogleBtnContainer,
  UpperContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import FirstNameField from "components/RegistrationContent/InputFields/FirstNameField";
import LastNameField from "components/RegistrationContent/InputFields/LastNameField";
import { FormikParams } from "constants/formikParams";
import EmailField from "components/InputFields/EmailField";
import PasswordField from "components/InputFields/PasswordField";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { UserStepOneValidation } from "validations/registerUserValidation";
import OrDivider from "components/OrDivider/OrDivider";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";
import {
  useAddPendingApplicationUserMutation,
  useLazyCheckEmailQuery,
} from "features/register/userRegisterApiSlice";
import { EmailAlreadyInUse } from "constants/registrationBEerrors";
import { userColors } from "themes/secondaryTheme/secondaryThemeColors";
import { useTranslation } from "react-i18next";
import {
  setAttributesStepOne,
  selectUserAttributesStepOne,
} from "features/register/userRegisterSlice";
import { useSelector, useDispatch } from "react-redux";

const UserStepOne = (props) => {
  const { t } = useTranslation();
  const [emailNotUniqueError, setEmailNotUniqueError] = useState(null);
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] =
    useState(null);

  const [addUser] = useAddPendingApplicationUserMutation();
  const [checkEmail] = useLazyCheckEmailQuery();

  const dispatch = useDispatch();
  const userDataStepOne = useSelector(selectUserAttributesStepOne);

  const reverseMappedData = {
    firstName: userDataStepOne?.firstName,
    lastName: userDataStepOne?.lastName,
    email: userDataStepOne?.email,
    password: null,
    confirmedPassword: null,
  };

  const handleSubmit = async () => {
    const data = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      password: formik.values.password,
      confirmedPassword: formik.values.confirmedPassword,
    };

    dispatch(setAttributesStepOne(data));

    const response = await addUser({
      email: formik.values.email,
    });

    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          case EmailAlreadyInUse:
            setEmailNotUniqueError(error.message);
            break;
          default:
            alert(error.message);
            break;
        }
      });
    } else {
      props.onClickNextPageBtn();
    }
  };

  const handleBlurEmail = async (e) => {
    formik.handleBlur(e);
    const result = await checkEmail(formik.values.email);

    if (result.data.data.isEmailUnique === false) {
      setEmailNotUniqueError(t("register.emailAlreadyTaken"));
      return;
    } else {
      setEmailNotUniqueError(null);
    }
  };

  const handleGoogle = () => {};

  const formik = useFormik({
    initialValues: {
      ...(reverseMappedData ?? null),
    },
    validationSchema: UserStepOneValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const clearEmailErrorMessage = () => {
    if (emailNotUniqueError != null) {
      setEmailNotUniqueError(null);
    }
  };

  const clearPasswordConfirmErrorMessage = () => {
    if (passwordsDoNotMatchError != null) {
      setPasswordsDoNotMatchError(null);
    }
  };

  return (
    <>
      <UpperContainer>
        <InputFieldsContainer>
          <InputFieldContainer twoInputs>
            <FirstNameField formik={formik} required />
            <LastNameField formik={formik} required />
          </InputFieldContainer>
          <InputFieldContainer>
            <EmailField
              formik={formik}
              required
              helperText={emailNotUniqueError}
              clearErrorMessage={clearEmailErrorMessage}
              onBlur={handleBlurEmail}
            />
          </InputFieldContainer>
          <InputFieldContainer twoInputs>
            <PasswordField formik={formik} required />
            <PasswordField
              label="common.labelPasswordConfirmation"
              name={FormikParams.confirmedPassword}
              placeholder="common.passwordConfirmationInput"
              formik={formik}
              required
              helperText={passwordsDoNotMatchError}
              clearErrorMessage={clearPasswordConfirmErrorMessage}
            />
          </InputFieldContainer>
        </InputFieldsContainer>
        <OrDividerGoogleBtnContainer>
          <OrDivider />
          <GoogleButton onClick={handleGoogle} />
        </OrDividerGoogleBtnContainer>
      </UpperContainer>
      <LowerRegContent
        onClickNextBtn={formik.handleSubmit}
        onClickBackBtn={props.onClickBackBtn}
        currentStep={props.currentStep}
        colorPalet={userColors}
      />
    </>
  );
};

UserStepOne.propTypes = {
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  currentStep: PropTypes.number,
};

export default UserStepOne;
