import React from "react";
import DashboardContent from "components/Dashboard/DashboardContent";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const DashboardPage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<ControlTableIconSelected />} />
      <DashboardContent />
    </MainLayout>
  );
};

export default DashboardPage;
