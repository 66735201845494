import * as Yup from "yup";

export default Yup.object().shape({
  password: Yup.string()
    .required("register.inputRequired")
    .min(8, "register.passwordLength")
    .matches(/[A-Z]/, "register.passwordUppercase")
    .matches(/[a-z]/, "register.passwordLowercase")
    .matches(/[0-9]/, "register.passwordNumber")
    .matches(/[\W_]/, "register.passwordSpecial"),
  confirmedPassword: Yup.string()
    .required("register.inputRequired")
    .oneOf([Yup.ref("password"), null], "register.passwordsDoNotMatch"),
});
