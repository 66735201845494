export const ClientStepOneAtributes = {
  username: "",
  companyName: "",
  primaryService: "",
  country: "",
  city: "",
  address: "",
  countryPhoneCode: "",
  phoneNumber: "",
};

export const ClientStepTwoAtributes = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirm: "",
};

export const ClientStepThreeAtributes = {
  sentEmailCode: "",
};
