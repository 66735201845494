import React from "react";
import PropTypes from "prop-types";
import { CheckBoxStyled, CheckedIcon, UncheckedIcon } from "./CheckBox.styled";

export const CheckBox = (props) => {
  return (
    <CheckBoxStyled
      onChange={props?.onCheck}
      checked={props?.checked}
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
    />
  );
};

CheckBox.propTypes = {
  onCheck: PropTypes.func,
  checked: PropTypes.any,
};
