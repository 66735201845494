import * as Yup from "yup";

export const ClientServiceAttributeValidation = Yup.object().shape({
  name: Yup.string().required("register.inputRequired"),
  description: Yup.string().required("register.inputRequired"),
  durationInMinutes: Yup.string()
    .required("register.inputRequired")
    .matches(/^[-+]?\d+$/, "common.positiveOrNegativeNumber"),
  price: Yup.number()
    .required("register.inputRequired")
    .typeError("common.positiveNumber"),
});
