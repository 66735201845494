import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormikParams } from "constants/formikParams";
import { CountryPhoneCodeSelect } from "./PhoneField.styled";
import { useGetAllCountriesQuery } from "features/lookup/lookupSlice";
import { MenuItem } from "@mui/material";

const CountryPhoneCodeField = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const { data, isLoading } = useGetAllCountriesQuery();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.formik.setFieldValue("countryPhoneCode", event.target.value);
  };
  const items = isLoading
    ? []
    : data?.data?.countryNames?.map((country) => {
        return {
          id: country.name,
          name: country.phoneCode,
        };
      });

  useEffect(() => {
    if (!isLoading && props?.selectFirst && items?.length > 0) {
      setSelectedValue(items[0]);
      props.formik.setFieldValue("countryPhoneCode", items[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (props?.selectedCountry != "" && items?.length > 0) {
      setSelectedValue(items[0]);
      props.formik.setFieldValue("countryPhoneCode", items[0]);
    }
  }, [props.selectedCountry]);

  return (
    <CountryPhoneCodeSelect
      name={props.name}
      disabled={props.disabled}
      value={selectedValue}
      onChange={handleChange}
      formik={props.formik}
      renderValue={(selected) => {
        return selected.name;
      }}
      IconComponent={null}
    >
      {items?.map((item) => {
        return (
          <MenuItem key={item.id} value={item}>
            {item.name}
          </MenuItem>
        );
      })}
    </CountryPhoneCodeSelect>
  );
};

CountryPhoneCodeField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
  selectFirst: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedCountry: PropTypes.string,
};

CountryPhoneCodeField.defaultProps = {
  name: FormikParams.countryPhoneCode,
};

export default CountryPhoneCodeField;
