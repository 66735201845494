import React from "react";
import PropTypes from "prop-types";
import {
  LoginButton,
  RegisterButton,
  RegisterLoginOptionsContainer,
} from "./RegisterLoginOptions.styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const RegisterLoginOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickRegister = () => {
    navigate(PAGES.MIDDLEPAGE.route);
  };

  const onClickLogin = () => {
    navigate(PAGES.LOGIN.route);
  };

  return (
    <RegisterLoginOptionsContainer>
      <RegisterButton
        value={t("register.registerTitle")}
        onClick={onClickRegister}
      />
      <LoginButton value={t("login.logInTitle")} onClick={onClickLogin} />
    </RegisterLoginOptionsContainer>
  );
};

RegisterLoginOptions.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};

export default RegisterLoginOptions;
