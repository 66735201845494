import React, { useMemo, useState } from "react";
import MainLayout from "layouts/MainLayout";

import PageHeader from "components/PageHeader/PageHeader";
import Table from "components/Table/Table";
import { useGetPaginatedAppointmentsForUserQuery } from "features/appointmentSlice/appointmentApiSlice";
import userAppointmentsTableConstants from "constants/tableConstants/userAppointmentsTableConstants";
import { formatDate } from "util/dateHelpers";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { ReactComponent as AppointmentIcon } from "assets/images/svg/appointments-icon-selected.svg";
import { useTranslation } from "react-i18next";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { randomIdGenerator } from "util/randomGenerator";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import {
  MessageContainer,
  NoAppointmentsMessageContainer,
  ScheduleAppointmentButton,
} from "./AppointmentsPage.styled";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const AppointemntsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [isFilterButtonShown, setIsFilterButtonShown] = useState(false);

  const mapRowDataFunction = (rowData) =>
    rowData.data?.appointments?.map?.((appointment) => {
      return {
        date: formatDate(new Date(appointment.fromTimeUtc), "dd.MM.yyyy"),
        time:
          convertUTCToLocalTime(appointment.fromTimeUtc) +
          "-" +
          convertUTCToLocalTime(appointment.toTimeUtc) +
          "h",
        serviceName: appointment?.clientService?.name,
        clientName: appointment?.client?.fullName,
        price:
          appointment?.clientService?.price +
          appointment?.chosenAttributes?.reduce?.(
            (acc, attribute) => acc + attribute.price,
            0
          ) +
          "din",
      };
    });

  const handleEmptyTable = () => {
    return (
      <NoAppointmentsMessageContainer>
        <MessageContainer>
          {t("appointments.noAppointmentsMessage")}
        </MessageContainer>
        <ScheduleAppointmentButton
          value={t("common.scheduleAppointment")}
          onClick={() => {
            navigate(PAGES.CLIENTS.route);
          }}
        />
      </NoAppointmentsMessageContainer>
    );
  };

  const appointmentDetailsBtnClick = () => {};

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    setAppliedFilters({
      ...filters,
      location: filters?.city?.id,
      status: filters?.status?.id,
      serviceCategory: filters?.primaryService?.id,
    });
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"clientService.serviceName"}
          placeholder={"clientService.placeholderName"}
          onChange={(name) =>
            setFilters((prevState) => ({ ...prevState, name }))
          }
          value={filters?.name}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  return (
    <MainLayout>
      <PageHeader
        leftIcon={<AppointmentIcon />}
        isFilterButtonShown={isFilterButtonShown}
        filterComponents={filterComponents}
        saveFilters={saveFilters}
      />
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={userAppointmentsTableConstants}
        usePaginatedQuery={useGetPaginatedAppointmentsForUserQuery}
        rowBtnClick={appointmentDetailsBtnClick}
        handleEmptyTable={handleEmptyTable}
        numberOfCharactersPerCell={30}
        appliedFilters={appliedFilters}
        setIsFilterButtonShown={(newValue) => setIsFilterButtonShown(newValue)}
        userId={user.id}
        t={t}
      />
    </MainLayout>
  );
};

export default AppointemntsPage;
