import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const registerUserSlice = createSlice({
  name: "user",
  initialState: {
    attributesStepOne: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    attributesStepTwo: {
      country: "",
      city: "",
      address: "",
    },
    id: "",
  },
  reducers: {
    setUserId: (state, action) => {
      state.id = action.payload;
    },
    setAttributesStepOne: (state, action) => {
      state.attributesStepOne = action.payload;
    },
    setAttributesStepTwo: (state, action) => {
      state.attributesStepTwo = action.payload;
    },
  },
});

export const { setUserId, setAttributesStepOne, setAttributesStepTwo } =
  registerUserSlice.actions;
export default registerUserSlice.reducer;

export const userSelector = (state) => state.user;

export const selectUserAttributesStepOne = createSelector(
  userSelector,
  (state) => state.attributesStepOne
);

export const selectUserAttributesStepTwo = createSelector(
  userSelector,
  (state) => state.attributesStepTwo
);

export const selectUserId = createSelector(userSelector, (state) => state.id);

export const selectUser = createSelector(userSelector, (state) => state);