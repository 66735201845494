import React, { useState, useEffect } from "react";

import { Table as TableComponent } from "@mui/material";

import { TableContainerStyled } from "./ClientServiceTable.styled";
import TableBodyContainer from "./TableBodyContainer";
import TableHeader from "./TableHeadContainer";
import PropTypes from "prop-types";

const ClientServiceTable = (props) => {
  const [rows, setRows] = useState([]);
  const [page] = useState(1);
  const [rowsPerPage] = useState(5);

  const paginatedData = props?.data;

  useEffect(() => {
    if (paginatedData) {
      setRows(props.mapRowDataFunction(paginatedData));
    } else {
      setRows([]);
    }
  }, [paginatedData, page, rowsPerPage]);

  return (
    <TableContainerStyled>
      <TableComponent>
        <TableHeader t={props.t} tableHeaders={props.tableHeaders} />

        <TableBodyContainer
          rows={rows}
          tableHeaders={props.tableHeaders}
          rowBtnClick={props.rowBtnClick}
          deleteBtnClick={props?.deleteBtnClick}
          numberOfCharactersPerCell={15}
        />
      </TableComponent>
    </TableContainerStyled>
  );
};

ClientServiceTable.propTypes = {
  tableHeaders: PropTypes.any,
  usePaginatedQuery: PropTypes.func,
  t: PropTypes.any,
  mapRowDataFunction: PropTypes.func,
  theme: PropTypes.any,
  rowBtnClick: PropTypes.func,
  deleteBtnClick: PropTypes.func,
  appliedFilters: PropTypes.object,
  data: PropTypes.any,
};

ClientServiceTable.defaultProps = {
  mapRowDataFunction: (d) => d,
};

export default ClientServiceTable;
