import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import SchedulingAppointmentContent from "components/SchedulingAppointmentContent/SchedulingAppointmentContent";
import { useParams } from "react-router-dom";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { useDispatch } from "react-redux";
import { setClientServiceWithClient } from "features/clientService/clientServiceSlice";
import {
  useGetClientByIdQuery,
  useGetClientServiceQuery,
} from "features/clients/clientsApiSlice";

const SchedulingAppointmentPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { data: clientServiceData, isLoading: isLoadingClientServiceData } =
    useGetClientServiceQuery({
      clientId: params.clientId,
      clientServiceId: params.clientServiceId,
    });
  const clientService = isLoadingClientServiceData
    ? null
    : clientServiceData?.data?.clientService;

  const { data: clientData, isLoadingClientData } = useGetClientByIdQuery(
    params.clientId
  );
  const client = isLoadingClientData ? null : clientData?.data?.client;

  useEffect(() => {
    if (!isLoadingClientData && !isLoadingClientData) {
      dispatch(
        setClientServiceWithClient({
          client,
          clientService,
        })
      );
    }
  }, [clientService, client]);

  return (
    <MainLayout>
      <PageHeader leftIcon={<ControlTableIconSelected />} />
      {isLoadingClientServiceData ? (
        <div>loading service details...</div>
      ) : (
        <SchedulingAppointmentContent clientService={clientService} />
      )}
    </MainLayout>
  );
};

SchedulingAppointmentPage.propTypes = {
  theme: PropTypes.any,
};

export default SchedulingAppointmentPage;
