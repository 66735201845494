import React, { useState } from "react";
import Table from "../../components/Table/Table";
import clientLicensesTableConstants from "constants/tableConstants/clientLicensesTableConstants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LicenseEditAddModal from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { useGetPaginatedClientLicensesQuery } from "features/clients/clientsApiSlice";

const SuperAdminClientLicensePage = () => {
  const { t } = useTranslation();

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenModalAddEdit = (itemEdit) => {
    setSelectedItem(itemEdit);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const mapRowDataFunction = (rowData) => {
    return rowData.data?.clientLicenses?.map?.((clientLicense) => ({
      ...clientLicense,
      type: clientLicense.type,
      startDateUtc: clientLicense.startDateUtc,
      endDateUtc: clientLicense.endDateUtc,
    }));
  };

  return (
    <MainLayout>
      <PageHeader
        buttonText={t("superAdmin.table.licenses.addButonForLicenses")}
        onClick={() => handleOpenModalAddEdit(null)}
        isAddButtonShown={true}
        isFilterButtonShown={false}
      />
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={clientLicensesTableConstants}
        usePaginatedQuery={useGetPaginatedClientLicensesQuery}
        t={t}
        rowBtnClick={handleOpenModalAddEdit}
        numberOfCharactersPerCell={20}
      />
      {isModalVisible && (
        <LicenseEditAddModal
          onCancel={handleModalClose}
          onAction={handleModalClose}
          selectedItem={selectedItem}
        />
      )}
    </MainLayout>
  );
};

SuperAdminClientLicensePage.propTypes = {
  theme: PropTypes.any,
};

export default SuperAdminClientLicensePage;
