import React from "react";
import PropTypes from "prop-types";
import { AddNewItemContainer } from "./AddNewItemButton.styled";
import { ReactComponent as PlusIcon } from "assets/images/svg/plus-icon.svg";
import { VARIANT } from "constants/buttonConstants";

export const AddNewItemButton = (props) => {
  return (
    <AddNewItemContainer
      variant={VARIANT.CONTAINED}
      onClick={props.onClick}
      value={props.value}
      startIcon={<PlusIcon />}
    ></AddNewItemContainer>
  );
};

AddNewItemButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

export default AddNewItemButton;
