import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  isCollapsed: true,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isCollapsed = !state.isCollapsed;
    },
    setSidebarState(state, action) {
      state.isCollapsed = action.payload;
    },
  },
});

export const selectSidebarState = (state) => state.sidebar.isCollapsed;

export const selectIsSidebarCollapsed = createSelector(
  [selectSidebarState],
  (isCollapsed) => isCollapsed
);

export const { toggleSidebar, setSidebarState } = sidebarSlice.actions;
export default sidebarSlice.reducer;
