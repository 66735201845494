import styled from "styled-components";
import { ReactComponent as ClientImg } from "../../assets/images/svg/client-validation-message.svg";
import { ReactComponent as UserImg } from "../../assets/images/svg/user-validation-message.svg";
import AuthType from "constants/authType";
import { hexToRGB } from "util/colorHelper";
import { ModalSize } from "components/Modals/ModalSize";

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  height: ${(props) => (props.size === ModalSize.LARGE ? "401px" : "277px")};
  width: 460px;
  text-align: center;
  box-sizing: border-box;
`;

export const Divider = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid ${({ theme }) => hexToRGB(theme.colors.black, 0.2)};
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const Title = styled.h2`
  margin: 20px;
  font-weight: 700;
  font-size: 24px;
  `;

export const Message = styled.p`
  margin-top: 30px;
  text-align: left;
  min-height: 60px;
`;

export const CloseButton = styled.button`
  background-color: ${({ type, theme }) =>
    type === AuthType.CLIENT
      ? theme.colors.clientRegistration.Circle
      : theme.colors.userRegistration.Circle};
  &:hover {
    background-color: ${({ type, theme }) =>
      type === AuthType.CLIENT
        ? theme.colors.clientRegistration.Circle
        : theme.colors.userRegistration.Circle};
  }
  color: ${({ theme }) => theme.colors.textBlack};
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  width: 128px;
  height: 47px;
`;

const StyledImage = styled.img`
  width: ${(props) => (props.size === ModalSize.LARGE ? "170px" : "108px")};
  height: ${(props) => (props.size === ModalSize.LARGE ? "130px" : "77px")};
  display: block;
  margin: 0 auto;
`;

export const StyledClientImg = styled(StyledImage).attrs({ as: ClientImg })``;
export const StyledUserImg = styled(StyledImage).attrs({ as: UserImg })``;
