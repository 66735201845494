import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "hooks/useAuth";
import { PAGES } from "constants/pages";

const RequireAuthorization = (props) => {
  const { hasRole } = useAuth();
  const location = useLocation();
  return hasRole(props.roles) ? (
    <Outlet />
  ) : (
    <Navigate to={PAGES.BASE.route} state={{ from: location }} replace />
  );
};

RequireAuthorization.propTypes = {
  roles: PropTypes.array,
};

export default RequireAuthorization;
