import styled from "styled-components";

export const ImageGalleryContainer = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props?.theme?.colors?.white};
  overflow: auto;
`;

export const GalleryTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const ImageGalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const UploadBox = styled.label`
  border: 2px dashed
    ${(props) => props?.theme?.colors?.imageGalleryUploadBoxBorder};
  text-align: center;
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => props?.theme?.colors?.imageGalleryUploadBox};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${(props) =>
      props?.theme?.colors?.imageGalleryUploadBoxHover};
  }
  border-color: ${(props) =>
    props.isDragOver
      ? props?.theme?.colors?.imageGalleryUploadBoxBorderHover
      : props?.theme?.colors?.imageGalleryUploadBoxBorder};
`;

export const UploadContent = styled.div`
  span {
    font-size: 3rem;
  }
  p {
    font-weight: 300px;
    font-size: 12px;
    color: ${(props) => props?.theme?.colors?.black};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;
  object-fit: contain;
  transition: filter 0.3s ease;
  width: auto;
  height: auto;
  user-select: none;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;
export const ImageContainer = styled.div`
  width: 300px;
  height: 169px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  &:hover img {
    filter: brightness(50%);
  }
  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;
