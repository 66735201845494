import React from "react"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PreviousTablePageContainer } from "./PreviousTablePageButton.styled";
import { VARIANT } from "constants/buttonConstants";

export const PreviousTablePageButton = (props) => {
    const { t } = useTranslation();
    return (
        <PreviousTablePageContainer
            variant={VARIANT.TEXT}
            disabled={props.disabled}
            onClick={props.onClick}
            value={t("common.previousTablePage")}
        >
        </PreviousTablePageContainer>
    )
}

PreviousTablePageButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default PreviousTablePageButton;