import * as React from "react";
import PropTypes from "prop-types";
import LeftAuthContent from "components/LeftAuthContent/LeftAuthContent";
import RightAuthContent from "components/RightAuthContent/RightAuthContent";
import { StyledBigCard } from "components/MiddlePage/MiddlepageContent.styled";
import MainLayout from "layouts/MainLayout";

const AuthContent = (props) => {
  return (
    <MainLayout isAuthLayout={true}>
      <StyledBigCard>
        <LeftAuthContent
          type={props.type}
          title={props.title}
          steps={props.steps}
        />
        <RightAuthContent
          type={props.type}
          label={props.label}
          currentStep={props.currentStep}
          onClickNextPageBtn={props.onClickNextPageBtn}
          onClickBackBtn={props.onClickBackBtn}
        />
      </StyledBigCard>
    </MainLayout>
  );
};

AuthContent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.string,
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
};

export default AuthContent;
