import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "register.emailFormat")
    .required("register.inputRequired"),
  password: Yup.string()
    .required("register.inputRequired")
    .min(8, "login.passwordLength"),
});
