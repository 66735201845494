import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndpoints from "features/apiEndpoints";
import { setCredentials, logOut } from "features/auth/authSlice";
import { isExpired } from "react-jwt";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token.accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const token = api.getState().auth?.token;
    const expired = isExpired(token?.accessToken);
    if (token != null) {
      if (expired) {
        const refreshResult = await baseQuery(
          {
            url: apiEndpoints.authentication.refreshToken,
            method: "POST",
            body: { refreshTokenValue: token?.refreshToken.value },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          const accessToken = refreshResult?.data.data.accessToken;
          const refreshToken = refreshResult?.data.data.refreshToken;
          const user = api.getState().auth.user;
          api.dispatch(setCredentials({ accessToken, refreshToken, user }));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
      }
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
