import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomSelectFieldStyled from "../../InputFields/CustomFields/CustomSelectField";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams";
import { Box } from "@mui/material";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";

const PrimaryServiceField = (props) => {
  const { t } = useTranslation();
  const handleChange = (changedValue) => {
    props.formik.setFieldValue("primaryService", changedValue);
  };
  const { data, isLoading } = useGetAllServiceCategoriesQuery();

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomSelectFieldStyled
        type={props.type}
        name={props.name}
        formik={props.formik}
        value={props?.formik?.values?.primaryService}
        placeholder={t(props.placeholder)}
        items={
          isLoading
            ? []
            : data?.data?.serviceCategories?.map((x) => ({
                name: x?.name,
                id: x?.name,
                primaryId: x?.id,
              }))
        }
        width={props.width}
        passValue={handleChange}
      />
    </Box>
  );
};

PrimaryServiceField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
};

PrimaryServiceField.defaultProps = {
  label: "common.labelPrimaryService",
  name: FormikParams.primaryService,
  placeholder: "common.primaryServiceSelect",
};

export default PrimaryServiceField;
