import React from "react";
import { AccordionWrapperStyled } from "./RightInfoContent.styled";
import { DescriptionAccordion } from "./Accordions/DescriptionAccordion";
import PropTypes from "prop-types";
import ScheduleEditor from "components/ScheduleEditor/ScheduleEditor";
import ImageGallery from "components/ImageGallery/ImageGallery";

export const AccordionWrapper = (props) => {
  return (
    <AccordionWrapperStyled>
      <ImageGallery clientId={props?.clientId} />
      <DescriptionAccordion description={props?.description} />
      <ScheduleEditor
        workingDaysHours={props?.workingDaysHours}
        publicAppointmentEnabled={props?.publicAppointmentEnabled}
        appointmentStartTimeInMinutes={props?.appointmentStartTimeInMinutes}
      />
    </AccordionWrapperStyled>
  );
};

AccordionWrapper.propTypes = {
  description: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  workingDaysHours: PropTypes.array,
  publicAppointmentEnabled: PropTypes.bool,
  appointmentStartTimeInMinutes: PropTypes.number
};
