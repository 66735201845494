import React from "react";
import PropTypes from "prop-types";
import { StyledTypography, TopTabStyled } from "./TopTab.styled";

export const TopTab = (props) => {
  return (
    <TopTabStyled selected={props?.isActive} onClick={props?.onClick}>
      <StyledTypography isselected={props?.isActive}>
        {props?.title}
      </StyledTypography>
    </TopTabStyled>
  );
};
TopTab.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
