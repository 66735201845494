import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "hooks/useAuth";

const WithPermissions = (props) => {
  const { roles, isSuperAdmin } = useAuth();

  if (
    props.roles.some((role) => roles.includes(role)) ||
    (!isSuperAdmin && props.roles.length == 0)
  ) {
    return props.children;
  } else {
    return <></>;
  }
};

WithPermissions.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.node,
};

export default WithPermissions;
