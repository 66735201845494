export default [
  {
    columnId: 0,
    headerName: "appointments.table.date",
    field: "date",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 1,
    headerName: "appointments.table.time",
    field: "time",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 2,
    headerName: "appointments.table.clientName",
    field: "clientName",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 3,
    headerName: "appointments.table.serviceName",
    field: "serviceName",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 4,
    headerName: "appointments.table.price",
    field: "price",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 5,
    field: "details",
    enabledSort: false,
    enabledFilter: true,
  },
];
