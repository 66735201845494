import React, { useState } from "react";
import { Controls, TabContainer } from "./TopTabContainer.styled";
import clientsTabConstants from "constants/ClientTabConstants/clientsTabConstants";
import { TopTab } from "../IndividualTab/TopTab";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { compareRoutes, replaceInRoute } from "util/routeHelpers";
import { StyledTypography } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { ClientSwitch } from "components/ClientEditComponents/ClientSwitch";
import { useDispatch } from "react-redux";
import { PAGES } from "constants/pages";

export const TopTabContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleTabClick = (key) => {
    props?.handleTabClick(key);
    navigate(`${key}`);
  };

  const [isUserPanelChecked, setPanelCheck] = useState(false);
  const handleUserPanelChange = (event) => {
    setPanelCheck(event.target.checked);

    const pathParams = {
      clientId: props?.clientId,
    };
    const names = {
      clientId: props?.clientName,
    };

    navigate(replaceInRoute(PAGES.CLIENT.route, pathParams, names, dispatch));
  };

  return (
    <TabContainer>
      {clientsTabConstants.map((singleTab) => (
        <TopTab
          key={singleTab.key}
          title={t(singleTab.title)}
          id={singleTab.key}
          isActive={compareRoutes(location.pathname, singleTab.route)}
          onClick={() => handleTabClick(singleTab.key)}
        />
      ))}
      <Controls>
        <StyledTypography userPanel>
          {t("superAdmin.table.clients.userPanel")}
        </StyledTypography>
        <ClientSwitch
          checked={isUserPanelChecked}
          onChange={handleUserPanelChange}
        />
      </Controls>
    </TabContainer>
  );
};

TopTabContainer.propTypes = {
  handleTabClick: PropTypes.any,
  activeTab: PropTypes.any,
  clientId: PropTypes.any,
  clientName: PropTypes.string,
};
