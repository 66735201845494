import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import LandingPageContent from "components/LandingPageContent/LandingPageContent";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const HomePage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<ControlTableIconSelected />} />
      <LandingPageContent />
    </MainLayout>
  );
};

export default HomePage;
