import styled from "styled-components";
import { ReactComponent as QuestionMarkIcon } from "assets/images/svg/question-mark-icon.svg";
import { ReactComponent as CheckIcon } from "assets/images/svg/check-solid.svg";
import { hexToRGB } from "util/colorHelper";
import Button from "components/Button/Button";

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  width: 460px;
  height: 287px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

export const Divider = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 250px;
  gap: 20px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;
  width: 80%;
  align-self: center;
`;

export const CloseButton = styled(Button)`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
  color: ${({ theme }) => theme.colors.textBlack};
  padding: 6px 24px 6px 24px;
  border-radius: 12px;
  border-color: ${({ theme }) => hexToRGB(theme?.colors?.black, 0.3)};
  cursor: pointer;
  height: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  align-self: center;
`;

export const ActionButton = styled(CloseButton)`
  background-color: ${({ theme, active }) =>
    active
      ? theme.colors.statusInactiveButton
      : theme.colors.statusActiveButton};
  &:hover {
    background-color: ${({ theme, active }) =>
      active
        ? theme.colors.statusInactiveButton
        : theme.colors.statusActiveButton};
  }
  color: ${({ theme }) => theme.colors.white};
`;

const StyledImage = styled.img`
  height: 56px;
  width: 56px;
  display: block;
  margin: auto;
  margin-top: 10px;
`;

export const QuestionMarkImg = styled(StyledImage).attrs({ as: QuestionMarkIcon })``;
export const CheckImg = styled(StyledImage).attrs({ as: CheckIcon })`
  padding-top: 20px;
`;