import styled from "styled-components";
import { TextField } from "@mui/material";

const TextFieldStyled = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 12px;
    }
    &:hover fieldset {
      border-color: ${(props) => props?.theme?.colors?.secondaryColor};
    }
    &.Mui-focused fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient ? theme?.colors?.text3 : theme?.colors?.secondaryColor};
      border-width: "1px";
    }
    height: ${(props) => (props?.height ? props?.height : "")};
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(props) => props?.theme?.colors?.textColor};
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
    margin-bottom: ${({ isClient }) => (isClient ? "4px" : "0px")};
  }
  width: ${(props) => (props?.width ? props?.width : "100%")};
  height: ${(props) => (props?.height ? props?.height : "")};
`;

export default TextFieldStyled;