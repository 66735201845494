import React from "react";
import PlusButtonRenderer from "components/Table/PlusButtonRenderer";

const getTableColumns = (t) => {
    const workingDays = [
      t("days.monday"),
      t("days.tuesday"),
      t("days.wednesday"),
      t("days.thursday"),
      t("days.friday"),
      t("days.saturday"),
      t("days.sunday"),
    ];
  
    return [
      {
        columnId: 0,
        headerName: "workingHours.workingDay",
        field: "workingDay",
        enabledSort: false,
        enabledFilter: true,
        workingDays,
      },
      {
        columnId: 1,
        headerName: "workingHours.workingHours",
        field: "workingHours",
        enabledSort: false,
        enabledFilter: true,
      },
      {
        columnId: 2,
        headerName: "",
        field: "change",
        enabledSort: false,
        enabledFilter: false,
        cellRenderer: (params) => <PlusButtonRenderer params={params} onClick={params.onClickEdit} />,
      },
    ];
  };
  
  export default getTableColumns;