import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  DateText,
  TableComponentStyled,
  TableContainerStyled,
  TableWrapper,
} from "components/Table/Table.styled";
import { CollapTableBodyContainer } from "./CollapTableBodyContainer";
import { groupTimeSlotsByDay } from "util/groupTimeSlotsByDay";
import { useTranslation } from "react-i18next";

const CollapsibleTable = (props) => {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const groupedData = groupTimeSlotsByDay(
      props?.timeSlots,
      props?.serviceName,
      props?.selectedAttributes
    );
    setRows(groupedData);
  }, [props?.timeSlots]);

  return (
    <TableContainerStyled>
      <TableWrapper>
        <TableComponentStyled>
          <DateText>{t("scheduleAppointment.dateLabel")}</DateText>
          <CollapTableBodyContainer
            passSelectedTimeSlot={props.passSelectedTimeSlot}
            rows={rows}
            numberOfCharactersPerCell={props?.numberOfCharactersPerCell}
          />
        </TableComponentStyled>
      </TableWrapper>
    </TableContainerStyled>
  );
};

CollapsibleTable.propTypes = {
  serviceName: PropTypes.string,
  timeSlots: PropTypes.array,
  selectedAttributes: PropTypes.array,
  passSelectedTimeSlot: PropTypes.func,
  numberOfCharactersPerCell: PropTypes.number,
};
export default CollapsibleTable;
