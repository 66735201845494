import React from "react";
import {
  ClientServiceTableHeaderButtonContainer,
  ClientServiceTableHeaderSyled,
  ClientServiceTableHeaderTypography,
} from "./ClientServiceTableHeader.styled";
import PropTypes from "prop-types";
import AddNewItemButton from "components/SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import { useTranslation } from "react-i18next";

const ClientServiceTableHeader = (props) => {
  const { t } = useTranslation();

  return (
    <ClientServiceTableHeaderSyled>
      <ClientServiceTableHeaderTypography>
        {props.headerTitle}
      </ClientServiceTableHeaderTypography>
      <ClientServiceTableHeaderButtonContainer>
        <AddNewItemButton
          value={t("clientService.add")}
          onClick={props.handleOpenModalAdd}
        />
      </ClientServiceTableHeaderButtonContainer>
    </ClientServiceTableHeaderSyled>
  );
};
ClientServiceTableHeader.propTypes = {
  headerTitle: PropTypes.string,
  handleOpenModalAdd: PropTypes.func,
};

export default ClientServiceTableHeader;
