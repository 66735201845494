import React from "react";
import PropTypes from "prop-types";
import { SingleForm } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const RegistrationNameField = (props) => {
  const handleChange = (event) => {
    props?.clearErrorMessage;
    props?.formik?.handleChange(event);
  };
  const showFormikError = props.formik?.errors?.registrationName;

  const helperText =
    props.helperText ||
    (showFormikError ? props.formik.errors.registrationName : "");
  return (
    <SingleForm
      formik={props?.formik}
      name={clientFormikParams.registrationName}
      onBlur={props?.onBlur}
      clearErrorMessage={handleChange}
      helperText={helperText}
      error={Boolean(helperText)}
    />
  );
};

RegistrationNameField.propTypes = {
  formik: PropTypes.any,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
};
