import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const forgotPasswordApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: apiEndpoints.forgotPassword.forgotPassword,
        method: "POST",
        body: body,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ id, token, body }) => ({
        url: apiEndpoints.forgotPassword.resetPasswrod
          .replace("{id}", id)
          .replace("{token}", token),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }),
    }),
  }),
});

export const { useForgotPasswordMutation, useResetPasswordMutation } =
  forgotPasswordApi;
