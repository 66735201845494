export const clientResourceFormikParams = (resource) => {
  return {
    name: resource?.name ?? "",
    imageId: resource?.imageId ?? "",
    email: resource?.email && resource?.email != "-" ? resource?.email : "",
    resourceType: resource?.type ?? ""
  };
};

export const FormikParams = {
  name: "",
};
