import React from "react";
import PropTypes from "prop-types";
import { FormikParams } from "constants/formikParams";
import { PhoneNumberContainer } from "./PhoneField.styled";
import { useTranslation } from "react-i18next";

const PhoneNumberField = (props) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      props.formik.setFieldValue(props.name, value);
    }
  };
  return (
    <PhoneNumberContainer
      type={props.type}
      name={props.name}
      formik={props.formik}
      placeholder={props.placeholder}
      value={props.formik?.values?.[props?.name]}
      onChange={handleChange}
      onBlur={props.formik?.handleBlur}
      error={
        props.formik?.touched?.[props?.name] &&
        Boolean(props.formik?.errors?.[props?.name])
      }
      helperText={t(
        props.formik?.touched?.[props?.name] &&
          props.formik?.errors?.[props?.name]
      )}
      disabled={props.disabled}
    ></PhoneNumberContainer>
  );
};

PhoneNumberField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

PhoneNumberField.defaultProps = {
  name: FormikParams.phoneNumber,
  placeholder: "common.phoneInput",
};

export default PhoneNumberField;
