import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  Modal,
  Title,
  ActionsContainer,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
  LabelContainer,
  StatusInputContainer,
} from "./Modal.styled";
import {
  InputFieldContainer,
  InputFieldsContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { VARIANT } from "constants/buttonConstants";
import CustomInputField from "components/InputFields/CustomFields/CustomInputField";
import { ClientServiceConstants } from "constants/clientServiceConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  addResource,
  clientResourcesSelector,
  selectRequiredResourceTypeById,
  submit,
  toggleClientServiceResourceStatus,
  updateResource,
} from "features/clientService/clientServiceSlice";
import CustomMultipleSelectField from "components/InputFields/CustomFields/CustomMultipleSelectField";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { ClientServiceResourceValidation } from "validations/clientService/clientServiceResourceValidation";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  ActiveCircle,
  StatusButtonContainer,
  StatusText,
} from "components/SuperAdmin/UserPageContent/UserPageContent.styled";
import { ReactComponent as CheckImg } from "assets/images/svg/status-active-check.svg";
import { ReactComponent as XImg } from "assets/images/svg/status-inactive-x.svg";

const ClientServiceResourceGroupModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const editFlag = useMemo(
    () => props?.selectedItem != null,
    [props?.selectedItem]
  );

  const [status, setStatus] = useState(false);

  var requiredResource = useSelector((state) =>
    selectRequiredResourceTypeById(state, props?.selectedItem?.id)
  );

  const resources = useSelector(clientResourcesSelector);
  const [selectedType, setSelectedType] = useState(null);

  const handleSubmit = async () => {
    const resource = {
      resourceTypeName: formik?.values?.resourceTypeName,
      autoAssign: requiredResource?.autoAssign || status,
      possibleResources: formik?.values?.resourceIds?.map((id, index) => {
        const resourceName = formik?.values?.resourceNames[index];
        return { resource: { name: resourceName, id } };
      }),
    };

    if (editFlag) {
      const updatedResource = {
        ...props?.selectedItem,
        ...resource,
      };
      dispatch(updateResource({ updatedResource: updatedResource }));
    } else {
      dispatch(addResource({ requiredResource: resource }));
    }
    dispatch(submit());
    dispatch(unsetShownModal());
  };

  const onClickStatusButton = (id) => {
    if (editFlag) {
      dispatch(toggleClientServiceResourceStatus({ id: id }));
    } else {
      setStatus((oldValue) => !oldValue);
      formik.values.autoAssign = !formik.values.autoAssign;
    }
  };

  const formik = useFormik({
    initialValues: {
      resourceTypeName: props?.selectedItem?.resourceTypeName,
      autoAssign: requiredResource?.autoAssign,
      resourceNames: editFlag
        ? props?.selectedItem?.possibleResources?.map(
            (resource) => resource?.resource.name
          )
        : [],
      resourceIds: editFlag
        ? props?.selectedItem?.possibleResources?.map(
            (resource) => resource.resource.id
          )
        : [],
    },
    validationSchema: ClientServiceResourceValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    const selectedIds = formik.values.resourceIds;
    if (selectedIds.length > 0) {
      const selectedResource = resources.find((r) => r.id === selectedIds[0]);
      setSelectedType(selectedResource?.type ?? null);
    } else {
      setSelectedType(null);
    }
  }, [formik.values.resourceIds, resources]);

  const filteredResources = useMemo(() => {
    if (!selectedType) return resources;

    return resources.filter((resource) => resource.type === selectedType);
  }, [selectedType, resources]);

  const isActive = useMemo(() => {
    return requiredResource?.autoAssign ?? status;
  }, [requiredResource, status]);

  return (
    <Modal>
      <UpperContainer>
        <Title>
          {editFlag
            ? t("clientService.editResourceGroup")
            : t("clientService.addResourceGroup")}
        </Title>
        <InputFieldsContainer>
          <InputFieldContainer>
            <CustomInputField
              value={formik?.values?.resourceTypeName}
              placeholder={"clientService.placeholderResourceGroupName"}
              name={ClientServiceConstants.resourceTypeName}
              label={"clientService.resourceTypeName"}
              formik={formik}
            />
          </InputFieldContainer>
          <InputFieldContainer gap={true}>
            <LabelContainer>
              <CustomFieldLabel label={t("clientService.resourceList")} />
            </LabelContainer>
            <CustomMultipleSelectField
              name={ClientServiceConstants.resourceIds}
              formik={formik}
              fieldIds="resourceIds"
              fieldNames="resourceNames"
              isClient={false}
              items={filteredResources?.map((x) => ({
                name: x?.name,
                id: x?.id,
                primaryId: x?.id,
                isActive: x?.isActive,
              }))}
            />
          </InputFieldContainer>
          <StatusInputContainer>
            <StatusText active={isActive || status}>
              {t("clientService.table.resources.autoAssign")}
            </StatusText>
            <StatusButtonContainer
              active={isActive || status}
              onClick={() => onClickStatusButton(props?.selectedItem?.id)}
            >
              <ActiveCircle>
                {isActive || status ? <CheckImg /> : <XImg />}
              </ActiveCircle>
            </StatusButtonContainer>
          </StatusInputContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={props.onCancel}
            value={t("common.cancel")}
          />
          <ActionButton
            onClick={formik.handleSubmit}
            value={editFlag ? t("common.save") : t("common.add")}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

ClientServiceResourceGroupModal.propTypes = {
  selectedItem: PropTypes.object,
  onCancel: PropTypes.func,
};

export default ClientServiceResourceGroupModal;
