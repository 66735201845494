import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  TableTitle,
  StyledTableRowWorkingHours
} from "./TableHeadContainer.styled";
import { secondaryThemeColors } from "themes/secondaryTheme/secondaryThemeColors";

const TableHeader = (props) => {
  const RowComponent = props.tableAlignmentLeft  ? StyledTableRowWorkingHours : StyledTableRow;
  return (
    <StyledTableHead>
      <RowComponent>
        {props.tableHeaders.map((col) => (
          <StyledTableCell key={col.field}>
            <TableTitle color={secondaryThemeColors.leftAuthContentBackground}>
              {props.t(col.headerName)}
            </TableTitle>
          </StyledTableCell>
        ))}
      </RowComponent>
    </StyledTableHead>
  );
};

TableHeader.propTypes = {
  t: PropTypes.any,
  tableHeaders: PropTypes.any,
  tableAlignmentLeft : PropTypes.bool,
};

TableHeader.defaultProps = {
  tableAlignmentLeft : false,
};

export default TableHeader;