import { toast } from "react-toastify";

const defaultOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
  draggable: true,
};

const toastClasses = {
  success: "toast-success",
  error: "toast-error",
  warning: "toast-warning",
};

export const makeToastMessage = (message, type = "info", options = {}) => {
  const toastOptions = {
    ...defaultOptions,
    ...options,
    className: toastClasses[type],
  };
  switch (type) {
    case "success":
      return toast.success(message, toastOptions);
    case "error":
      return toast.error(message, toastOptions);
    case "warning":
      return toast.warning(message, toastOptions);
    default:
      return toast.info(message, toastOptions);
  }
};
