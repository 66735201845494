import UserDetailsRenderer from "components/Table/UserDetailsRenderer";
import React from "react";

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.tableHeaderForUsers",
    field: "name",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 1,
    headerName: "common.labelEmail",
    field: "email",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 2,
    headerName: "common.labelPhoneNumber",
    field: "phoneNumber",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 3,
    headerName: "common.labelLocation",
    field: "city",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 4,
    headerName: "common.status",
    field: "status",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 5,
    headerName: "",
    field: "izmeni",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (props) => <UserDetailsRenderer {...props} />,
  },
];
