import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";
import { ReactComponent as PencileIcon } from "assets/images/svg/pencile-icon.svg";

const EditRenderer = (props) => {
  return (
    <ActionButton
      startIcon={<PencileIcon />}
      value={"common.edit"}
      onClick={props.onClickEdit}
    />
  );
};
EditRenderer.propTypes = {
  onClickEdit: PropTypes.func,
};

export default EditRenderer;
