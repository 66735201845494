import React from "react";
import PropTypes from "prop-types";
import {
  TimeContainer,
  GridContainer,
  ItemContainer,
  RowContainer,
  DayLabelContainer,
  LabelNonWorkingDay,
} from "./ListingWorkingHourComponent.styled";
import { daysOfWeek, workingDays } from "constants/daysOfWeek";
import { useTranslation } from "react-i18next";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";

const ListingWorkingHourComponent = (props) => {
  const { t } = useTranslation();
  return (
    <GridContainer>
      {Object.values(daysOfWeek).map((dayOfWeek) => {
        const dayWorkingHours = props?.items
          ?.filter((item) => item.dayOfWeek == (dayOfWeek + 1) % 7)
          .sort(
            (item1, item2) =>
              new Date(item1.fromTimeUtc) - new Date(item2.fromTimeUtc)
          );

        const rows = [];
        for (let i = 0; i < dayWorkingHours?.length; i += props.itemsPerRow) {
          rows.push(dayWorkingHours.slice(i, i + props.itemsPerRow));
        }
        return (
          <>
            {rows?.length != 0 ? (
              rows.map((row, rowIndex) => (
                <RowContainer key={rowIndex}>
                  <ItemContainer>
                    {rowIndex == 0 && (
                      <DayLabelContainer>
                        {t(workingDays[dayOfWeek])}
                      </DayLabelContainer>
                    )}
                  </ItemContainer>
                  {row.map((item, itemIndex) => {
                    const fromTime = convertUTCToLocalTime(item.fromTimeUtc);
                    const toTime = convertUTCToLocalTime(item.toTimeUtc);
                    return (
                      <ItemContainer key={itemIndex}>
                        <TimeContainer>{fromTime + "-" + toTime}</TimeContainer>
                      </ItemContainer>
                    );
                  })}
                </RowContainer>
              ))
            ) : (
              <RowContainer>
                <ItemContainer>
                  <DayLabelContainer>
                    {t(workingDays[dayOfWeek])}
                  </DayLabelContainer>
                </ItemContainer>
                <ItemContainer>
                  <LabelNonWorkingDay>
                    {t("days.nonWorkingDay")}
                  </LabelNonWorkingDay>
                </ItemContainer>
              </RowContainer>
            )}
          </>
        );
      })}
    </GridContainer>
  );
};

ListingWorkingHourComponent.propTypes = {
  items: PropTypes.array,
  itemsPerRow: PropTypes.number,
};

export default ListingWorkingHourComponent;
