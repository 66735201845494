import React from "react";
import EditRenderer from "components/Table/EditRenderer";

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.tableHeaderForServiceCategories",
    field: "name",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "superAdmin.table.tableHeaderForServiceCategoriesDescription",
    field: "description",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 2,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (props) => <EditRenderer {...props} />,
  },
];
