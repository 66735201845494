import React from "react";
import PropTypes from "prop-types";
import { SingleForm } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const PhoneField = (props) => {
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      props?.formik?.handleChange(event);
    }
  };
  return (
    <SingleForm
      formik={props?.formik}
      name={clientFormikParams.phoneNumber}
      changeField={handleChange}
      phone={true}
    />
  );
};

PhoneField.propTypes = {
  formik: PropTypes.any,
};
