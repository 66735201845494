import { createSelector, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userObject",
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

export const userObjectSelector = (state) => state.userObject;

export const selectUser = createSelector(
  userObjectSelector,
  (state) => state.user
);
