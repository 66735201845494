import React from "react";
import PropTypes from "prop-types";
import { FormikParams } from "constants/formikParams";
import { Box } from "@mui/material";
import { PhoneFieldContainer } from "./PhoneField.styled";
import CountryPhoneCodeField from "./CountryPhoneCodeSelect";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { useTranslation } from "react-i18next";
import PhoneNumberField from "./PhoneNumberField";

const PhoneField = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <PhoneFieldContainer>
        <CountryPhoneCodeField
          formik={props.formik}
          name={props.nameCountryPhoneCode}
          disabled={props.disabled || props.disabledCode}
          selectedCountry={props.selectedCountry}
          selectFirst={props.selectFirst}
        />
        <PhoneNumberField
          name={props.namePhoneNumber}
          formik={props.formik}
          placeholder={t(props.placeholder)}
          disabled={props.disabled || props.disabledNumber}
        />
      </PhoneFieldContainer>
    </Box>
  );
};

PhoneField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  nameCountryPhoneCode: PropTypes.string,
  namePhoneNumber: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
  disabled: PropTypes.bool,
  disabledCode: PropTypes.bool,
  disabledNumber: PropTypes.bool,
  selectFirst: PropTypes.bool,
  selectedCountry: PropTypes.string,
};

PhoneField.defaultProps = {
  label: "common.labelPhone",
  nameCountryPhoneCode: FormikParams.countryPhoneCode,
  namePhoneNumber: FormikParams.phoneNumber,
  placeholder: "23 45 678",
};

export default PhoneField;
