import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { MessageIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const MessagesPage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<MessageIconSelected />} />
    </MainLayout>
  );
};

export default MessagesPage;
