import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    serviceCategoryModal: false,
    serviceCategoryModalProps: {
      serviceCategory: null,
    },
    sentCodeModal: false,
    sentCodeModalProps: {
      authType: null,
    },
    finishedRegistrationModal: false,
    finishedRegistrationModalProps: {
      authType: null,
    },
    changeUserStatusModal: false,
    changeUserStatusModalProps: {
      userId: null,
      active: null,
      userType: null,
    },
    changeLicenceModal: false,
    changeLicenceModalProps: {
      data: null,
      clientId: null,
      authType: null,
    },
    deleteLicenceModal: false,
    deleteLicenceModalProps: {
      id: null,
      authType: null,
    },
    deleteResourceModal: false,
    deleteResourceModalProps: {
      resourceId: null,
    },
    addEditResourceModal: false,
    addEditResourceModalProps: {
      resource: null,
    },
    workingTimeModal: false,
    workingTimeModalProps: {
      day: null,
      onAddWorkingHour: null,
    },
    unsavedAlertModal: false,
    unsavedAlertModalProps: {
      path: null,
    },
  },
  clientServiceAttributeModal: false,
  clientServiceAttributeModalProps: {
    selectedItem: null,
    onCancel: null,
  },
  clientServiceResourceModal: false,
  clientServiceResourceModalProps: { selectedItem: null, onCancel: null },

  clientServiceDeleteModal: false,
  clientServiceDeleteModalProps: { selectedItem: null, onDelete: null },

  reducers: {
    setServiceCategoryModal: (state, action) => {
      return {
        ...state,
        serviceCategoryModal: true,
        serviceCategoryModalProps: {
          serviceCategory: action.payload,
        },
      };
    },
    setWorkingHoursModal: (state, action) => {
      return {
        ...state,
        workingTimeModal: true,
        workingTimeModalProps: {
          day: action.payload.day,
          onAddWorkingHour: action.payload.onAddWorkingHour,
        },
      };
    },
    unsetShownModal: (state) => {
      state.serviceCategoryModal = false;
      state.sentCodeModal = false;
      state.finishedRegistrationModal = false;
      state.changeUserStatusModal = false;
      state.changeLicenceModal = false;
      state.deleteLicenceModal = false;
      state.clientServiceAttributeModal = false;
      state.clientServiceResourceModal = false;
      state.deleteResourceModal = false;
      state.addEditResourceModal = false;
      state.workingTimeModal = false;
      state.unsavedAlertModal = false;
      state.clientServiceDeleteModal = false;
    },
    setSentCodeModal: (state, action) => {
      return {
        ...state,
        sentCodeModal: true,
        sentCodeModalProps: {
          authType: action.payload,
        },
      };
    },
    setFinishedRegistrationModal: (state, action) => {
      return {
        ...state,
        finishedRegistrationModal: true,
        finishedRegistrationModalProps: {
          authType: action.payload,
        },
      };
    },
    setChangeUserStatusModal: (state, action) => {
      return {
        ...state,
        changeUserStatusModal: true,
        changeUserStatusModalProps: {
          userId: action.payload.userId,
          active: action.payload.active,
          userType: action.payload.userType,
        },
      };
    },
    setChangeLicenceModal: (state, action) => {
      return {
        ...state,
        changeLicenceModal: true,
        changeLicenceModalProps: {
          data: action.payload.data,
          clientId: action.payload.clientId,
          authType: action.payload.authType,
        },
      };
    },
    setDeleteLicenceModal: (state, action) => {
      return {
        ...state,
        deleteLicenceModal: true,
        deleteLicenceModalProps: {
          id: action.payload.id,
          authType: action.payload.authType,
        },
      };
    },
    setDeleteResourceModal: (state, action) => {
      return {
        ...state,
        deleteResourceModal: true,
        deleteResourceModalProps: {
          clientId: action.payload.clientId,
          resourceId: action.payload.resourceId,
        },
      };
    },
    setAddEditResourceModal: (state, action) => {
      return {
        ...state,
        addEditResourceModal: true,
        addEditResourceModalProps: {
          ...action.payload,
        },
      };
    },
    setUnsavedAlertModal: (state, action) => {
      return {
        ...state,
        unsavedAlertModal: true,
        unsavedAlertModalProps: {
          ...action.payload,
        },
      };
    },
    setClientServiceAttributeModal: (state, action) => {
      return {
        ...state,
        clientServiceAttributeModal: true,
        clientServiceAttributeModalProps: {
          selectedItem: action.payload.selectedItem,
          onCancel: action.payload.onCancel,
        },
      };
    },
    setClientServicResourceModal: (state, action) => {
      return {
        ...state,
        clientServiceResourceModal: true,
        clientServiceResourceModalProps: {
          selectedItem: action.payload.selectedItem,
          onCancel: action.payload.onCancel,
        },
      };
    },
    setClientServiceDeleteModal: (state, action) => {
      return {
        ...state,
        clientServiceDeleteModal: true,
        clientServiceDeleteModalProps: {
          selectedItem: action.payload.selectedItem,
          onDelete: action.payload.onDelete,
          onCancel: action.payload.onCancel,
        },
      };
    },
  },
});
export const {
  setServiceCategoryModal,
  setWorkingHoursModal,
  setSentCodeModal,
  setFinishedRegistrationModal,
  setChangeUserStatusModal,
  unsetShownModal,
  setChangeLicenceModal,
  setDeleteLicenceModal,
  setDeleteResourceModal,
  setAddEditResourceModal,
  setUnsavedAlertModal,
  setClientServiceAttributeModal,
  setClientServicResourceModal,
  setClientServiceDeleteModal,
} = modalSlice.actions;
export default modalSlice.reducer;

export const modalSelector = (state) => state.modal;

export const selectModal = createSelector(modalSelector, (state) => {
  return state;
});
