import styled from "styled-components";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  height: 24px;
  width: 98px;
`;

export const DayLabelContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textBlack};
`;

export const TimeContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textBlack};
`;

export const LabelNonWorkingDay = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.errorColor};
`;
