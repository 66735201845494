import { Checkbox } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as CustomCheckIcon } from "assets/images/svg/checked-box.svg";
import { ReactComponent as CustomUncheckedIcon } from "assets/images/svg/unchecked-box.svg";

export const CheckBoxStyled = styled(Checkbox)``;

export const CheckedIcon = styled(CustomCheckIcon)`
  height: 24px;
  width: 24px;
`;

export const UncheckedIcon = styled(CustomUncheckedIcon)`
  height: 24px;
  width: 24px;
`;
