import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, FormControl } from "@mui/material";
import { resourceTypes } from "constants/resourceType";
import { CustomSelectFieldStyled, CustomFormHelperText } from "./ResourceTypeField.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";

const ResourceTypeField = (props) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const value = event.target.value;
    if (props.formik && props.formik.setFieldValue) {
      props.formik.setFieldValue(props.name, value);
    }
    if (props.formik && props.formik.setFieldTouched) {
      props.formik.setFieldTouched(props.name, true, true);
    }
    if (props.formik && value) {
      props.formik.setFieldError(props.name, "");
    }
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} />
      <FormControl fullWidth error={Boolean(props.formik?.touched[props.name] && props.formik?.errors[props.name])}>
        <CustomSelectFieldStyled
          id={props.name}
          name={props.name}
          value={props.formik.values[props.name] || ""}
          onChange={handleChange}
          onBlur={props?.formik?.handleBlur}
          displayEmpty
          placeholder={t(props.placeholder)}
          fullWidth
        >
          <MenuItem value="" disabled>
            {t(props.placeholder)}
          </MenuItem>
          {resourceTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {t(type.name)}
            </MenuItem>
          ))}
        </CustomSelectFieldStyled>
        {props.formik?.touched[props.name] && props.formik?.errors[props.name] && (
          <CustomFormHelperText>{t("register.inputRequired")}</CustomFormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

ResourceTypeField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.any,
};

ResourceTypeField.defaultProps = {
  label: "superAdmin.table.clientResources.resource",
  name: "resourceType",
  placeholder: "superAdmin.table.clientResources.chooseResource",
};

export default ResourceTypeField;
