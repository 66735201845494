import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomFields/CustomTextField";
import CustomFieldLabel from "./CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams";
import TYPE from "../../constants/inputTypes";
import { Box } from "@mui/material";

const EmailField = (props) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    props?.clearErrorMessage?.();
    props?.formik?.handleChange(event);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomTextField
        type={props.type}
        name={props.name}
        placeholder={t(props.placeholder)}
        formik={props.formik}
        width={props.width}
        height={props.height}
        onChange={handleChange}
        helperText={props?.helperText}
        onBlur={props?.onBlur}
      />
    </Box>
  );
};

EmailField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  onBlur: PropTypes.func,
};

EmailField.defaultProps = {
  label: "common.labelEmail",
  type: TYPE.EMAIL,
  name: FormikParams.email,
  placeholder: "common.email",
};

export default EmailField;
