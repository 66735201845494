import CustomLabelWithFormik from "components/InputFields/CustomFields/CustomLabelWIthFormik";
import { ChipStyled } from "components/InputFields/CustomFields/CustomSelectField.styled";
import UpdateRenderer from "components/Table/UpdateRenderer";
import React from "react";

export default [
  {
    columnId: 0,
    headerName: "clientService.table.resources.groupName",
    field: "resourceTypeName",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "clientService.table.resources.autoAssign",
    field: "autoAssign",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <>
        {params?.name ? (
          <CustomLabelWithFormik label={"common.yes"} />
        ) : (
          <CustomLabelWithFormik label={"common.no"} />
        )}
      </>
    ),
  },
  {
    columnId: 2,
    headerName: "clientService.table.resources.resources",
    field: "resourceNames",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <>
        {params?.name?.map((name) => (
          <ChipStyled key={name} label={name} />
        ))}
      </>
    ),
  },
  {
    columnId: 3,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer
        params={params}
        onClick={params.onClickEdit}
        onClickDelete={params.onClickDelete}
        value={"superAdmin.table.editButton"}
      />
    ),
  },
];
