import Button from "components/Button/Button";
import styled from "styled-components";

export const ActionButtonContainer = styled(Button)`
  border-radius: 8px;
  padding: 2px 10px 2px 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: white;
  background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  }
`;
