import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import i18n from "../i18nt/index";

export function formatDate(date, fmt = "MM/dd/y", locale = enUS) {
  const dt = new Date(date);
  return format(dt, fmt, { locale });
}

export function formatDateTime(date) {
  const dt = new Date(date);
  return format(dt, "MM/dd/y hh:mm aa");
}

export function formatDateTimeLocal(date) {
  const dt = new Date(date);
  if (isNaN(dt.getTime())) {
    console.error("Invalid date:", date);
    return "";
  }
  return format(dt, "dd MMMM yyyy hh:mm aa");
}

export function getDateDay(date) {
  const dt = new Date(date);
  return format(dt, "dd");
}

export function getDateMonth(date) {
  const dt = new Date(date);
  return format(dt, "MM");
}

export function getDateYear(date) {
  const dt = new Date(date);
  return format(dt, "y");
}

export function formatDateTimeLocale(date) {
  const dt = new Date(date);
  return format(dt, "MM/dd/y hh:mm aa");
}

// TODO add locale
export function formatDateRange(dates) {
  const start = formatDate(dates.start);
  const end = formatDate(dates.end);
  return i18n.t("common.date.range", { start, end });
}

export const calculateTimeSpan = (date) => {
  let currentDate = new Date();
  let timeElapsed = (currentDate.getTime() - date?.getTime()) / 1000;
  if (timeElapsed <= 0) {
    return {
      yearsPassed: 0,
      monthsPassed: 0,
      daysPassed: 0,
      hoursPassed: 0,
      minutesPassed: 0,
      secondsPassed: 0,
    };
  }

  const yearsPassed = Math.floor(timeElapsed / (60 * 60 * 24 * 365));
  timeElapsed -= yearsPassed * (60 * 60 * 24 * 365);

  const monthsPassed = Math.floor(timeElapsed / (60 * 60 * 24 * 31));
  timeElapsed -= monthsPassed * (60 * 60 * 24 * 31);

  const daysPassed = Math.floor(timeElapsed / (60 * 60 * 24));
  timeElapsed -= daysPassed * (60 * 60 * 24);

  const hoursPassed = Math.floor(timeElapsed / (60 * 60));
  timeElapsed -= hoursPassed * (60 * 60);

  const minutesPassed = Math.floor(timeElapsed / 60);
  timeElapsed -= minutesPassed * 60;

  const secondsPassed = Math.floor(timeElapsed);
  timeElapsed -= secondsPassed;

  return {
    yearsPassed,
    monthsPassed,
    daysPassed,
    hoursPassed,
    minutesPassed,
    secondsPassed,
  };
};

export const formatTimeSpan = (date) => {
  const timespanObject = calculateTimeSpan(date);
  if (timespanObject.yearsPassed > 0)
    return i18n.t("date.timespan.yearsAgo", {
      years: timespanObject.yearsPassed,
    });
  if (timespanObject.monthsPassed > 0)
    return i18n.t("date.timespan.monthsAgo", {
      months: timespanObject.monthsPassed,
    });
  if (timespanObject.daysPassed > 0)
    return i18n.t("date.timespan.daysAgo", {
      days: timespanObject.daysPassed,
    });
  if (timespanObject.hoursPassed > 0)
    return i18n.t("date.timespan.hoursAgo", {
      hours: timespanObject.hoursPassed,
    });
  if (timespanObject.minutesPassed > 0)
    return i18n.t("date.timespan.minutesAgo", {
      minutes: timespanObject.minutesPassed,
    });
  if (timespanObject.secondsPassed > 0)
    return i18n.t("date.timespan.secondsAgo", {
      seconds: timespanObject.secondsPassed,
    });
  return i18n.t("date.timespan.now");
};

export function formatDate_T_Time(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function getStartAndEndOfWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  const monday = new Date(today);
  monday.setDate(today.getDate() + diffToMonday);
  monday.setHours(0, 0, 0, 0);
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);

  return {
    startOfWeek: monday,
    endOfWeek: sunday,
  };
}
