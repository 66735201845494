import styled from "styled-components";

export const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;
`;

export const StatusButtonContainer = styled.div`
  width: 36px;
  height: 20px;
  padding: 2px;
  background-color: ${(props) =>
    props?.active ? props?.activeColor : props?.inactiveColor};
  display: flex;
  justify-content: ${(props) => (props.active ? "flex-end" : "flex-start")};
  border-radius: 12px;
  cursor: pointer;
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 18px;
  transition: 0.3s ease;
`;
