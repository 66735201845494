import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";
import { ReactComponent as DetailsIcon } from "assets/images/svg/details-icon.svg";

const UserDetailsRenderer = (props) => {
  return (
    <ActionButton
      startIcon={<DetailsIcon />}
      value={"common.details"}
      onClick={props.onClickEdit}
    />
  );
};
UserDetailsRenderer.propTypes = {
  onClickEdit: PropTypes.func,
};

export default UserDetailsRenderer;
