import { Typography } from "@mui/material";
import styled from "styled-components";

export const ClientInfoContainer = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 410px;
`;

export const ClientName = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
`;

export const BasicInfo = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 27.28px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
`;

export const SegmentWrapper = styled.div`
  gap: 6px;
`;

export const SegmentWithBottomBorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 2px solid
    ${(props) => props?.theme?.colors?.textUncompletedStep};
  gap: 6px;
`;

export const ClientDescription = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const ClientWorkingHour = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const CustomTextWrapper = styled.div``;

export const ClientFullName = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const ClientPrimaryService = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;
export const ClientSecondaryServicesWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const ClientSecondaryServices = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.serviceChipTextColor};
  background-color: ${(props) => props?.theme?.colors?.serviceChipColor};
  color: purple;
  border-radius: 12px;
  padding: 2px 6px;
`;

export const ClientWebAddress = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const SegmentTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;
