import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AvailableSlotsContentStyled,
  AvailableSlotsTableContainer,
  ChoosePeriodContainer,
  ChooseTimeLabel,
  DatesContainer,
  LabelAndCalendarContainer,
  LeftArrowStyled,
  PeriodLabel,
  PeriodText,
  RightArrowStyled,
  SwitchButtonContainer,
  SwitchButtonLabel,
  ParametersContainer,
} from "./AvailableSlotsContent.styled";
import { useTranslation } from "react-i18next";
import SwitchButton from "components/SwitchButton/SwitchButton";
import { secondaryThemeColors } from "themes/secondaryTheme/secondaryThemeColors";
import { formatDate } from "util/dateHelpers";
import { Box } from "@mui/material";
import CollapsibleTable from "components/CollapsibleTable/CollapsibleTable";
import clientsTableConstants from "constants/tableConstants/clientsTableConstants";

const AvailableSlotsContent = (props) => {
  const { t } = useTranslation();
  const [notAllowedPreviousWeeks, setNotAllowedPreviousWeeks] = useState(true);
  const startOfWeek = props.startAndEndOfWeek.startOfWeek;
  const endOfWeek = props.startAndEndOfWeek.endOfWeek;

  const showCalendar = () => {};

  const showTable = () => {};

  const onClickLeftArrow = async () => {
    if (notAllowedPreviousWeeks) {
      return;
    }
    const today = new Date();
    const prevMonday = new Date(startOfWeek);
    prevMonday.setDate(startOfWeek.getDate() - 7);
    const prevSunday = new Date(endOfWeek);
    prevSunday.setDate(endOfWeek.getDate() - 7);
    props.onChangePeriod({ startOfWeek: prevMonday, endOfWeek: prevSunday });
    if (today >= prevMonday) {
      setNotAllowedPreviousWeeks(true);
    }
  };

  const onClickRightArrow = async () => {
    const nextMonday = new Date(startOfWeek);
    nextMonday.setDate(startOfWeek.getDate() + 7);
    const nextSunday = new Date(endOfWeek);
    nextSunday.setDate(endOfWeek.getDate() + 7);
    props.onChangePeriod({ startOfWeek: nextMonday, endOfWeek: nextSunday });
    setNotAllowedPreviousWeeks(false);
  };

  return (
    <AvailableSlotsContentStyled>
      <ParametersContainer>
        <LabelAndCalendarContainer>
          <ChooseTimeLabel>
            {t("scheduleAppointment.chooseTime")}
          </ChooseTimeLabel>
          <SwitchButtonContainer>
            <SwitchButtonLabel>
              {t("scheduleAppointment.calendar")}
            </SwitchButtonLabel>
            <SwitchButton
              onSelect={showCalendar}
              onDeselect={showTable}
              activeColor={secondaryThemeColors.darkBlue}
              inactiveColor={secondaryThemeColors.paginationSelected}
            />
          </SwitchButtonContainer>
        </LabelAndCalendarContainer>
        <ChoosePeriodContainer>
          <PeriodLabel>{t("scheduleAppointment.periodLabel")}</PeriodLabel>
          <LeftArrowStyled
            onClick={onClickLeftArrow}
            disabled={notAllowedPreviousWeeks}
          />
          <PeriodText>
            <DatesContainer>
              <Box>{formatDate(startOfWeek, "dd.MM.yyyy")}</Box>
              <Box>{"-"}</Box>
              <Box>{formatDate(endOfWeek, "dd.MM.yyyy")}</Box>
            </DatesContainer>
          </PeriodText>
          <RightArrowStyled onClick={onClickRightArrow} />
        </ChoosePeriodContainer>
      </ParametersContainer>
      <AvailableSlotsTableContainer>
        <CollapsibleTable
          tableHeaders={clientsTableConstants}
          t={t}
          timeSlots={props.timeSlots}
          serviceName={props?.clientService?.name}
          passSelectedTimeSlot={props?.passSelectedTimeSlot}
          selectedAttributes={props?.selectedAttributes}
          numberOfCharactersPerCell={30}
        />
      </AvailableSlotsTableContainer>
    </AvailableSlotsContentStyled>
  );
};

AvailableSlotsContent.propTypes = {
  clientService: PropTypes.any,
  startAndEndOfWeek: PropTypes.object,
  onChangePeriod: PropTypes.func,
  timeSlots: PropTypes.array,
  selectedAttributes: PropTypes.array,
  passSelectedTimeSlot: PropTypes.func,
};

export default AvailableSlotsContent;
