import { TableContainer } from "@mui/material";
import styled from "styled-components";

export const TableContainerStyled = styled(TableContainer)`
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  max-width: ${({ isClient }) => (isClient ? "100%" : "")};
  height: ${({ isClient }) => (isClient ? "100%" : "")};
  padding: ${({ isClient }) => (isClient ? "10px" : "0px")};
  table-layout: fixed;
`;
