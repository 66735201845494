import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomSelectFieldStyled from "../../InputFields/CustomFields/CustomSelectField";
import CustomFieldLabel from "../../InputFields/CustomFields/CustomFieldLabel";
import { FormikParams } from "constants/formikParams";
import { Box } from "@mui/material";
import { useGetAllCountriesQuery } from "features/lookup/lookupSlice";

const CountryField = (props) => {
  const { t } = useTranslation();
  const handleChange = (changedValue) => {
    props.formik.setFieldValue("country", changedValue);
    if (props.setSelectedCountry) props.setSelectedCountry(changedValue.name);
  };
  const { data, isLoading } = useGetAllCountriesQuery();

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomSelectFieldStyled
        name={props.name}
        formik={props.formik}
        value={props?.formik?.values?.country}
        placeholder={t(props.placeholder)}
        items={
          isLoading
            ? []
            : data?.data?.countryNames?.map((country) => {
                return {
                  id: country.name,
                  name: country.name,
                };
              })
        }
        passValue={handleChange}
      />
    </Box>
  );
};

CountryField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  formik: PropTypes.any,
  value: PropTypes.any,
  setSelectedCountry: PropTypes.func,
};

CountryField.defaultProps = {
  label: "common.labelCountry",
  name: FormikParams.country,
  placeholder: "common.countrySelect",
};

export default CountryField;
