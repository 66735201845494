export const ResourceTypes = {
  EMPLOYEE: 1,
  EQUIPMENT: 2,
  PREMISE: 3,
  OTHER: 4
};

export const resourceTypes = [
  {
    id: ResourceTypes.EMPLOYEE,
    name: "superAdmin.table.clientResources.resourceType.employee",
  },
  {
    id: ResourceTypes.EQUIPMENT,
    name: "superAdmin.table.clientResources.resourceType.equipment",
  },
  {
    id: ResourceTypes.PREMISE,
    name: "superAdmin.table.clientResources.resourceType.premise",
  },
  {
    id: ResourceTypes.OTHER,
    name: "superAdmin.table.clientResources.resourceType.other",
  },
];
