import React from "react";
import PropTypes from "prop-types";
import { BackButtonContainer } from "./BackButton.styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow.svg";

export const BackButton = (props) => {
  const { t } = useTranslation();
  return (
    <BackButtonContainer
      onClick={props.onClick}
      value={t("common.back")}
      startIcon={<LeftArrow />}
      {...props}
    ></BackButtonContainer>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  colorPalet: PropTypes.string,
};

export default BackButton;
