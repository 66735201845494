import styled from "styled-components";
import { ReactComponent as ChipDeleteIcon } from "assets/images/svg/remove-filter-icon.svg";

export const FilterChipsContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const FilterChip = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: ${(props) => props?.theme?.colors?.textBlack};
  background-color: ${(props) => props?.theme?.colors?.filterChipColor};
  padding: 0px 8px;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const ChipDeleteIconStyled = styled(ChipDeleteIcon)`
  cursor: pointer;
  width: 15px;
  height: 15px;
`;
