import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const userRegisterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyEmail: builder.mutation({
      query: (userData) => ({
        url: apiEndpoints.registration.verifyEmail,
        method: 'POST',
        body: userData,
      }),
    }),
  
    requestEmailVerificationCode: builder.mutation({
      query: (requestData) => ({
        url: apiEndpoints.registration.requestEmailVerificationCode,
        method: 'POST',
        body: requestData,
      }),
    }),

    checkEmail: builder.query({
      query: (email) => ({
        url: apiEndpoints.registration.checkEmail.replace("{email}", email),
        }),
    }),
  
    addPendingApplicationUser: builder.mutation({
      query: (requestData) => ({
        url: apiEndpoints.registration.addPendingApplicationUser,
        method: 'POST',
        body: requestData,
      }),
    }),
  }),
});

export const {
    useVerifyEmailMutation, useRequestEmailVerificationCodeMutation,
    useLazyCheckEmailQuery, useAddPendingApplicationUserMutation
} = userRegisterApiSlice;