import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  CancelButton,
  LeaveButton,
  LowerContainer,
  Modal,
  StyledQuestionMarkImg,
  Title,
  UpperContainer,
} from "./UnsavedAlertModal.styled";
import { unsetShownModal } from "features/modal/modalSlice";
import { resetFlag } from "features/clients/clientSlice";
import { useNavigate } from "react-router-dom";
import { resetSubmitFlag } from "features/clientService/clientServiceSlice";

const UnsavedAlertModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leavePage = () => {
    dispatch(unsetShownModal());
    dispatch(resetFlag());
    dispatch(resetSubmitFlag());
    setTimeout(() => {
      navigate(props.path.nextLocation.pathname);
  }, 0);
  };

  const cancel = () => {
    dispatch(unsetShownModal());
  };

  return (
      <Modal>
        <UpperContainer>
          <StyledQuestionMarkImg />
          <Title>{t("superAdmin.table.clients.unsavedData")}</Title>
        </UpperContainer>
        <LowerContainer>
          <LeaveButton value={t("common.giveUp")} onClick={() => cancel()} />
          <CancelButton value={t("common.leave")} onClick={() => leavePage()} />
        </LowerContainer>
      </Modal>
  );
};

UnsavedAlertModal.propTypes = {
  path: PropTypes.string,
};

export default UnsavedAlertModal;
