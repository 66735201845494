import { hexToRGB } from "util/colorHelper";

export const primaryThemeColors = {
  primaryDark: "#393646",
  secondaryDark: "#4F4557",
  secondaryLighter: "#6D5D6E",
  backgroundColor: "#F3F6F9",
  primaryLighter: "#836E80",
  headerColor: "#FFFFFF",
  textColor: "#C4DFDF",
  purpleColor: "#855CE0",
  purpleBoxBackgournd: "#F7FDFF",
  greenColor: "#35C2C1",
  greenBoxBackground: "#F9FFFF",
  primaryColor: "#35C2C1",
  secondaryColor: "#855CE0",
  secondaryColorHover: "#8E76C2",
  primaryColorHover: "#66B5B4",
  completedRegistration: "#0A2323",
  uncompletedRegistration: "#658383",
  uncompletedRegistrationBackground: "#9BADAD",
  iconColor: "#FDFDFD",
  errorColor: "#FF4D4D",
  selectCountryPhoneCodeBackground: "#F3F6F9",
  fieldBorderColor: "#ccc",
  darkBlue: "#173F61",
  hoverDarkBlue: "#173F81",
  black: "#000000",
  white: "#FFFFFF",
  statusActiveBackground: "#E6F4EA",
  statusInactiveBackground: "#FDECEC",
  statusActiveText: "#4CAF50",
  statusInactiveText: "#F44336",
  placeholderText: "#9C9EAA",
  notificationGreen: "#6CAA7D",
  notificationRed: "#FF0000",
  timeSlotBg: "#B3C7D7",
  timeSlotText: "#173F61",
  accordionClosed: "#F3F6F9",
  shadowColor: hexToRGB("#9BADAD40", 0.25),
};

export const clientColors = {
  buttonColor: primaryThemeColors?.clientRegistration?.Circle,
  buttonColorHover: primaryThemeColors?.clientRegistration?.Circle,
  buttonTextColor: primaryThemeColors?.textBlack,
};

export const userColors = {
  buttonColor: primaryThemeColors?.userRegistration?.Circle,
  buttonColorHover: primaryThemeColors?.userRegistration?.Circle,
  buttonTextColor: primaryThemeColors?.textBlack,
};

export const cardColors = [
  {
    background: "#F98A2A14",
    text: "#F98A2A",
  },
  {
    background: "#F5EDFD",
    text: "#9966CC",
  },
  {
    background: "#FFD7000D",
    text: "#F2CC00",
  },
];