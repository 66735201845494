import React from "react";
import {
  ClientServiceTableSectionContainer,
  ClientServiceTableSectionStyled,
  ClientServiceTableSectionWithoutHeaderStyled,
} from "./ClientServiceTableSection.styled";
import resourcesTableConstants from "constants/tableConstants/resourcesTableConstants";
import { useTranslation } from "react-i18next";
import attributesTableConstants from "constants/tableConstants/attributesTableConstants";
import ClientServiceTableHeader from "./ClientServiceTableHeader";
import ClientServiceTable from "./ClientServiceTable/ClientServiceTable";
import PropTypes from "prop-types";
import {
  deleteAttribute,
  deleteResourceType,
  submit,
} from "features/clientService/clientServiceSlice";
import { useDispatch } from "react-redux";
import {
  setClientServiceAttributeModal,
  setClientServicResourceModal,
  unsetShownModal,
} from "features/modal/modalSlice";

const ClientServiceTableSection = (props) => {
  const { t } = useTranslation();
  const mapRowDataFunctionAttributes = (rowData) =>
    rowData?.attributes?.map?.((attributes) => {
      return {
        ...attributes,
      };
    });

  const dispatch = useDispatch();
  const mapRowDataFunctionResources = (rowData) => {
    return rowData?.requiredResourceTypes?.map?.((resourceType) => {
      const resourceNames = resourceType?.possibleResources?.map(
        (possibleResource) => possibleResource?.resource?.name
      );
      return {
        ...resourceType,
        resourceNames,
      };
    });
  };

  const onCancel = () => {
    dispatch(unsetShownModal());
  };

  const editBtnClickAttribute = (attribute) => {
    dispatch(
      setClientServiceAttributeModal({
        selectedItem: attribute,
        onCancel: onCancel,
      })
    );
  };
  const deleteBtnClickAttribute = (id) => {
    dispatch(submit());
    dispatch(deleteAttribute(id));
  };
  const editBtnClickResource = (resource) => {
    dispatch(
      setClientServicResourceModal({
        selectedItem: resource,
        onCancel: onCancel,
      })
    );
  };
  const deleteBtnClickResource = (id) => {
    dispatch(submit());
    dispatch(deleteResourceType(id));
  };

  const handleOpenModalAddResource = () => {
    dispatch(
      setClientServicResourceModal({
        selectedItem: null,
        onCancel: onCancel,
      })
    );
  };
  const handleOpenModalAddAttribute = () => {
    dispatch(
      setClientServiceAttributeModal({
        selectedItem: null,
        onCancel: onCancel,
      })
    );
  };

  return (
    <ClientServiceTableSectionContainer>
      <ClientServiceTableSectionStyled>
        <ClientServiceTableHeader
          headerTitle={t("clientService.serviceResources")}
          handleOpenModalAdd={handleOpenModalAddResource}
        />
        <ClientServiceTableSectionWithoutHeaderStyled>
          <ClientServiceTable
            mapRowDataFunction={mapRowDataFunctionResources}
            tableHeaders={resourcesTableConstants}
            rowBtnClick={editBtnClickResource}
            deleteBtnClick={deleteBtnClickResource}
            data={props?.data}
            t={t}
          />
        </ClientServiceTableSectionWithoutHeaderStyled>
      </ClientServiceTableSectionStyled>
      <ClientServiceTableSectionStyled>
        <ClientServiceTableHeader
          headerTitle={t("clientService.serviceAttributes")}
          handleOpenModalAdd={handleOpenModalAddAttribute}
        />
        <ClientServiceTableSectionWithoutHeaderStyled>
          <ClientServiceTable
            mapRowDataFunction={mapRowDataFunctionAttributes}
            tableHeaders={attributesTableConstants}
            rowBtnClick={editBtnClickAttribute}
            deleteBtnClick={deleteBtnClickAttribute}
            data={props?.data}
            t={t}
          />
        </ClientServiceTableSectionWithoutHeaderStyled>
      </ClientServiceTableSectionStyled>
    </ClientServiceTableSectionContainer>
  );
};

ClientServiceTableSection.propTypes = {
  data: PropTypes.any,
};

export default ClientServiceTableSection;
