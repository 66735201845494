import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SingleFormStyled } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomSelectFieldStyled from "components/InputFields/CustomFields/CustomSelectField";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import { useTranslation } from "react-i18next";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const PrimaryServiceField = (props) => {
  const handleChange = (changedValue) => {
    props?.formik?.setFieldValue(
      "primaryServiceCategoryName",
      changedValue.name
    );
    props?.formik?.setFieldValue(
      "primaryServiceCategoryId",
      changedValue.primaryId
    );
  };


  const { t } = useTranslation();
  const { data, isLoading } = useGetAllServiceCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (data?.data?.serviceCategories && props?.formik?.values?.primaryServiceCategoryId) {
      const selected = data?.data?.serviceCategories?.find(
        (category) => category?.id === props?.formik?.values?.primaryServiceCategoryId
      );
      setSelectedCategory(selected);
    }
  }, [data, props?.formik?.values?.primaryServiceCategoryId]);

  return (
    <SingleFormStyled>
      <CustomFieldLabel
        isRequired={false}
        label={t("superAdmin.table.clients.details.primaryServiceCategory")}
        isClient={true}
      />

      <CustomSelectFieldStyled
        name={clientFormikParams.primaryServiceCategoryName}
        formik={props?.formik}
        placeholder={props?.formik?.values?.primaryServiceCategoryName}
        isClient
        height={"40px"}
        items={
          isLoading
            ? []
            : data?.data?.serviceCategories?.map((x) => ({
                name: x?.name,
                id: x?.name,
                primaryId: x?.id,
              }))
        }
        passValue={handleChange}
        selectedValueFlag={selectedCategory?.id}
      />
    </SingleFormStyled>
  );
};

PrimaryServiceField.propTypes = {
  formik: PropTypes.any,
};

export default PrimaryServiceField;
