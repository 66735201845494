import React from "react";
import PropTypes from "prop-types";
import { LogInButtonContainer } from "./LogInButton.styled";
import { useTranslation } from "react-i18next";

export const LogInButton = (props) => {
  const { t } = useTranslation();
  return (
    <LogInButtonContainer
      onClick={props.onClick}
      value={t("login.logIn")}
      fullWidth
    />
  );
};

LogInButton.propTypes = {
  onClick: PropTypes.func,
};
