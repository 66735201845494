import React, { useRef } from "react";
import {
  CategoryList,
  ScrollButton,
  ServiceCategoriesContainer,
  TitleContainer,
  CardsContainer,
  ArrowStyled,
  CardFirstStyled,
  CardFirstText,
  CardFirstTitle,
  CardImageContainer,
  CardImageContainerSecond,
  CardImageContainerSecondNotLogged,
  CardSecondStyled,
  CardSecondText,
  CardSecondTitle,
  CardSectionContainer,
  MainContentContainer,
  TextContainer,
} from "./LandingPageContent.styled";
import PropTypes from "prop-types";
import { TEXTVARIANT } from "constants/textConstants";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/useAuth";
import { PAGES } from "constants/pages";
import { useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard";
import { useGetPaginatedAppointmentsForUserQuery } from "features/appointmentSlice/appointmentApiSlice";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import { useDispatch } from "react-redux";
import { setServiceCategory } from "features/serviceCategory/serviceCategorySlice";

const LandingPageContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetAllServiceCategoriesQuery();
  const listRef = useRef(null);
  const { isLoggedIn, user } = useAuth();

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const onClickSelect = (category) => {
    dispatch(setServiceCategory(category));
    navigate(PAGES.CLIENTS.route);
  };
  const { data: appointments } = useGetPaginatedAppointmentsForUserQuery({
    id: user?.id,
    pageSize: 3,
  });

  const paginatedAppointments = appointments?.data?.appointments;

  return (
    <MainContentContainer>
      <CardSectionContainer>
        <CardFirstStyled>
          <CardImageContainer />
          <TextContainer>
            <CardFirstTitle variant={TEXTVARIANT.H3}>
              {t("home.firstSection.firstCardTitle")}
            </CardFirstTitle>
            <CardFirstText>
              {t("home.firstSection.firstCardText")}
            </CardFirstText>
          </TextContainer>
        </CardFirstStyled>
        <CardSecondStyled
          isLoggedIn={isLoggedIn}
          to={isLoggedIn ? PAGES.APPOINTMENTS.route : PAGES.ABOUT.route}
        >
          {isLoggedIn ? (
            <CardImageContainerSecond />
          ) : (
            <CardImageContainerSecondNotLogged />
          )}
          <TextContainer>
            <CardSecondTitle>
              {isLoggedIn
                ? t("home.firstSection.secondCardLoggedInTitle")
                : t("home.firstSection.secondCardNotLoggedInTitle")}
            </CardSecondTitle>
            {isLoggedIn ? (
              paginatedAppointments && paginatedAppointments.length > 0 ? (
                paginatedAppointments.map((appointment) => {
                  const localDate = new Date(appointment.fromTimeUtc);
                  const time = convertUTCToLocalTime(appointment.fromTimeUtc);

                  let formattedDate = localDate.toLocaleDateString("sr-RS", {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  });
                  formattedDate = formattedDate
                    .split(" ")
                    .map((word, index) =>
                      index === 0 || index === 2
                        ? word.charAt(0).toUpperCase() + word.slice(1)
                        : word
                    )
                    .join(" ");
                  return (
                    <CardSecondText key={appointment.id}>
                      {t("home.firstSection.appointmentText", {
                        date: formattedDate,
                        time: time,
                        appointmentService: appointment.clientService.name,
                      })}
                    </CardSecondText>
                  );
                })
              ) : (
                <CardSecondText>
                  {t("home.firstSection.secondCardLoggedInText")}
                </CardSecondText>
              )
            ) : (
              <CardSecondText>
                {t("home.firstSection.secondCardNotLoggedInText")}
              </CardSecondText>
            )}
            <ArrowStyled />
          </TextContainer>
        </CardSecondStyled>
      </CardSectionContainer>
      <ServiceCategoriesContainer>
        <TitleContainer>{t("serviceCategory.selectService")}</TitleContainer>
        <CardsContainer>
          <ScrollButton left onClick={scrollLeft}>
            &lt;
          </ScrollButton>
          <CategoryList ref={listRef}>
            {data?.data?.serviceCategories?.map((category, index) => (
              <CategoryCard
                key={index}
                onClick={() => onClickSelect(category)}
                imageId={category?.imageId}
                name={category.name}
                index={index}
              />
            ))}
          </CategoryList>
          <ScrollButton onClick={scrollRight}>&gt;</ScrollButton>
        </CardsContainer>
      </ServiceCategoriesContainer>
    </MainContentContainer>
  );
};

LandingPageContent.propTypes = {
  theme: PropTypes.any,
};

export default LandingPageContent;
