import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ActionContainer,
  InfoContainer,
  MainContainer,
  NameContainer,
  StatusContainer,
  StatusButtonContainer,
  StatusText,
  ActiveCircle,
  LongDash,
  ImageAndNameContainer,
  DataContainer,
  CustomInputFieldStyled,
  StatusAndImageAndNameContainer,
  FieldsContainer,
  AvatarStyled,
} from "./UserPageContent.styled";
import { useTranslation } from "react-i18next";
import BackButton from "components/RightAuthContent/Buttons/BackButton/BackButton";
import { ReactComponent as CheckImg } from "assets/images/svg/status-active-check.svg";
import { ReactComponent as XImg } from "assets/images/svg/status-inactive-x.svg";
import { useDispatch } from "react-redux";
import { setChangeUserStatusModal } from "features/modal/modalSlice";
import authType from "constants/authType";

export const UserPageContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isActive = useMemo(() => {
    return props?.user?.isActive;
  }, [props?.user]);

  const onClickStatusButton = () => {
    dispatch(
      setChangeUserStatusModal({
        userId: props?.user.id,
        active: isActive,
        userType: authType.USER,
      })
    );
  };

  const initials =
    props?.user?.firstName && props?.user?.lastName
      ? `${props?.user.firstName[0]}${props?.user.lastName[0]}`.toUpperCase()
      : null;

  return (
    <MainContainer>
      <InfoContainer>
        <StatusAndImageAndNameContainer>
          <StatusContainer>
            <StatusText active={isActive}>
              {isActive
                ? t("superAdmin.table.users.labelStatusActive")
                : t("superAdmin.table.users.labelStatusInactive")}
            </StatusText>
            <StatusButtonContainer
              active={isActive}
              onClick={onClickStatusButton}
            >
              <ActiveCircle>{isActive ? <CheckImg /> : <XImg />}</ActiveCircle>
            </StatusButtonContainer>
          </StatusContainer>
          <ImageAndNameContainer>
            <AvatarStyled>{initials}</AvatarStyled>
            <NameContainer>
              {props?.user?.firstName + " " + props?.user?.lastName}
            </NameContainer>
          </ImageAndNameContainer>
        </StatusAndImageAndNameContainer>
        <DataContainer>
          <LongDash />
          <FieldsContainer>
            <CustomInputFieldStyled
              label={"common.labelEmailAddress"}
              value={props?.user?.email}
              disabled
            />
            <CustomInputFieldStyled
              label={"common.labelPhoneNumber"}
              value={props?.user?.phoneNumber}
              disabled
            />
            <CustomInputFieldStyled
              label={"common.labelCountry"}
              value={props?.user?.country}
              disabled
            />
            <CustomInputFieldStyled
              label={"common.labelCity"}
              value={props?.user?.city}
              disabled
            />
          </FieldsContainer>
        </DataContainer>
      </InfoContainer>
      <ActionContainer>
        <BackButton onClick={props.onClickBackBtn} />
      </ActionContainer>
    </MainContainer>
  );
};

UserPageContent.propTypes = {
  user: PropTypes.object,
  onClickBackBtn: PropTypes.func,
};

export default UserPageContent;
