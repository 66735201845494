export const VARIANT = {
  TEXT: "text",
  OUTLINED: "outlined",
  CONTAINED: "contained",
  BODY2: "body2",
};

export const SIZE = {
  SMALL: "small",
};

export const TYPE = {
  FILE: "file",
  NUMBER: "number",
};
