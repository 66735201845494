import React from 'react';
import PropTypes from 'prop-types';
import { ClientSwitchStyled } from './ClientSwitch.styled';


const ClientSwitch = ( props ) => {
  return (
      <ClientSwitchStyled
        checked={props.allowed}
        onChange={props.onChange}
      />
  );
};

ClientSwitch.propTypes = {
  allowed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClientSwitch;