import dayjs from "dayjs";

export const groupTimeSlotsByDay = (timeSlots, serviceName, attributes) => {
  const grouped = timeSlots.reduce((acc, slot, index) => {
    const date = dayjs(slot.timeSlotsStartTime).format("YYYY-MM-DD");
    if (!acc[date]) acc[date] = [];
    acc[date].push({
      ...slot,
      id: index,
    });
    return acc;
  }, {});
  return Object.keys(grouped).map((date) => ({
    id: date,
    date: date,
    nestedRows: grouped[date].map((slot) => ({
      attributes,
      serviceName,
      id: slot.id,
      startTime: slot.timeSlotsStartTime,
      endTime: slot.timeSlotsEndTime,
      serviceDuration: slot.serviceDuration,
      resources: slot.resources,
      date: date,
    })),
  }));
};
