import styled from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

export const Modal = styled.div`
  min-width: 20%;
  max-width: 650px;
  height: fit-content;
  box-sizing: border-box;
  overflow: hidden;
`;

export const StatusInputContainer = styled.div`
  display: flex;
  gap: 5px;
  align-self: flex-start;
  padding-left: 30px;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: 80%;
  overflow: auto;
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props.theme?.colors?.white};
  justify-content: center;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: 20%;
  padding: 25px 30px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ActionButton = styled(Button)`
  height: 80%;
  width: 30%;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
`;

export const CancelActionButton = styled(Button)`
  height: 80%;
  width: 30%;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => props.theme?.colors?.black};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};
`;
