import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  DeleteButton,
  HiddenInput,
  ImagePreviewContainer,
  PreviewImage,
  UploadBox,
  UploadContent,
} from "./ImageUpload.styled";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { submit } from "features/clientService/clientServiceSlice";

const ImageUpload = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDragOver, setIsDragOver] = useState(false);

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      if (props.onImageSelect) {
        props.onImageSelect({ file, url: imageUrl });
        dispatch(submit());
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
    dispatch(submit());
  };

  const handleDragLeave = () => {
    dispatch(submit());
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      if (props.onImageSelect) {
        dispatch(submit());
        props.onImageSelect({ file, url: imageUrl });
      }
    }
  };

  const handleDeleteImage = () => {
    if (props.onDeleteImage) {
      dispatch(submit());
      props.onDeleteImage();
    }
  };

  return (
    <div>
      {props.uploadedImage ? (
        <ImagePreviewContainer>
          <PreviewImage
            src={props.uploadedImage.url}
            alt="Uploaded"
            onClick={handleDeleteImage}
          />
          <DeleteButton onClick={handleDeleteImage} />
        </ImagePreviewContainer>
      ) : (
        <UploadBox
          isDragOver={isDragOver}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <HiddenInput
            type="file"
            onChange={handleImageInputChange}
            accept="image/*"
          />
          <UploadContent>
            <span>+</span>
            <p>{t("imageGallery.addImage")}</p>
          </UploadContent>
        </UploadBox>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  onImageSelect: PropTypes.func.isRequired,
  uploadedImage: PropTypes.object,
  onDeleteImage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {
  uploadedImage: null,
};

export default ImageUpload;
