import Button from "components/Button/Button";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow.svg"

export const NextButtonContainer = styled(Button)`
    background-color: ${props=> props.colorPalet.buttonColor};
    color: ${props => props.colorPalet.buttonTextColor};
    &:hover {
        background-color: ${props => props.colorPalet.buttonColorHover};
    }
`
export const RightArrowStyled = styled(RightArrow)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.textBlack};
  }
`