import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";

export const StyledSelect = styled(Select)`
  z-index: 10000;
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
    width: 100%;
  }

  & .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
  {
    padding: 8.5px 14px !important;
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
  }

  & em {
    font-style: normal;
  }

  &.css-1wc848c-MuiFormHelperText-root
  {
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 100%;
  }
`;