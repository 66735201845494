export const FormikParams = {
  username: "username",
  companyName: "companyName",
  primaryService: "primaryService",
  country: "country",
  city: "city",
  address: "address",
  countryPhoneCode: "countryPhoneCode",
  phoneNumber: "phoneNumber",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  password: "password",
  confirmedPassword: "confirmedPassword",
  sentEmailCode: "sentEmailCode",
  sentSMSCode: "sentSMSCode",
};
