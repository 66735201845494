import React from "react";
import PropTypes from "prop-types";
import { SingleForm } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const CountryField = (props) => {
  return (
    <SingleForm
      formik={props?.formik}
      name={clientFormikParams.country}
      error={
        props.formik?.touched?.country && Boolean(props.formik?.errors?.country)
      }
    />
  );
};

CountryField.propTypes = {
  formik: PropTypes.any,
};
