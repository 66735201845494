import React from "react";
import {
  ArrowImage,
  CollapsedLogoContainer,
  LeftArrowImage,
  LogoContainer,
  RightArrowImage,
  SidebarContainer,
  StyledLink,
  TabsContainer,
} from "./Sidebar.styled";

import sidebarNavigationConstants from "constants/sidebarNavigationConstants";
import SidebarNavigationItem from "./SidebarTabs/SidebarNavigationItem";
import WithPermissions from "components/WithPermissions/WithPermissions";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "features/sidebar/sidebarSlice";
import { selectIsSidebarCollapsed } from "features/sidebar/sidebarSlice";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const isCollapsed = useSelector(selectIsSidebarCollapsed);

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <SidebarContainer collapsed={isCollapsed}>
      <StyledLink to={PAGES.BASE.route}>
        {!isCollapsed ? <LogoContainer /> : <CollapsedLogoContainer />}
      </StyledLink>
      <TabsContainer collapsed={isCollapsed}>
        {sidebarNavigationConstants.map((singleNavigationConstant) => (
          <WithPermissions
            key={singleNavigationConstant.path}
            roles={singleNavigationConstant.hasRole}
          >
            <SidebarNavigationItem
              collapsed={isCollapsed}
              path={singleNavigationConstant.path}
              icon={singleNavigationConstant.icon}
              title={singleNavigationConstant?.title}
              iconSelected={singleNavigationConstant.iconSelected}
            />
          </WithPermissions>
        ))}
      </TabsContainer>
      <ArrowImage>
        <RightArrowImage
          showArrow={isCollapsed}
          onClick={handleSidebarToggle}
        />
        <LeftArrowImage hideArrow={isCollapsed} onClick={handleSidebarToggle} />
      </ArrowImage>
    </SidebarContainer>
  );
};

export default Sidebar;
