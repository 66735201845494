import { Typography } from "@mui/material";
import { ReactComponent as ProfilePicture } from "../../../assets/images/svg/super-admin-profile-picture.svg";
import styled, { css } from "styled-components";
import Button from "components/Button/Button";
import { ReactComponent as X_Icon } from "assets/images/svg/x-icon.svg";

const fieldBorderColor = (props) => props.theme?.colors?.fieldBorderColor;
const blackColor = (props) => props.theme?.colors?.black;
const whiteColor = (props) => props.theme?.colors?.white;
const darkBlueColor = (props) => props.theme?.colors?.darkBlue;
import {ReactComponent as ClientHeaderIcon} from "../../../assets/images/svg/klijenti-selektovano.svg"


export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px 10px 0px;
  padding-right: 30px;
`;

export const UpperContainterWithProfilePicture = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  width: 100%;
  margin-bottom: 30px;
  margin-top:25px;
`;

const StyledImage = styled.img`
  display: flex;
  align-self: center;
  width: 40px;
  height: 40px;
`;

export const ProfilePictureContainer = styled(StyledImage).attrs({
  as: ProfilePicture,
})``;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  margin: 10px 0px 0px 25px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-weight: bold;
  display:flex;
  cursor:${(props)=> props?.linkBack ? "pointer" : "auto"};
`;

export const FilterPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${whiteColor};
  width: fit-content;
  height: fit-content;
  position: absolute;
  padding: 32px 24px;
  border-radius: 8px;
  border: 1px solid ${blackColor};
`;

export const UpperFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 175px;
          }
        `
      : css`
          & > div {
            width: 366px;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;

export const LowerFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const FilterTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${darkBlueColor};
`;

export const X_IconStyled = styled(X_Icon)`
  cursor: pointer;
`;
export const CancelButton = styled(Button)`
  border-radius: 12px;
  padding: 6px 32px 6px 32px;
  max-height: 33px;
  max-width: 109px;
  background-color: ${fieldBorderColor};
  border-color: ${blackColor};
  &:hover {
    background-color: ${fieldBorderColor};
    border-color: ${blackColor};
  }
  color: ${blackColor};
  font-size: 14px;
  font-weight: 600;
`;

export const SaveFiltersButton = styled(Button)`
  border-radius: 12px;
  padding: 6px 32px 6px 32px;
  max-height: 33px;
  max-width: 109px;
  background-color: ${darkBlueColor};
  border-color: ${blackColor};
  &:hover {
    background-color: ${darkBlueColor};
    border-color: ${blackColor};
  }
  color: ${whiteColor};
  font-size: 14px;
  font-weight: 600;
`;

export const ClientHeaderIconStyled = styled(ClientHeaderIcon)`
  height:45px;
  width:45px;
  margin-top:30px;
`;