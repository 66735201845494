import { SingleForm } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm";
import React from "react";
import PropTypes from "prop-types";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const ClientAdressField = (props) => {
  return (
    <SingleForm
      formik={props?.formik}
      name={clientFormikParams.address}
      lastInput={props?.lastInput}
      error={
        props.formik?.touched?.address && Boolean(props.formik?.errors?.address)
      }
    />
  );
};

ClientAdressField.propTypes = {
  formik: PropTypes.any,
  lastInput: PropTypes.bool,
};
