import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTypography,
  LastCell,
  NoRowsBody,
  StyledTooltip,
} from "./TableBodyContainer.styled";
import { TEXTVARIANT } from "constants/textConstants";
import tooltipConstants from "constants/tooltipConstants";

const TableBodyContainer = (props) => {
  return (
    <StyledTableBody>
      {props?.rows?.map((row) => (
        <StyledTableRow key={row.id}>
          {props.tableHeaders.map((col, index) => {
            let data = row?.[col?.field];
            const formattedData = col?.valueFormatter?.(data, row) ?? data;
            const isLastColumn = index === props.tableHeaders.length - 1;
            const cellRendered = col?.cellRenderer ? (
              col.cellRenderer({
                name: formattedData,
                onClickEdit: () => props.rowBtnClick(row),
                onClickDelete: () => props?.deleteBtnClick(row.id),
                row,
              })
            ) : formattedData.length > props?.numberOfCharactersPerCell ? (
              <StyledTooltip
                title={formattedData}
                placement={tooltipConstants.TOP}
              >
                <StyledTypography variant={TEXTVARIANT.H5}>
                  {formattedData.slice(0, props?.numberOfCharactersPerCell) +
                    "..."}
                </StyledTypography>
              </StyledTooltip>
            ) : (
              <StyledTypography variant={TEXTVARIANT.H5}>
                {formattedData}
              </StyledTypography>
            );
            return isLastColumn ? (
              <LastCell key={col.field}>{cellRendered}</LastCell>
            ) : (
              <StyledTableCell key={col.field}>{cellRendered}</StyledTableCell>
            );
          })}
        </StyledTableRow>
      ))}
      {props?.rows?.length === 0 && <NoRowsBody />}
    </StyledTableBody>
  );
};

TableBodyContainer.propTypes = {
  rows: PropTypes.any,
  rowBtnClick: PropTypes.func,
  deleteBtnClick: PropTypes.func,
  tableHeaders: PropTypes.any,
  theme: PropTypes.any,
  numberOfCharactersPerCell: PropTypes.number,
};

export default TableBodyContainer;
