import styled from "styled-components";
import { ReactComponent as XImg } from "assets/images/svg/x-icon-blue.svg";
import { ReactComponent as CheckImg } from "assets/images/svg/check-solid.svg";

export const AttributesContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ChooseAttributeLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
`;

export const AttributeContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const XImgStyled = styled(XImg)``;

export const CheckImgStyled = styled(CheckImg)`
  padding: 3px;
  fill: ${(props) => props?.theme?.colors?.statusActiveButton};
`;
