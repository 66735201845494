import React from "react";
import { SingleFormStyled } from "./SingleForm.styled";
import PropTypes from "prop-types";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";
import { useTranslation } from "react-i18next";

export const SingleForm = (props) => {
  const handleChange = (event) => {
    if (props?.phone) {
      props?.changeField(event);
    } else {
      props?.formik?.handleChange(event);
    }
  };

  const { t } = useTranslation();

  return (
    <SingleFormStyled lastInput={props?.lastInput} error={props?.error}>
      <CustomFieldLabel
        label={t(`superAdmin.table.clients.details.${props?.name}`)}
        isClient={true}
        required={false}
      />

      <CustomTextField
        formik={props?.formik}
        name={clientFormikParams[props?.name]}
        onChange={handleChange}
        isClient={true}
        placeholder={props?.placeholder}
        height="40px"
        onBlur={props?.onBlur}
        helperText={props?.helperText}
      />
    </SingleFormStyled>
  );
};

SingleForm.propTypes = {
  formik: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  lastInput: PropTypes.bool,
  phone: PropTypes.bool,
  changeField: PropTypes.func,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
};
