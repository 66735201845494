import { AddButtonContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import clientLicensesTableConstants from "constants/tableConstants/clientLicensesTableConstants";
import React from "react";
import { useTranslation } from "react-i18next";
import AddNewItemButton from "components/SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import PropTypes from "prop-types";
import { ClientLicenseContentStyled } from "./ClientLicenseContent.styled";
import Table from "components/Table/Table";
import { useDispatch } from "react-redux";
import {
  setChangeLicenceModal,
  setDeleteLicenceModal,
} from "features/modal/modalSlice";
import AuthType from "constants/authType";
import WithAdminPermissions from "components/WithPermissions/WithAdminPermission/WithAdminPermissions";
import { useGetPaginatedClientLicensesQuery } from "features/clients/clientsApiSlice";

export const ClientLicenseContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenModalAddEdit = (itemEdit) => {
    dispatch(
      setChangeLicenceModal({
        data: itemEdit,
        clientId: props?.clientId,
        authType: AuthType.CLIENT,
      })
    );
  };

  const handleOpenModalDelete = (id) => {
    dispatch(setDeleteLicenceModal({ id: id, authType: AuthType.CLIENT }));
  };

  const mapRowDataFunction = (rowData) => {
    return rowData.data?.clientLicenses?.map?.((clientLicense) => ({
      ...clientLicense,
      type: clientLicense.type,
      startDateUtc: clientLicense.startDateUtc,
      endDateUtc: clientLicense.endDateUtc,
      createdAtUtc: clientLicense.createdAtUtc,
      updatedAtUtc: clientLicense?.updatedAtUtc,
    }));
  };

  return (
    <ClientLicenseContentStyled>
      <WithAdminPermissions>
        <AddButtonContainer>
          <AddNewItemButton
            value={t("superAdmin.table.licenses.addButonForLicenses")}
            onClick={() => handleOpenModalAddEdit(null)}
          />
        </AddButtonContainer>
      </WithAdminPermissions>
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={clientLicensesTableConstants}
        usePaginatedQuery={useGetPaginatedClientLicensesQuery}
        t={t}
        rowBtnClick={handleOpenModalAddEdit}
        isClient={true}
        clientId={props?.clientId}
        deleteBtnClick={handleOpenModalDelete}
        numberOfCharactersPerCell={20}
      />
    </ClientLicenseContentStyled>
  );
};

ClientLicenseContent.propTypes = {
  clientId: PropTypes.any,
};
