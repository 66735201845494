export const licenseParams = (license) => {
  return {
    startDateUtc: license?.startDateUtc ?? "",
    endDateUtc: license?.endDateUtc ?? "",
    type: license?.type ?? "",
  };
};

export const FormikLicenseParams = {
  startDateUtc: "",
  endDateUtc: "",
  type: "",
};
