import React from "react";
import PropTypes from "prop-types";
import { NextButtonContainer, RightArrowStyled } from "./NextButton.styled";
import { useTranslation } from "react-i18next";

export const NextButton = (props) => {
  const { t } = useTranslation();
  return (
    <NextButtonContainer
      disabled={props.disabled}
      onClick={props.onClick}
      colorPalet={props.colorPalet}
      value={props.lastStep ? t("common.finish") : t("common.next")}
      endIcon={<RightArrowStyled />}
    ></NextButtonContainer>
  );
};

NextButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  colorPalet: PropTypes.string,
  lastStep: PropTypes.bool,
};

export default NextButton;
