import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryPhoneCode, setCountryPhoneCode] = useState("");

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        countryPhoneCode,
        setCountryPhoneCode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => useContext(UserContext);
