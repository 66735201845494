import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NextTablePageContainer } from "./NextTablePageButton.styled";
import { VARIANT } from "constants/buttonConstants";

export const NextTablePageButton = (props) => {
  const { t } = useTranslation();
  return (
    <NextTablePageContainer
      variant={VARIANT.TEXT}
      disabled={props.disabled}
      onClick={props.onClick}
      value={t("common.nextTablePage")}
    ></NextTablePageContainer>
  );
};

NextTablePageButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NextTablePageButton;
