import React from "react";
import {
  ClientControlsWrapper,
  Controls,
  StyledTypography,
} from "./RightInfoContent.styled";
import { ClientSwitch } from "components/ClientEditComponents/ClientSwitch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setChangeUserStatusModal } from "features/modal/modalSlice";
import PropTypes from "prop-types";
import authType from "constants/authType";
import { getStatus, setStatus } from "features/clients/clientSlice";
import { SaveButtonWrapper } from "components/ClientEditTab/ClienEditTab.styled";
import { isDataSaved, submit } from "features/clients/clientSlice";
import { selectCurrentUser } from "features/auth/authSlice";

export const ClientControls = (props) => {
  const user = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(setStatus(props?.isActive));
  var status = useSelector(getStatus);
  const flag = useSelector(isDataSaved);

  const handleStatusChange = () => {
    dispatch(
      setChangeUserStatusModal({
        userId: props.clientId,
        active: status,
        userType: authType.CLIENT,
      })
    );
  };

  return (
    <ClientControlsWrapper>
      <Controls>
        <StyledTypography checked={status}>
          {status
            ? t("superAdmin.table.clients.labelStatusActive")
            : t("superAdmin.table.clients.labelStatusInactive")}
        </StyledTypography>
        <ClientSwitch
          disabled={user?.clientId ? true : false}
          checked={status}
          onChange={handleStatusChange}
        />
      </Controls>
      <Controls>
        <SaveButtonWrapper
          isClient={true}
          leftClientPageButton={true}
          value={t("common.save")}
          disabled={!flag}
          onClick={() => dispatch(submit())}
        />
      </Controls>
    </ClientControlsWrapper>
  );
};

ClientControls.propTypes = {
  clientId: PropTypes.string.isRequired,
  isActive: PropTypes.string.isRequired,
};
