import React from "react";
import { Modal } from "../LicenseAddEditModal/LicenceAddEditModal.styled";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  DeleteButton,
  LowerContainerWrapper,
  QuestionMarkIconStyled,
  UpperContainerWrapper,
  TitleStyled,
} from "./LicenseDeleteModal.styled";
import PropTypes from "prop-types";
import { makeToastMessage } from "util/toastMessage";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import { useDeleteLicenseMutation } from "features/clients/clientsApiSlice";

export const LicenseDeleteModal = (props) => {
  const { t } = useTranslation();
  const [deleteLicense] = useDeleteLicenseMutation();
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await deleteLicense(props.id).unwrap();
      makeToastMessage(
        t("superAdmin.table.licenses.licenseDeleted"),
        "success"
      );
    } catch (error) {
      console.error("Failed to delete license:", error);
    }
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      <UpperContainerWrapper>
        <QuestionMarkIconStyled />
        <TitleStyled>
          {t("superAdmin.table.licenses.licenseDeletePrompt")}
        </TitleStyled>
      </UpperContainerWrapper>
      <LowerContainerWrapper>
        <CancelButton
          value={t("common.quit")}
          onClick={() => dispatch(unsetShownModal())}
        />
        <DeleteButton value={t("common.deactivate")} onClick={handleDelete} />
      </LowerContainerWrapper>
    </Modal>
  );
};

LicenseDeleteModal.propTypes = {
  id: PropTypes.any.isRequired,
};

export default LicenseDeleteModal;
