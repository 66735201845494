import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 72px - 50px);
`;

export const ContentCentered = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  padding: 24px;
  margin-top: 30px;
  height: 50%;
`;
