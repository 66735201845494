export const ClientServiceConstants = {
  name: "name",
  description: "description",
  durationInMinutes: "durationInMinutes",
  price: "price",
  resourceTypeName: "resourceTypeName",
  resourceIds: "resourceIds",
  primaryServiceCategoryName: "primaryServiceCategoryName",
  primaryServiceCategoryId: "primaryServiceCategoryId",
};
