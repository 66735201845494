import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomLabelWithFormik = (props) => {
  const { t } = useTranslation();
  return <Typography>{t(props?.label)}</Typography>;
};

CustomLabelWithFormik.propTypes = {
  label: PropTypes.string,
};

export default CustomLabelWithFormik;
