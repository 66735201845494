import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomFieldLabel from "./CustomFieldLabel";
import CustomTextField from "./CustomTextField";
import { Box } from "@mui/material";

const CustomInputField = (props) => {
  const { t } = useTranslation();
  return (
    <Box {...props}>
      <CustomFieldLabel label={t(props.label)} disabled={props?.disabled} />
      <CustomTextField
        placeholder={props?.placeholder}
        name={props.name}
        value={props.value}
        formik={props.formik}
        disabled={props.disabled}
        type={props?.type}
      />
    </Box>
  );
};

CustomInputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  formik: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CustomInputField;
