import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const imagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientLogo: builder.query({
      query: ({ id }) => ({
        url: apiEndpoints.images.getClientLogo(id),
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response, meta) => {
        const imageUrl = URL.createObjectURL(
          new Blob([response], { type: meta.response.headers["Content-Type"] })
        );

        return { imageUrl };
      },
    }),
  }),
});

export const { useGetClientLogoQuery } = imagesApiSlice;
