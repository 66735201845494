import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CustomSelectFieldStyled,
  ChipStyled,
  ChipDelete,
  menuProps,
} from "./CustomSelectField.styled";
import { Box, MenuItem } from "@mui/material";

const CustomMultipleSelectField = (props) => {
  const { formik, fieldNames, fieldIds, items, isClient } = props;
  const [selectedNames, setSelectedNames] = useState(
    formik?.values?.[fieldNames] || []
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedNames = typeof value === "string" ? value.split(",") : value;
    setSelectedNames(selectedNames);

    formik?.setFieldValue(fieldNames, selectedNames);

    const selectedIds = selectedNames.map((name) => {
      const matchedItem = items.find((item) => item.name === name);
      return matchedItem?.primaryId;
    });
    formik?.setFieldValue(fieldIds, selectedIds);
  };

  const handleDelete = (valueToDelete) => {
    const newValues = selectedNames.filter((value) => value !== valueToDelete);
    setSelectedNames(newValues);

    formik?.setFieldValue(fieldNames, newValues);

    const matchedItem = items.find((item) => item.name === valueToDelete);
    const idToDelete = matchedItem?.primaryId;

    const newIds = formik?.values?.[fieldIds]?.filter(
      (id) => id !== idToDelete
    );
    formik?.setFieldValue(fieldIds, newIds);
  };

  return (
    <>
      <CustomSelectFieldStyled
        multiple
        isClient={isClient}
        value={selectedNames}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              return (
                <ChipStyled
                  key={value}
                  label={value}
                  deleteIcon={<ChipDelete />}
                  onDelete={() => handleDelete(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              );
            })}
          </Box>
        )}
        MenuProps={menuProps}
      >
        {items?.map((item) => {
          return (
            item?.isActive && (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            )
          );
        })}
      </CustomSelectFieldStyled>
    </>
  );
};

CustomMultipleSelectField.propTypes = {
  helperText: PropTypes.any,
  formik: PropTypes.any,
  name: PropTypes.string,
  width: PropTypes.string,
  items: PropTypes.any,
  value: PropTypes.any,
  passValue: PropTypes.func,
  placeholder: PropTypes.string,
  isClient: PropTypes.bool,
  height: PropTypes.string,
  fieldNames: PropTypes.string,
  fieldIds: PropTypes.string,
};

CustomMultipleSelectField.defaultProps = {};

export default CustomMultipleSelectField;
