import { createSlice } from "@reduxjs/toolkit";

const serviceCategorySlice = createSlice({
  name: "serviceCategory",
  initialState: {
    serviceCategory: null,
  },
  reducers: {
    setServiceCategory(state, action) {
      state.serviceCategory = action.payload;
    },
  },
});

export const { setServiceCategory } = serviceCategorySlice.actions;
export const getServiceCategory = (state) =>
  state?.serviceCategory?.serviceCategory;
export default serviceCategorySlice.reducer;
