import React from "react";
import PropTypes from "prop-types";
import CustomFieldLabelStyled from "./CustomFieldLabel.styled";

const CustomFieldLabel = (props) => {
  return (
    <CustomFieldLabelStyled
      isClient={props?.isClient}
      required={props?.required}
      disabled={props?.disabled}
    >
      {props.label}
    </CustomFieldLabelStyled>
  );
};

CustomFieldLabel.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  isClient: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomFieldLabel.defaultProps = {
  required: false,
};

export default CustomFieldLabel;
