import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Table as TableComponent } from "@mui/material";
import { ServicesTableContainerStyled } from "./ServicesTable.styled";
import TableFooterControls from "components/Table/TableFooterPagination";
import ListingItemsComponent from "components/ClientPanelForUsers/ServicesContent/ServicesTable/ListingServicesComponent/ListingServicesComponent";
import { useGetActiveClientServicesQuery } from "features/clients/clientsApiSlice";

const ServicesTable = (props) => {
  const startingNumberOfItemsPerPage = 8;
  const incrementNumberOfItemsPerPage = 4;
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(startingNumberOfItemsPerPage);
  const [totalRows, setTotalRows] = useState(0);

  const { data: paginatedData } = useGetActiveClientServicesQuery({
    id: props?.clientId,
    pageNumber: page,
    pageSize: rowsPerPage,
    filters: props.appliedFilters,
  });

  useEffect(() => {
    if (paginatedData) {
      setRows(paginatedData.data?.clientServices);
      setTotalRows(paginatedData.totalCount);
    }
  }, [paginatedData, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <ServicesTableContainerStyled>
      <TableComponent>
        <ListingItemsComponent
          items={rows}
          itemsPerRow={4}
          clientId={props?.clientId}
        />
        <TableFooterControls
          page={page}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={handlePageChange}
          t={t}
          startingNumberOfItemsPerPage={startingNumberOfItemsPerPage}
          incrementNumberOfItemsPerPage={incrementNumberOfItemsPerPage}
        />
      </TableComponent>
    </ServicesTableContainerStyled>
  );
};

ServicesTable.propTypes = {
  tableFor: PropTypes.any,
  theme: PropTypes.any,
  rowBtnClick: PropTypes.func,
  clientId: PropTypes.any,
  appliedFilters: PropTypes.object,
};

export default ServicesTable;
