import axios from "axios";
import { decodeToken } from "react-jwt";
import { attachBeforeRequestListener } from "../../request/index";

import { logOut, setCredentials } from "features/auth/authSlice";
import apiEndpoints from "features/apiEndpoints";

const baseURL = process.env.REACT_APP_BASE_API_URL;

let getRefreshToken = (jwtToken, refresh) =>
  axios.post(
    `${baseURL}/`[apiEndpoints.authentication.refreshToken],
    {
      refreshTokenValue: refresh,
    },
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

let refreshTokenPromise;

export const accessTokensMiddlewareInterceptorName = "ACCESS_TOKEN_INTERCEPTOR";

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    attachBeforeRequestListener((response) => {
      const jwtToken = getState().auth?.token?.accessToken;
      const refresh = getState().auth?.token?.refreshToken;
      const jwtTokenDecoded = decodeToken(jwtToken);
      if (!jwtToken || !refresh) return Promise.resolve(response);
      if (response?.params?.noAuth) {
        delete response?.params?.noAuth;
        return Promise.resolve(response);
      }
      if (new Date() <= new Date(jwtTokenDecoded.exp * 1000)) {
        response.headers.Authorization = `Bearer ${jwtToken}`;
      }
      if (new Date() > new Date(jwtTokenDecoded.exp * 1000)) {
        try {
          if (!refreshTokenPromise) {
            refreshTokenPromise = getRefreshToken(jwtToken, refresh.value).then(
              (token) => {
                refreshTokenPromise = null;
                return token;
              }
            );
          }

          return refreshTokenPromise
            .then((res) => {
              const { accessToken, refreshToken } = res.data.data;

              const decodedToken = decodeToken(accessToken);

              const user = {
                id: decodedToken.id,
                email: decodedToken.email,
                firstName: decodedToken.firstName,
                lastName: decodedToken.lastName,
                role: decodedToken.role,
                expireAt: decodedToken.exp,
                issuedAt: decodedToken.iat,
                issuer: decodedToken.iss,
                audience: decodedToken.aud,
              };

              dispatch(setCredentials({ accessToken, refreshToken, user }));

              response.headers.Authorization = `Bearer ${accessToken}`;
              refreshTokenPromise = null;
              return Promise.resolve(response);
            })
            .catch((e) => {
              refreshTokenPromise = null;
              dispatch(logOut());
              return Promise.reject(e);
            });
        } catch (e) {
          refreshTokenPromise = null;
          dispatch(logOut());
          return Promise.reject(e);
        }
      }
      return Promise.resolve(response);
    });
    next(action);
  };
