import {
  selectCurrentRoles,
  selectCurrentToken,
  selectCurrentUser,
} from "features/auth/authSlice";
import { useMemo } from "react";
import { isExpired } from "react-jwt";
import { useSelector } from "react-redux";
import { USER_ROLES } from "constants/userRoles";
import DATA_TYPES from "constants/dataTypes";

export const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  var rolesResp = useSelector(selectCurrentRoles);
  const roles =
    rolesResp == null
      ? []
      : typeof rolesResp === DATA_TYPES.STRING
      ? [rolesResp]
      : rolesResp;

  const isLoggedIn = useMemo(() => {
    return token && isExpired(token);
  }, [token]);

  const isUser = useMemo(() => {
    return roles?.includes(USER_ROLES.USER.name);
  }, [roles, isLoggedIn]);

  const isClient = useMemo(() => {
    return roles?.includes(USER_ROLES.CLIENT_ADMIN.name);
  }, [roles, isLoggedIn]);

  const isSuperAdmin = useMemo(() => {
    return roles?.includes(USER_ROLES.SUPER_ADMIN.name);
  }, [roles, isLoggedIn]);

  const hasRole = (userRoles) =>
    roles?.some((role) => userRoles?.includes(role));

  return {
    user: {
      id: user?.id,
      email: user?.email,
      role: user?.role,
      clientId: user?.clientId,
    },
    roles,
    hasRole,
    isLoggedIn,
    isUser,
    isClient,
    isSuperAdmin,
  };
};
