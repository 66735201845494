export const UserStepOneAtributes = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirm: "",
};

export const UserStepTwoAtributes = {
  country: "",
  city: "",
  address: "",
};

export const UserStepThreeAtributes = {
  sentEmailCode: "",
};