import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";
import Status from "components/Status/Status";

export default [
  {
    columnId: 0,
    headerName: "clientService.serviceName",
    field: "name",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "clientService.serviceDescription",
    field: "description",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 2,
    headerName: "clientService.serviceStatus",
    field: "isActive",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => {
      return (
        <Status
          isActive={params?.name}
          activeTextKey={"clientService.active"}
          inactiveTextKey={"clientService.notActive"}
        />
      );
    },
  },
  {
    columnId: 3,
    headerName: "clientService.servicePrice",
    field: "price",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 4,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer
        params={params}
        onClick={params.onClickEdit}
        onClickDelete={params.onClickDelete}
        value={"superAdmin.table.editButton"}
      />
    ),
  },
];
