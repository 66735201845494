import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  TableTitle,
} from "./TableHeadContainer.styled";
import { secondaryThemeColors } from "themes/secondaryTheme/secondaryThemeColors";

const TableHeader = (props) => {
  return (
    <StyledTableHead>
      <StyledTableRow>
        {props.tableHeaders.map((col) => (
          <StyledTableCell key={col.field}>
            <TableTitle color={secondaryThemeColors.leftAuthContentBackground}>
              {props.t(col.headerName)}
            </TableTitle>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
};

TableHeader.propTypes = {
  t: PropTypes.any,
  tableHeaders: PropTypes.any,
};

export default TableHeader;