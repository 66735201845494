import styled, { css } from "styled-components";
import { Typography } from "@mui/material";

const CustomFieldLabelStyled = styled(Typography)`
  padding-left: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: black;
  ${(props) =>
    props?.required &&
    css`
      &::after {
        content: "*";
      }
    `}
  color: ${(props) =>
    props.disabled
      ? props?.theme?.colors?.text3
      : props?.theme?.colors?.textBlack};
`;

export default CustomFieldLabelStyled;
