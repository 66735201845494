import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const appointmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableTimeSlots: builder.query({
      query: (data) => ({
        url: apiEndpoints.appointments.getAvailableTimeSlots(data),
      }),
    }),
    scheduleAppointment: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.appointments.addAppointment,
        method: "POST",
        body: data,
      }),
    }),
    getPaginatedAppointmentsForUser: builder.query({
      query: ({ id, pageNumber = 1, pageSize = 10 }) => ({
        url: apiEndpoints.appointments.getPaginatedForUser(
          id,
          pageNumber,
          pageSize
        ),
      }),
    }),
  }),
});

export const {
  useGetAvailableTimeSlotsQuery,
  useScheduleAppointmentMutation,
  useGetPaginatedAppointmentsForUserQuery,
} = appointmentApi;
