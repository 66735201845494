import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";
import Status from "components/Status/Status";
import WithAdminPermissions from "components/WithPermissions/WithAdminPermission/WithAdminPermissions";
const licenseTypeMap = {
  0: "Trial",
  1: "Full",
};
export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.licenses.lastChanged",
    field: "createdAtUtc",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value, row) => {
      const valueToRender = row?.updatedAtUtc ? row.updatedAtUtc : value;
      const date = new Date(valueToRender);
      return date.toLocaleDateString();
    },
  },
  {
    columnId: 1,
    headerName: "superAdmin.table.licenses.issuedAtUtc",
    field: "startDateUtc",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value) => {
      const date = new Date(value);
      return date.toLocaleDateString();
    },
  },
  {
    columnId: 3,
    headerName: "superAdmin.table.licenses.expiresAtUtc",
    field: "endDateUtc",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value) => {
      const date = new Date(value);
      return date.toLocaleDateString();
    },
  },
  {
    columnId: 2,
    headerName: "superAdmin.table.licenses.type",
    field: "type",
    enabledSort: false,
    enabledFilter: false,
    valueFormatter: (value) => licenseTypeMap[value] || "Unknown",
  },
  {
    columnId: 4,
    headerName: "superAdmin.table.licenses.status",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => {
      const currentDate = new Date();
      const startDate = new Date(params?.row?.startDateUtc);
      const endDate = new Date(params?.row?.endDateUtc);
      const isValid = currentDate > startDate && currentDate < endDate;

      return (
        <Status
          isActive={isValid}
          activeTextKey="superAdmin.table.licenses.statusValid"
          inactiveTextKey="superAdmin.table.licenses.statusInvalid"
        />
      );
    },
  },
  {
    columnId: 5,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <WithAdminPermissions>
        <UpdateRenderer
          params={params}
          onClick={params.onClickEdit}
          onClickDelete={params.onClickDelete}
          value={"superAdmin.table.editButton"}
        />
      </WithAdminPermissions>
    ),
  },
];
