import React from "react";
import PropTypes from "prop-types";
import ValidationMessage from "components/ValidationMessage/ValidationMessage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  setAttributesStepOne,
  setAttributesStepTwo,
} from "features/register/registerClientSlice";
import authType from "constants/authType";
import { ModalSize } from "components/Modals/ModalSize";

const FinishedRegistrationModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBtnClick = () => {
    if (props.authType == authType.CLIENT) {
      dispatch(setAttributesStepOne(null));
      dispatch(setAttributesStepTwo(null));
    }
    dispatch(unsetShownModal());
    navigate(PAGES.LOGIN.route);
  };
  return (
    <ValidationMessage
      title={t(`validationmessage.${props.authType}.title`)}
      message={t(`validationmessage.${props.authType}.text`)}
      type={props.authType}
      onClose={onBtnClick}
      size={ModalSize.LARGE}
    />
  );
};

FinishedRegistrationModal.propTypes = {
  authType: PropTypes.string,
};

export default FinishedRegistrationModal;
