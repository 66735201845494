import React from "react";
import PropTypes from "prop-types";
import CustomTextFieldStyled from "./CustomTextField.styled";
import { IconButton, InputAdornment } from "@mui/material";
import TYPE from "../../../constants/inputTypes";
import { useTranslation } from "react-i18next";

const CustomTextField = (props) => {
  const { t } = useTranslation();

  return (
    <CustomTextFieldStyled
      variant={props?.variant}
      type={props.type}
      name={props.name}
      placeholder={t(props.placeholder)}
      value={props?.value || props?.formik?.values?.[props?.name]}
      isClient={props?.isClient}
      disableUnderline
      onChange={props.onChange || props.formik?.handleChange}
      onBlur={props?.onBlur || props.formik?.handleBlur}
      error={
        props?.helperText ||
        (props.formik?.touched?.[props?.name] &&
          Boolean(props.formik?.errors?.[props?.name]))
      }
      helperText={
        t(props?.helperText) ||
        t(
          props.formik?.touched?.[props?.name] &&
            props.formik?.errors?.[props?.name]
        )
      }
      disabled={props.disabled}
      width={props.width}
      height={props.height}
      inputProps={{ min: props?.minNumValue }}
      InputProps={{
        disableUnderline: true,
        endAdornment: props.endIcon ? (
          <InputAdornment position="end">
            <IconButton
              onClick={props.endIconClick}
              onMouseDown={props.endIconMouseDown}
            >
              {props.endIcon}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    ></CustomTextFieldStyled>
  );
};

CustomTextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  onChange: PropTypes.func,
  endIconClick: PropTypes.func,
  endIconMouseDown: PropTypes.func,
  endIcon: PropTypes.any,
  helperText: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string,
  initialValue: PropTypes.string,
  isClient: PropTypes.bool,
  onBlur: PropTypes.any,
  disabled: PropTypes.bool,
  minNumValue: PropTypes.number,
};

CustomTextField.defaultProps = {
  type: TYPE.TEXT,
};

export default CustomTextField;
