import { PAGES } from "constants/pages";

export default [
  {
    key: "info",
    title: "superAdmin.table.clients.tabs.informations",
    route: PAGES.CLIENTS_DETAILS_INFO.route,
    default: true,
  },
  {
    key: "resources",
    title: "superAdmin.table.clients.tabs.resources",
    route: PAGES.CLIENTS_DETAILS_RESOURCES.route,
  },
  {
    key: "services",
    title: "superAdmin.table.clients.tabs.services",
    route: PAGES.CLIENTS_DETAILS_SERVICES.route,
  },
  {
    key: "calendar",
    title: "superAdmin.table.clients.tabs.calendar",
    route: PAGES.CLIENTS_DETAILS_CALENDAR.route,
  },
  {
    key: "licenses",
    title: "superAdmin.table.clients.tabs.license",
    route: PAGES.CLIENTS_DETAILS_LICENSES.route,
  },
];
