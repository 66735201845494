import styled from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { ReactComponent as QuestionMarkImg } from "assets/images/svg/question-mark.svg";

export const Modal = styled.div`
  width: 460px;  
  max-height: 308px;    
  box-sizing: border-box;
  overflow: hidden; 
`;

export const UpperContainer = styled.div`
  height: 227px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;        
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;     
  overflow: auto;  
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

export const LowerContainer = styled.div`
  gap: 10px;
  height: 81px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props.theme?.colors?.white};
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const CancelButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.errorColor};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.errorColorHover};
  }
  color: ${(props) => props.theme?.colors?.white};
`;

export const LeaveButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => props.theme?.colors?.black};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};
`;

const StyledImage = styled.img`
  height: 56px;
  width: 56px;
  display: block;
  margin: auto;
  margin-bottom: 10px;
`;

export const StyledQuestionMarkImg = styled(StyledImage).attrs({
  as: QuestionMarkImg,
})``;
