import { createSlice } from "@reduxjs/toolkit";

const clientLogoSlice = createSlice({
  name: "clientLogo",
  initialState: {
    logoData: null,
    isLoading: false,
  },
  reducers: {
    setLogoData: (state, action) => {
      state.logoData = action.payload;
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clearLogoData: (state) => {
      state.logoData = null;
    },
  },
});

export const { setLogoData, setLoading, clearLogoData } =
  clientLogoSlice.actions;

export default clientLogoSlice.reducer;

export const getLogo = (state) => state.clientLogo.logoData;
export const getIsLoading = (state) => state.clientLogo.isLoading;
