import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";
export const TopTabStyled = styled(Box)`
  width: 160px;
  height: 60px;
  border-radius: 16px 16px 0px 0px;
  padding: 16px 24px 16px 24px;
  gap: 10px;

  background-color: ${({ selected, theme }) =>
    selected
      ? theme.colors.updateTableButton
      : hexToRGB(theme.colors.updateTableButton, 0.08)};
  cursor: pointer;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ isselected }) => (isselected ? "white" : "black")};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;
