import React, { useState, useEffect } from "react";
import { Table as TableComponent } from "@mui/material";
import TableFooterControls from "./TableFooterPagination";
import {
  TableFooterWrapper,
  TableContainerStyled,
  TableWrapper,
} from "./Table.styled";
import TableBodyContainer from "./TableBodyContainer";
import TableHeader from "./TableHeadContainer";
import PropTypes from "prop-types";

const Table = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const { data: paginatedData, refetch } = props.usePaginatedQuery({
    pageNumber: page,
    pageSize: rowsPerPage,
    filters: props?.appliedFilters,
    id: props?.clientId || props?.userId,
    serviceCategoryId: props?.serviceCategoryId,
    clientId: props?.clientId,
  });
  useEffect(() => {
    if (paginatedData) {
      setRows(props.mapRowDataFunction(paginatedData));
      setTotalRows(paginatedData.totalCount);
      refetch();
      if (paginatedData.totalCount != 0) {
        props.setIsFilterButtonShown?.(true);
      } else {
        props?.setIsFilterButtonShown?.(false);
      }
    }
  }, [paginatedData, page, rowsPerPage]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return rows.length != 0 ? (
    <TableContainerStyled isClient={props?.isClient}>
      <TableWrapper>
        <TableComponent>
          <TableHeader
            t={props.t}
            tableAlignmentLeft={props.tableAlignmentLeft}
            tableHeaders={props.tableHeaders}
          />
          <TableBodyContainer
            rows={rows}
            tableHeaders={props.tableHeaders}
            rowBtnClick={props.rowBtnClick}
            deleteBtnClick={props?.deleteBtnClick}
            tableAlignmentLeft={props.tableAlignmentLeft}
            isSuperAdmin={props?.isSuperAdmin}
            numberOfCharactersPerCell={props?.numberOfCharactersPerCell}
          />
        </TableComponent>
      </TableWrapper>
      <TableFooterWrapper>
        <TableFooterControls
          page={page}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={handlePageChange}
          t={props.t}
        />
      </TableFooterWrapper>
    </TableContainerStyled>
  ) : (
    props?.handleEmptyTable?.()
  );
};

Table.propTypes = {
  tableHeaders: PropTypes.any,
  usePaginatedQuery: PropTypes.func,
  fetchFunction: PropTypes.func,
  t: PropTypes.any,
  tableFor: PropTypes.any,
  mapRowDataFunction: PropTypes.func,
  theme: PropTypes.any,
  rowBtnClick: PropTypes.func,
  deleteBtnClick: PropTypes.func,
  appliedFilters: PropTypes.object,
  isClient: PropTypes.bool,
  clientId: PropTypes.any,
  userId: PropTypes.any,
  serviceCategoryId: PropTypes.any,
  isSuperAdmin: PropTypes.bool,
  tableAlignmentLeft: PropTypes.bool,
  numberOfCharactersPerCell: PropTypes.number,
  handleEmptyTable: PropTypes.func,
  setIsFilterButtonShown: PropTypes.func,
};

Table.defaultProps = {
  mapRowDataFunction: (d) => d,
  tableAlignmentLeft: false,
};

export default Table;
