import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const serviceCategoriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllServiceCategories: builder.query({
      query: () => ({
        url: apiEndpoints.serviceCategories.getAll,
      }),
      providesTags: ["Table"],
    }),
    getPaginatedServiceCategories: builder.query({
      query: ({ pageNumber = 1, pageSize = 10 }) => ({
        url: apiEndpoints.serviceCategories.getPaginated
          .replace("{pageNumber}", pageNumber)
          .replace("{pageSize}", pageSize),
      }),
      providesTags: ["Table"],
    }),
    addServiceCategory: builder.mutation({
      query: (formData) => ({
        url: apiEndpoints.serviceCategories.create,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Table"],
    }),
    updateServiceCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: apiEndpoints.serviceCategories.update.replace("{id}", id),
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Table"],
    }),
    getClientsForServiceCategory: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, filters = {} }) => ({
        url: apiEndpoints.serviceCategories.getClientsForServiceCategory(
          pageNumber,
          pageSize,
          filters
        ),
      }),
    }),
    getServiceCategoryImage: builder.query({
      query: (id) => ({
        url: apiEndpoints.serviceCategories.getImage.replace("{id}", id),
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
    }),
  }),
});

export const {
  useGetServiceCategoryImageQuery,
  useGetAllServiceCategoriesQuery,
  useGetPaginatedServiceCategoriesQuery,
  useAddServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
  useGetClientsForServiceCategoryQuery,
} = serviceCategoriesApi;
