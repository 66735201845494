import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGetServiceCategoryImageQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import {
  CategoryCardContainer,
  ImageIcon,
  TextContainer,
  StyledCardText,
  LineContainer,
} from "./CategoryCard.styled";
import fallbackIcon from "assets/images/svg/sidebar-collapsed-logo.svg";

const CategoryCard = (props) => {
  const { t } = useTranslation();
  const { data: fetchedImage, isLoading } = useGetServiceCategoryImageQuery(
    props.imageId
  );

  const [imageUrl, setImageUrl] = useState(fallbackIcon);

  useEffect(() => {
    if (typeof fetchedImage === "string") {
      setImageUrl(fetchedImage);
    } else if (fetchedImage instanceof Blob || fetchedImage instanceof File) {
      const objectUrl = URL.createObjectURL(fetchedImage);
      setImageUrl(objectUrl);
  
      return () => objectUrl && URL.revokeObjectURL(objectUrl);
    } else {
      setImageUrl(fallbackIcon);
    }
  }, [fetchedImage]);  

  if (isLoading) return <p>{t("image.loading")}</p>;

  return (
    <CategoryCardContainer onClick={props.onClick} index={props.index}>
      <ImageIcon src={imageUrl} alt={props.name} />
      <TextContainer>
        <StyledCardText index={props.index}>{props.name}</StyledCardText>
        <LineContainer index={props.index} />
      </TextContainer>
    </CategoryCardContainer>
  );
};

CategoryCard.propTypes = {
  imageId: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  name: PropTypes.string,
};

export default CategoryCard;
