import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomFields/CustomTextField";
import CustomFieldLabel from "./CustomFields/CustomFieldLabel";
import { ReactComponent as EyeOn } from "../../assets/images/svg/eye-on.svg";
import { ReactComponent as EyeOff } from "../../assets/images/svg/eye-off.svg";
import { FormikParams } from "constants/formikParams";
import TYPE from "../../constants/inputTypes";
import { Box } from "@mui/material";

const PasswordField = (props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () =>
    setShowPassword((oldValue) => !oldValue);
  const handleMouseDownPassword = () =>
    setShowPassword((oldValue) => !oldValue);
  const handleChange = (event) => {
    if (props?.clearErrorMessage) props?.clearErrorMessage();
    props?.formik?.handleChange(event);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)} required={props.required} />
      <CustomTextField
        type={showPassword ? "text" : "password"}
        name={props.name}
        placeholder={t(props.placeholder)}
        formik={props.formik}
        width={props.width}
        height={props.height}
        showEndIcon={showPassword}
        endIconClick={handleClickShowPassword}
        endIconMouseDown={handleMouseDownPassword}
        endIcon={showPassword ? <EyeOn /> : <EyeOff />}
        onChange={handleChange}
        helperText={props?.helperText}
      />
    </Box>
  );
};

PasswordField.propTypes = {
  label: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.bool,
  formik: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
};

PasswordField.defaultProps = {
  label: "common.labelPassword",
  type: TYPE.PASSWORD,
  name: FormikParams.password,
  placeholder: "common.passwordInput",
};

export default PasswordField;
