import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import CustomFieldLabel from "./CustomFieldLabel";
import { Container, CustomTimePicker } from "./TimeInputField.styled";

const TimeInputField = (props) => {
  const { t } = useTranslation();
  const [time, setTime] = useState(null);

  const handleTimeChange = (newTime) => {
    if (newTime && newTime.isValid()) {
      const formattedTime = newTime.toDate().toISOString();
      setTime(newTime);
      if (props.onChange) {
        props.onChange(formattedTime);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <CustomFieldLabel label={t(props.label)} />
        <CustomTimePicker
          value={time}
          onChange={handleTimeChange}
          renderInput={(params) => (
            <TextField 
              {...params} 
              fullWidth 
              error={Boolean(props.error)}
              helperText={props.helperText}
            />
          )}
          ampm={false}
        />
      </Container>
    </LocalizationProvider>
  );
};

TimeInputField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func
};

export default TimeInputField;