import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Title,
  ActionsContainer,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
  ContentContainer,
} from "./AddEditResourceModal.styled";
import {
  InputFieldContainer,
  InputFieldsContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { VARIANT } from "constants/buttonConstants";
import { useFormik } from "formik";
import { makeToastMessage } from "util/toastMessage";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import { clientResourceFormikParams } from "initialValues/entityFormikParams/clientResourceFormikParams";
import { addEditResourceValidation } from "validations/clientResourceValidation";
import NameField from "../InputFields/NameField";
import EmailField from "components/InputFields/EmailField";
import toastNotificationType from "constants/toastNotificationType";
import {
  ActiveCircle,
  StatusButtonContainer,
  StatusContainer,
  StatusText,
} from "components/SuperAdmin/UserPageContent/UserPageContent.styled";
import { ReactComponent as CheckImg } from "assets/images/svg/status-active-check.svg";
import { ReactComponent as XImg } from "assets/images/svg/status-inactive-x.svg";
import {
  CloseIcon,
  TitleContainer,
} from "components/SuperAdmin/ServiceCategoryEditAddModal/ServiceCategoryEditAddModal.styled";
import {
  useAddClientResourceMutation,
  useGetClientImageQuery,
  useUpdateClientResourceMutation,
} from "features/clients/clientsApiSlice";
import ResourceTypeField from "components/InputFields/CustomFields/ResourceTypeField";
import ImageUpload from "components/InputFields/CustomFields/ImageUpload";

const AddEditResourceModal = (props) => {
  const { t } = useTranslation();
  const [addResource] = useAddClientResourceMutation();
  const [updateResource] = useUpdateClientResourceMutation();
  const { data: imageUrl, refetch: fetchImage, isLoading} = useGetClientImageQuery(
    props.resource?.imageId,
    { skip: !props.resource?.imageId }
  );
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageSelect = (image) => {
    setUploadedImage(image);
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('Name', formik.values.name);
    formData.append('Email', formik.values.email);
    formData.append('IsActive', isActive);
    formData.append('Type', formik.values.resourceType);

    if (uploadedImage && uploadedImage.file) {
      formData.append('Image', uploadedImage.file);
    } 
    else if (uploadedImage && uploadedImage.url) {
      formData.append('ImageUrl', uploadedImage.url);
    } 
    else {
      formData.append('Image', null);
    }

    let response = null;
    if (props.resource) {
      formData.append('ResourceId', props.resource?.id);
      response = await updateResource({
        clientId: props.clientId,
        resourceId: props?.resource?.id,
        formData,
      });
    } else {
      response = await addResource({
        clientId: props.clientId,
        formData,
      });
    }
  
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        props.resource
          ? t("superAdmin.table.resources.successfullyUpdated")
          : t("superAdmin.table.resources.successfullyAdded"),
        toastNotificationType.SUCCESS
      );
      dispatch(unsetShownModal());
    }
  };

  const editFlag = useMemo(() => props.resource != null, [props.resource]);
  const formik = useFormik({
    initialValues: clientResourceFormikParams(props.resource),
    validationSchema: addEditResourceValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (editFlag) {
      setIsActive(props.resource?.isActive);
  
      if (props.resource?.imageId && !uploadedImage) {
        fetchImage();
      }
  
      if (imageUrl && !uploadedImage) {
        setUploadedImage({
          file: null,
          url: imageUrl,
        });
      }
    }
  }, [editFlag, isLoading]);

  return (
    <Modal>
      <UpperContainer>
        <TitleContainer>
          <Title>
            {editFlag
              ? t("superAdmin.table.resources.editResource")
              : t("superAdmin.table.resources.addResource")}
          </Title>
          <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        </TitleContainer>
        <ContentContainer>
          <StatusContainer onClick={() => setIsActive((oldValue) => !oldValue)}>
            <StatusText active={isActive}>
              {t("superAdmin.table.resources.status")}
            </StatusText>
            <StatusButtonContainer active={isActive}>
              <ActiveCircle>{isActive ? <CheckImg /> : <XImg />}</ActiveCircle>
            </StatusButtonContainer>
          </StatusContainer>
          <InputFieldsContainer>
            <InputFieldContainer>
              <NameField formik={formik} />
            </InputFieldContainer>
            <InputFieldContainer>
              <ResourceTypeField required formik={formik} name="resourceType" />
            </InputFieldContainer>
            <InputFieldContainer>
              <EmailField formik={formik} />
            </InputFieldContainer>
            <ImageUpload
              uploadedImage={uploadedImage}
              onImageSelect={handleImageSelect}
              onDeleteImage={handleDeleteImage}
            />
          </InputFieldsContainer>
        </ContentContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t(`common.cancel`)}
          />
          <ActionButton
            onClick={formik.handleSubmit}
            value={editFlag ? t(`common.save`) : t(`common.add`)}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

AddEditResourceModal.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  item: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  selectedItem: PropTypes.object,
  resource: PropTypes.object,
  clientId: PropTypes.any,
};

AddEditResourceModal.defaultProps = {};

export default AddEditResourceModal;
