import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ClientLogoStyled,
  ClientLogoWrapper,
  LogoContainer,
} from "components/ClientEditTab/ClientContentTab/TabContent/Information/LeftInfoContent/ClientLogo.styled";
import {
  BasicInfo,
  BasicInfoContainer,
  ClientDescription,
  ClientInfoContainer,
  ClientName,
  ClientFullName,
  SegmentWithBottomBorderWrapper,
  ClientPrimaryService,
  ClientSecondaryServices,
  SegmentWrapper,
  ClientWebAddress,
  ClientSecondaryServicesWrapper,
  SegmentTitle,
} from "./ClientInfoContent.styled";
import { useTranslation } from "react-i18next";
import ListingWorkingHourComponent from "./ListingWorkingHourComponent/ListingWorkingHourComponent";
import { useGetClientLogoQuery } from "features/images/imagesApiSlice";
import defaultLogo from "assets/images/svg/default-ClientLogo.png";

const ClientInfoContent = (props) => {
  const { t } = useTranslation();
  const { data, isError } = useGetClientLogoQuery({
    id: props?.client?.logoId,
  });
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (data && data.imageUrl && !isError) {
      setImageUrl(data.imageUrl);
    } else {
      setImageUrl(defaultLogo);
    }
    return () => {
      if (imageUrl && imageUrl !== defaultLogo) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [data]);

  return (
    <ClientInfoContainer>
      <ClientLogoWrapper>
        <LogoContainer>
          <ClientLogoStyled src={imageUrl} alt="Client Logo" />
        </LogoContainer>
        <ClientName>{props?.client?.fullName}</ClientName>
        <BasicInfoContainer>
          <BasicInfo>{`${props?.client?.address}, ${props?.client?.city}, ${props?.client?.country}`}</BasicInfo>
          <BasicInfo>{props?.client?.phoneNumbers[0]?.phoneNumber}</BasicInfo>
        </BasicInfoContainer>
      </ClientLogoWrapper>
      <SegmentWrapper>
        <ClientDescription>{props?.client?.description}</ClientDescription>
      </SegmentWrapper>
      <SegmentWrapper>
        <SegmentTitle>
          {t("clientPanel.infoSegment.title.workingHours")}
        </SegmentTitle>
        <ListingWorkingHourComponent
          items={props?.client?.workingDaysHours}
          itemsPerRow={2}
        />
      </SegmentWrapper>
      <SegmentWithBottomBorderWrapper>
        <SegmentTitle>
          {t("clientPanel.infoSegment.title.fullName")}
        </SegmentTitle>
        <ClientFullName>{props?.client?.fullName}</ClientFullName>
      </SegmentWithBottomBorderWrapper>
      <SegmentWithBottomBorderWrapper>
        <SegmentTitle>
          {t("clientPanel.infoSegment.title.primaryService")}
        </SegmentTitle>
        <ClientPrimaryService>
          {props?.client?.primaryServiceCategory?.name}
        </ClientPrimaryService>
      </SegmentWithBottomBorderWrapper>
      <SegmentWithBottomBorderWrapper>
        <SegmentTitle>
          {t("clientPanel.infoSegment.title.additionalServices")}
        </SegmentTitle>
        <ClientSecondaryServicesWrapper>
          {props?.client?.secondaryServiceCategories?.map?.((sc) => {
            return (
              <ClientSecondaryServices key={sc.id}>
                {sc.name}
              </ClientSecondaryServices>
            );
          })}
        </ClientSecondaryServicesWrapper>
      </SegmentWithBottomBorderWrapper>
      <SegmentWrapper>
        <SegmentTitle>
          {t("clientPanel.infoSegment.title.webAddress")}
        </SegmentTitle>
        <ClientWebAddress>{props?.client?.webAddress}</ClientWebAddress>
      </SegmentWrapper>
    </ClientInfoContainer>
  );
};

ClientInfoContent.propTypes = {
  client: PropTypes.any,
};

export default ClientInfoContent;
