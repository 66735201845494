import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { ContactIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const ContactPage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<ContactIconSelected />} />
    </MainLayout>
  );
};

export default ContactPage;
