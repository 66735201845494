import React from "react";
import { LeftContentWrapper } from "./LeftInfoContent.styled";
import ClientLogo from "./ClientLogo";
import PropTypes from "prop-types";
import { ClientForm } from "./ClientForm";
export const LeftInfoContent = (props) => {
  return (
    <LeftContentWrapper>
      <ClientLogo
        clientName={props?.client?.fullName}
        logoId={props?.client?.logoId}
        clientId={props?.client?.id}
      />
      <ClientForm client={props?.client} />
    </LeftContentWrapper>
  );
};

LeftInfoContent.propTypes = {
  client: PropTypes.any,
};
