import React from "react";
import PropTypes from "prop-types";
import {
  ArrwoDownStyled,
  FullNameTypography,
  HeaderProfileContainer,
  RoleTypography,
  StyledPopover,
  TypographyContainer,
  AvatarStyled,
} from "./HeaderProfile.styled";
import HeaderProfilePopoverContent from "./HeaderProfilePopoverContent/HeaderProfilePopoverContent";
import { selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import { USER_ROLES } from "constants/userRoles";

const HeaderProfile = (props) => {
  const user = useSelector(selectCurrentUser);
  const initials =
    user?.firstName && user?.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
      : null;
  const userRole = Array.isArray(user.role)
    ? user.role
        .map((roleName) =>
          Object.values(USER_ROLES).find((role) => role.name === roleName)
        )
        .filter((role) => role)
        .sort((a, b) => b.priority - a.priority)[0]?.name
    : user.role;
  return (
    <HeaderProfileContainer>
      <AvatarStyled>{initials}</AvatarStyled>
      {props.isLoggedIn && (
        <TypographyContainer>
          <FullNameTypography>
            {user.firstName + " " + user.lastName}
          </FullNameTypography>
          {userRole === USER_ROLES.SUPER_ADMIN.name && (
            <RoleTypography>{userRole}</RoleTypography>
          )}
        </TypographyContainer>
      )}
      <StyledPopover
        trigger={<ArrwoDownStyled />}
        content={<HeaderProfilePopoverContent isLoggedIn={props.isLoggedIn} />}
      />
    </HeaderProfileContainer>
  );
};

HeaderProfile.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
};

export default HeaderProfile;
