import React from "react";
import PropTypes from "prop-types";
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import RegisterLoginOptions from "./RegisterLoginOptions/RegisterLoginOptions";

const HeaderProfileBar = (props) => {
  return props?.isLoggedIn ? (
    <HeaderProfile isLoggedIn={props.isLoggedIn} />
  ) : (
    <RegisterLoginOptions></RegisterLoginOptions>
  );
};

HeaderProfileBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};

export default HeaderProfileBar;
