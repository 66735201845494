import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  ArrowButton,
  GalleryContentContainer,
  ImagesContainer,
  LeftArrowContainer,
  LeftArrowStyled,
  RightArrowContainer,
  RightArrowStyled,
} from "./GalleryContent.styled";
import { useGetClientGalleryQuery } from "features/clients/clientsApiSlice";
import { ImageContainer } from "components/ImageGallery/ImageGallery.styled";
import ImageComponent from "components/ImageGallery/Image";
import { getAdjacentElements } from "util/helpers/arrayHelpers";

const GalleryContent = (props) => {
  const { data, isLoading } = useGetClientGalleryQuery(props?.client?.id);
  const galleryImages = isLoading ? [] : data.data.imageResponses;
  const [counter, setCounter] = useState(0);
  const numberOfShownImages = 3;
  const [imagesToShow, setImagesToShow] = useState([]);
  useEffect(() => {
    setImagesToShow(
      getAdjacentElements(galleryImages, counter, numberOfShownImages)
    );
  }, [galleryImages, counter]);

  return (
    <>
      <GalleryContentContainer>
        {galleryImages.length >= 3 && (
          <LeftArrowContainer>
            <ArrowButton
              onClick={() =>
                setCounter(
                  (oldValue) =>
                    (oldValue - 1 + galleryImages.length) % galleryImages.length
                )
              }
            >
              <LeftArrowStyled />
            </ArrowButton>
          </LeftArrowContainer>
        )}
        <ImagesContainer>
          {imagesToShow.map((image, index) => (
            <ImageContainer key={index}>
              <ImageComponent imageId={image?.id} />
            </ImageContainer>
          ))}
        </ImagesContainer>
        {galleryImages.length >= 3 && (
          <RightArrowContainer>
            <ArrowButton
              onClick={() =>
                setCounter((oldValue) => (oldValue + 1) % galleryImages.length)
              }
            >
              <RightArrowStyled />
            </ArrowButton>
          </RightArrowContainer>
        )}
      </GalleryContentContainer>
    </>
  );
};

GalleryContent.propTypes = {
  client: PropTypes.any,
};

export default GalleryContent;
