import React from "react";
import ClientDetailsRenderer from "components/Table/ClientDetailsRenderer";

export default [
  {
    columnId: 0,
    headerName: "common.clients",
    field: "client",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 1,
    headerName: "Osnovna delatnost",
    field: "primaryServiceCategory.name",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 2,
    headerName: "common.labelLocation",
    field: "location",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 3,
    headerName: "common.status",
    field: "status",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 4,
    headerName: "",
    field: "details",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (props) => <ClientDetailsRenderer {...props} />,
  },
];
