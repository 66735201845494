export const clientFormikParams = {
  registrationName: "registrationName",
  phoneNumber: "phoneNumber",
  description: "description",
  city: "city",
  country: "country",
  webAddress: "webAddress",
  address: "address",
  primaryServiceCategoryName: "primaryServiceCategoryName",
  primaryServiceCategoryId: "primaryServiceCategoryId",
  secondaryServiceCategoryIds: "secondaryServiceCategoryIds",
  secondaryServiceCategoryNames: "secondaryServiceCategoryNames",
  workingDaysHours: "workingDaysHours",
  appointmentStartTimeInMinutes: "appointmentStartTimeInMinutes",
  publicAppointmentEnabled: "publicAppointmentEnabled",
};
