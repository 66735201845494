import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";
import { getAppointmentEnabled, getAppointmentStartTime, getDescription, getWorkingDaysHours } from "features/clients/clientSlice";
import { useSelector } from "react-redux";

export const clientParams = (client) => {
  const changedDescription = useSelector(getDescription);
  const workingDaysHours = useSelector(getWorkingDaysHours);
  const appointmentEnabled = useSelector(getAppointmentEnabled);
  const appointmentStartTime = useSelector(getAppointmentStartTime);
  return {
    [clientFormikParams.registrationName]: client?.registrationName ?? "",
    [clientFormikParams.description]: changedDescription ?? "",
    [clientFormikParams.country]: client?.country ?? "",
    [clientFormikParams.city]: client?.city ?? "",
    [clientFormikParams.address]: client?.address ?? "",
    [clientFormikParams.webAddress]: client?.webAddress ?? "",
    [clientFormikParams.phoneNumber]: client?.phoneNumbers[0]?.phoneNumber ?? "",
    [clientFormikParams.primaryServiceCategoryName]:
      client?.primaryServiceCategory?.name ?? "",
    [clientFormikParams.primaryServiceCategoryId]:
      client?.primaryServiceCategory?.id ?? "",
    [clientFormikParams.secondaryServiceCategoryIds]:
      client?.secondaryServiceCategories?.map((category) => category.id) || [],
    [clientFormikParams.secondaryServiceCategoryNames]:
      client?.secondaryServiceCategories?.map((category) => category.name) ||
      [],
    [clientFormikParams.publicAppointmentEnabled]: appointmentEnabled ?? "",
    [clientFormikParams.appointmentStartTimeInMinutes]: appointmentStartTime ?? "",
    [clientFormikParams.workingDaysHours]: workingDaysHours ?? [],
  };
};

export const FormikParams = {
  registrationName: "",
  description: "",
  country: "",
  city: "",
  address: "",
  phoneNumber: "",
  primaryServiceCategoryName: "",
  primaryServiceCategoryId: "",
  secondaryServiceCategoryId: "",
  publicAppointmentEnabled: "",
  workingDaysHours: [],
  appointmentStartTimeInMinutes: "",
};
