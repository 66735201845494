export const serviceCategoryParams = (serviceCategory) => {
  return {
    name: serviceCategory?.name ?? "",
    description: serviceCategory?.description ?? "",
    image: serviceCategory?.image ?? null,
  };
};

export const FormikParams = {
  name: "",
  description: "",
  image: null,
};
