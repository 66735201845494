import React, { useEffect, useState } from "react";
import {
  DeleteButton,
  GalleryTitle,
  HiddenInput,
  ImageContainer,
  ImageGalleryContainer,
  ImageGalleryGrid,
  UploadBox,
  UploadContent,
} from "./ImageGallery.styled";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as TrashIcon } from "../../assets/images/svg/trash-icon.svg";
import { TYPE } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";
import { AccordionStyled } from "components/ScheduleEditor/ScheduleEditor.styled";
import {
  useDeleteClientImageMutation,
  useGetClientGalleryQuery,
  useAddClientImageMutation,
} from "features/clients/clientsApiSlice";
import PropTypes from "prop-types";
import ImageComponent from "./Image";

const ImageGallery = (props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [images, setImages] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const {
    data: galleryImages,
    isSuccess,
    refetch,
  } = useGetClientGalleryQuery(props.clientId);

  useEffect(() => {
    if (isSuccess && galleryImages?.data?.imageResponses?.length) {
      setImages(galleryImages.data.imageResponses);
    } else {
      setImages([]);
    }
  }, [isSuccess, galleryImages]);

  useEffect(() => {
    refetch();
  }, [props.clientId, refetch]);

  const [addImage] = useAddClientImageMutation();

  const handleImageUpload = async (event) => {
    event.preventDefault();
    const files = event.target.files || event.dataTransfer.files;

    const newImages = Array.from(files).map((file) => {
      const objectURL = URL.createObjectURL(file);
      return { file, objectURL };
    });

    for (const { file } of newImages) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("clientId", props.clientId);
      formData.append("isGalleryImage", true);

      const result = await addImage(formData);

      if (result.error) {
        console.error("Error adding image:", result.error);
      } else {
        refetch();
      }
    }
  };

  const [deleteImage] = useDeleteClientImageMutation();
  const handleImageDelete = async (id) => {
    await deleteImage(id);
    setImages((prevImages) => prevImages.filter((image) => image.id !== id));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    handleImageUpload(event);
  };

  const handleAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <AccordionStyled expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <GalleryTitle>{t("imageGallery.images")}</GalleryTitle>
      </AccordionSummary>
      <AccordionDetails>
        <ImageGalleryContainer>
          <ImageGalleryGrid>
            <UploadBox
              isDragOver={isDragOver}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <HiddenInput type={TYPE.FILE} onChange={handleImageUpload} />
              <UploadContent>
                <span>+</span>
                <p>{t("imageGallery.addImage")}</p>
              </UploadContent>
            </UploadBox>
            {images.map((image) => (
              <ImageContainer key={image.id}>
                <ImageComponent imageId={image.id} />
                <DeleteButton onClick={() => handleImageDelete(image.id)}>
                  <TrashIcon />
                </DeleteButton>
              </ImageContainer>
            ))}
          </ImageGalleryGrid>
        </ImageGalleryContainer>
      </AccordionDetails>
    </AccordionStyled>
  );
};

ImageGallery.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default ImageGallery;
