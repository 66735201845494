import { Table, TableContainer, Typography } from "@mui/material";
import styled from "styled-components";

export const TableContainerStyled = styled(TableContainer)`
  display: flex;
  flex-direction: column;
  width: calc(100%-15px);
  margin-left: 15px;
  margin-right: 15px;
`;

export const TableWrapper = styled.div`
  flex-grow: 1;
`;

export const TableFooterWrapper = styled.div`
  flex-shrink: 0;
`;

export const TableComponentStyled = styled(Table)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DateText = styled(Typography)`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.leftAuthContentBackground};
  align-items: center;
  padding-left: 24px;
`;
