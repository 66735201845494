import { Box } from "@mui/material";
import styled from "styled-components";

export const TabContainer = styled(Box)`
  display: flex;
  gap: 10px;
  border-bottom: 2px solid ${(props) => props?.theme?.colors?.updateTableButton};
`;

export const Controls = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding-right: 16px;
`;
