import Button from "components/Button/Button";
import styled from "styled-components";

export const GoogleButtonContainer = styled(Button)`
  max-width: 308px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: ${(props) => props?.theme?.colors?.googleButton};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.googleButtonHover};
  }
`;
