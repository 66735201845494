import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { isInRoute } from "util/routeHelpers";
import {
  NavLinkContainer,
  StyledTypography,
  TabWrapper,
} from "./SidebarNavigationItem.styled";

export const SidebarNavigationItem = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [displayHidden, setDisplayHidden] = useState(true);

  const isInThisRoute = isInRoute(props?.path, location?.pathname);

  useEffect(() => {
    const handler = () => {
      if (displayHidden !== props?.collapsed)
        setDisplayHidden(props?.collapsed);
    };
    let timeout = setTimeout(() => {
      handler();
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [props?.collapsed]);

  return (
    <NavLinkContainer
      to={props?.path}
      style={{ textDecoration: "none" }}
      collapsed={props?.collapsed}
    >
      <TabWrapper selected={isInThisRoute} collapsed={props?.collapsed}>
        {isInThisRoute ? props?.iconSelected : props?.icon}
        <StyledTypography displayHidden={displayHidden}>
          {t(props?.title)}
        </StyledTypography>
      </TabWrapper>
    </NavLinkContainer>
  );
};

SidebarNavigationItem.propTypes = {
  selected: PropTypes.bool,
  collapsed: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  iconSelected: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  title: PropTypes.string,
};

export default SidebarNavigationItem;
