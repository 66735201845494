import { Typography } from "@mui/material";
import styled from "styled-components";

const LongDashStyled = styled(Typography)`
  background-color: black;
  width: 100%;
  height: 1px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins" !important;
`;

export default LongDashStyled;
