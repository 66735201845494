import React from "react";
import PropTypes from "prop-types";
import { ResourcesContentStyled } from "./ResourcesContent.styled";
import ResourceSelectField from "./ResourceSelectField";

const ResourcesContent = (props) => {
  return (
    <ResourcesContentStyled>
      {props?.requiredResourceTypes?.map((requiredResourceType, index) =>
        requiredResourceType.autoAssign ? null : (
          <ResourceSelectField
            key={index}
            index={index}
            name={requiredResourceType.id}
            label={requiredResourceType.resourceTypeName}
            onChange={props?.onSelectResource}
            items={requiredResourceType.possibleResources?.map(
              (possibleResource) => {
                return {
                  id: possibleResource.resource.id,
                  name: possibleResource.resource.name,
                };
              }
            )}
            selectedFirst
          ></ResourceSelectField>
        )
      )}
    </ResourcesContentStyled>
  );
};

ResourcesContent.propTypes = {
  requiredResourceTypes: PropTypes.any,
  onSelectResource: PropTypes.func,
};

export default ResourcesContent;
