import React, { useState } from "react";
import PropTypes from "prop-types";
import XIcon from "../../assets/images/svg/x-icon.svg";
import { TimeSlotWrapper } from "./TimeSlot.styled";

const TimeSlot = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleRemove = () => {
    setIsVisible(false);
    props.onRemove();
  };

  if (!isVisible) return null;

  return (
    <TimeSlotWrapper>
      {props?.label}
      {props?.hours}
      {props.deletable && (
        <img src={XIcon} alt="Remove" onClick={handleRemove} />
      )}
    </TimeSlotWrapper>
  );
};

TimeSlot.propTypes = {
  hours: PropTypes.string,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  deletable: PropTypes.bool,
};

TimeSlot.defaultProps = {
  deletable: true,
};

export default TimeSlot;
