import Button from "components/Button/Button";
import styled from "styled-components";

export const NoAppointmentsMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 20px;
`;

export const MessageContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 27.28px;
`;

export const ScheduleAppointmentButton = styled(Button)`
  border-radius: 12px;
  padding: 6px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: white;
  background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  }
  width: fit-content;
`;
