import { SingleForm } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm";
import React from "react";
import PropTypes from "prop-types";
import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";

export const ClientWebAdressField = (props) => {
  return (
    <SingleForm formik={props?.formik} name={clientFormikParams.webAddress} />
  );
};

ClientWebAdressField.propTypes = {
  formik: PropTypes.any,
};
