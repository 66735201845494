import React from "react";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { HelpIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const HelpPage = () => {
  return (
    <MainLayout>
      <PageHeader leftIcon={<HelpIconSelected />} />
    </MainLayout>
  );
};

export default HelpPage;
