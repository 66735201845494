import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Title,
  ActionsContainer,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
  CloseIcon,
  TitleContainer,
  ImageErrorContainer,
} from "./ServiceCategoryEditAddModal.styled";
import {
  InputFieldContainer,
  InputFieldsContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { VARIANT } from "constants/buttonConstants";
import NameField from "./InputFields/NameField";
import DescriptionField from "./InputFields/DescriptionField";
import { useFormik } from "formik";
import { serviceCategoryParams } from "initialValues/entityFormikParams/serviceCategoryFormikParams";
import { addEditSCvalidation } from "validations/serviceCategory/serviceCategoryValidation";
import {
  useAddServiceCategoryMutation,
  useGetServiceCategoryImageQuery,
  useUpdateServiceCategoryMutation,
} from "features/serviceCategory/serviceCategoryApiSlice";
import { NameAlreadyInUse } from "constants/registrationBEerrors";
import { makeToastMessage } from "util/toastMessage";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import toastNotificationType from "constants/toastNotificationType";
import ImageUpload from "components/InputFields/CustomFields/ImageUpload";

const ServiceCategoryEditAddModal = ({ serviceCategory }) => {
  const { t } = useTranslation();
  const [addServiceCategory] = useAddServiceCategoryMutation();
  const [updateServiceCategory] = useUpdateServiceCategoryMutation();
  const [nameNotUniqueError, setNameNotUniqueError] = useState(null);
  const dispatch = useDispatch();
  const [uploadedImage, setUploadedImage] = useState(null);
  const { data: fetchedImage, isLoading } = useGetServiceCategoryImageQuery(
    serviceCategory?.imageId
  );

  const handleImageSelect = (image) => {
    setUploadedImage(image);
    formik.setFieldValue("image", image);
  };

  useEffect(() => {
    if (serviceCategory?.imageId) {
      setUploadedImage({ url: fetchedImage });
      formik.setFieldValue("image", {});
    }
  }, [serviceCategory, isLoading]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("Name", formik.values.name);
    formData.append("Code", "code");
    formData.append("Description", formik.values.description);
    if (formik.values.image != {}) {
      formData.append("Image", uploadedImage.file);
    } else {
      formData.append("Image", null);
    }

    let response = null;
    if (serviceCategory) {
      response = await updateServiceCategory({
        id: serviceCategory?.id,
        formData,
      });
    } else {
      response = await addServiceCategory(formData);
    }

    if (response?.error) {
      response?.error?.data?.errors.map((error) => {
        switch (error.code) {
          case NameAlreadyInUse:
            setNameNotUniqueError(error.message);
            break;
          default:
            makeToastMessage(error.message, toastNotificationType.ERROR);
            break;
        }
      });
    } else {
      makeToastMessage(
        serviceCategory
          ? t("superAdmin.table.serviceCategories.successfullyUpdated")
          : t("superAdmin.table.serviceCategories.successfullyAdded"),
        toastNotificationType.SUCCESS
      );
      dispatch(unsetShownModal());
    }
  };

  const editFlag = useMemo(() => serviceCategory != null, [serviceCategory]);
  const formik = useFormik({
    initialValues: serviceCategoryParams(serviceCategory),
    validationSchema: addEditSCvalidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const clearNameErrorMessage = () => {
    if (nameNotUniqueError != null) {
      setNameNotUniqueError(null);
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    formik.setFieldValue("image", null);
  };

  return (
    <Modal>
      <UpperContainer>
        <TitleContainer>
          <Title>
            {editFlag
              ? t("serviceCategory.editServiceCategory")
              : t("serviceCategory.addNewServiceCategory")}
          </Title>
          <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        </TitleContainer>
        <InputFieldsContainer>
          <InputFieldContainer>
            <NameField
              formik={formik}
              helperText={nameNotUniqueError}
              clearErrorMessage={clearNameErrorMessage}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <DescriptionField formik={formik} />
          </InputFieldContainer>
          <InputFieldContainer>
            <ImageUpload
              onDeleteImage={handleDeleteImage}
              uploadedImage={uploadedImage}
              onImageSelect={handleImageSelect}
            />
            {formik.touched?.image && Boolean(formik.errors?.image) && (
              <ImageErrorContainer>
                {t(formik.touched?.image && formik.errors?.image)}
              </ImageErrorContainer>
            )}
          </InputFieldContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t(`common.quit`)}
          />
          <ActionButton
            onClick={formik.handleSubmit}
            value={editFlag ? t(`common.save`) : t(`common.add`)}
          />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

ServiceCategoryEditAddModal.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  item: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  selectedItem: PropTypes.object,
  serviceCategory: PropTypes.object,
};

ServiceCategoryEditAddModal.defaultProps = {};

export default ServiceCategoryEditAddModal;
