import React from "react";
import {
  ClientSwitchStyled,
  ThumbFalse,
  ThumbTrue,
} from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm.styled";
import PropTypes from "prop-types";

export const ClientSwitch = (props) => {
  return (
    <ClientSwitchStyled
      disabled={props?.disabled}
      checked={props?.checked}
      onChange={props.onChange}
      checkedIcon={<ThumbTrue />}
      icon={<ThumbFalse />}
    />
  );
};

ClientSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.any,
};
