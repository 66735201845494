import Button from "components/Button/Button";
import styled from "styled-components";
import { ReactComponent as PlusIconSvg } from "assets/images/svg/plus-icon.svg";

export const PlusButtonContainer = styled(Button)`
  border-radius: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center; 
  justify-content: center;
  margin: 0px;
  padding: 0px;
  min-width: 24px;
  min-height: 24px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
  & .MuiButton-startIcon {
    margin: 0;
  }
`;

export const PlusIcon = styled(PlusIconSvg)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.black};
    fill: ${(props) => props?.theme?.colors?.black};
  }
  display: block;
  width: 24px;
  height: 24px;
`;