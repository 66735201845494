import styled from "styled-components";
import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material";

export const StyledTableBody = styled(TableBody)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  overflow: auto;
  width: 100%;
  min-height: 40px;
`;

export const StyledTableRow = styled(TableRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 66px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;

export const NoRowsBody = styled(TableRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;

export const StyledTableCell = styled(TableCell)`
  padding-left: 15px;
  width: 15%;
  flex-wrap: wrap;
  border: none;
  display: flex;
  align-items: center;
  & .MuiTypography-root {
    display: flex;
    flex-direction: row;
  }
`;

export const LastCell = styled(StyledTableCell)`
  display: flex;
  justify-content: right;
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledTooltip = styled(Tooltip)`
  & .tooltipClasses.tooltip {
    color: red;
    background-color: #fff;
  }
`;
