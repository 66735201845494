import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectModal } from "features/modal/modalSlice";
import ServiceCategoryEditAddModal from "components/SuperAdmin/ServiceCategoryEditAddModal/ServiceCategoryEditAddModal";
import SentCodeModal from "./SentCodeModal/SentCodeModal";
import FinishedRegistrationModal from "components/RegistrationContent/FinishedRegistrationModal/FinishedRegistrationModal";
import ChangeUserStatusModal from "components/SuperAdmin/ChangeUserStatusModal/ChangeUserStatusModal";
import LicenseEditAddModal from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal";
import { LicenseDeleteModal } from "components/SuperAdmin/LicenseDeleteModal/LicenseDeleteModal";
import DeleteResourceModal from "components/ClientEditTab/ClientContentTab/TabContent/Resource/DeleteResourceModal/DeleteResourceModal";
import AddEditResourceModal from "components/ClientEditTab/ClientContentTab/TabContent/Resource/AddEditResourceModal/AddEditResourceModal";
import SetWorkingHoursModal from "components/ScheduleEditor/SetWorkingHoursModal/SetWorkingHoursModal";
import ClientServiceAttributeModal from "./ClientServiceModals/ClientServiceAttributeModal";
import ClientServiceResourceGroupModal from "./ClientServiceModals/ClientServiceResourceGroupModal";
import { ClientServiceDeleteModal } from "./ClientServiceModals/ClientServiceDeleteModal";
import UnsavedAlertModal from "components/UnsavedAlertModal/UnsavedAlertModal";
import { Overlay } from "./Modal.styled";

const Modal = () => {
  const modal = useSelector(selectModal);
  const isAnyModalOpen = useMemo(() => {
    return Object.values(modal).some((value) => value === true);
  }, [modal]);

  return (
    <>
      <Overlay isVisible={isAnyModalOpen}>
        {modal.serviceCategoryModal && (
          <ServiceCategoryEditAddModal {...modal.serviceCategoryModalProps} />
        )}

        {modal.finishedRegistrationModal && (
          <FinishedRegistrationModal
            {...modal.finishedRegistrationModalProps}
          />
        )}
        {modal.sentCodeModal && <SentCodeModal {...modal.sentCodeModalProps} />}
        {modal.changeUserStatusModal && (
          <ChangeUserStatusModal {...modal.changeUserStatusModalProps} />
        )}
        {modal.clientServiceAttributeModal && (
          <ClientServiceAttributeModal
            {...modal.clientServiceAttributeModalProps}
          />
        )}
        {modal.clientServiceResourceModal && (
          <ClientServiceResourceGroupModal
            {...modal.clientServiceResourceModalProps}
          />
        )}
        {modal.deleteResourceModal && (
          <DeleteResourceModal {...modal.deleteResourceModalProps} />
        )}
        {modal.addEditResourceModal && (
          <AddEditResourceModal {...modal.addEditResourceModalProps} />
        )}
        {modal.workingTimeModal && (
          <SetWorkingHoursModal {...modal.workingTimeModalProps} />
        )}
        {modal.clientServiceDeleteModal && (
          <ClientServiceDeleteModal {...modal.clientServiceDeleteModalProps} />
        )}
        {modal.changeLicenceModal && (
          <LicenseEditAddModal {...modal.changeLicenceModalProps} />
        )}
        {modal.deleteLicenceModal && (
          <LicenseDeleteModal {...modal.deleteLicenceModalProps} />
        )}
      {modal.unsavedAlertModal && (
        <UnsavedAlertModal {...modal.unsavedAlertModalProps} />
      )}
      </Overlay>
    </>
  );
};

export default Modal;

