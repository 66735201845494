import { getRequest, postRequest } from "./index";
import apiEndpoints from "features/apiEndpoints";

export const getUsernames = (emailorusername) =>
  getRequest(apiEndpoints.authentications.getUsernames, {
    emailorusername,
  });

export const attemptLogin = (payload) =>
  postRequest(apiEndpoints.authentications.login, payload);

export const updateSecurityAnswer = (payload) =>
  postRequest(apiEndpoints.authentications.confirmSecurityQuestion, payload);

export const refreshTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.refreshToken, payload);

export const logoutUserRequest = () =>
  postRequest(apiEndpoints.authentication.logout);

export const generateTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.generateToken, payload);
