import { Box } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const ClientInformationStyled = styled(Box)`
  width: 100%;
  position: relative;
  display: flex;
  gap: 40px;
  padding: 16px;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  background-color: ${(props) =>
    props?.theme?.colors?.selectCountryPhoneCodeBackground};
`;
