import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForgotPasswordMutation } from "features/forgotPassword/forgotPasswordSlice";
import { PAGES } from "constants/pages";
import { useFormik } from "formik";
import forgotPasswordValidation from "validations/forgotPasswordValidation";
import {
  BackButtonStyled,
  ButtonWrapper,
  ForgotPasswordContainer,
  ForgotPasswordTitle,
  InputFieldContainer,
  LogoStyled,
  LogTitleContainerStyled,
  LowerContainer,
  MessageAndButtonContainer,
  SentEmailMessage,
  StyledButton,
  TitleAndInputContainer,
} from "./ForgotPassword.styled";
import { makeToastMessage } from "util/toastMessage";
import MainLayout from "layouts/MainLayout";
import { ModalSize } from "components/Modals/ModalSize";
import { StyledLink } from "components/Sidebar/Sidebar.styled";
import EmailField from "components/InputFields/EmailField";
const ForgotPassword = () => {
  const { t } = useTranslation();
  const [useForgotPassword] = useForgotPasswordMutation();
  const [sentEmail, setSentEmail] = useState(false);
  const handleSubmit = async () => {
    const response = await useForgotPassword({
      email: formik.values.email,
      redirectionUrl:
        process.env.REACT_APP_BASE_URL + PAGES.RESET_PASSWORD.route,
    });
    if (response.error) {
      makeToastMessage(response.error.data.errors[0].message, "error");
    } else {
      makeToastMessage(t("login.forgotPasswordEmailSent"), "success");
      setSentEmail(true);
    }
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: forgotPasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <MainLayout isAuthLayout={true}>
      <ForgotPasswordContainer size={ModalSize.SMALL} sentEmail={sentEmail}>
        <LogTitleContainerStyled>
          <StyledLink to={PAGES.BASE.route}>
            <LogoStyled />
          </StyledLink>
        </LogTitleContainerStyled>
        {!sentEmail ? (
          <LowerContainer>
            <TitleAndInputContainer>
              <ForgotPasswordTitle variant="h5">
                {t("forgotPassword.resetPasswordEmailText")}
              </ForgotPasswordTitle>
              <InputFieldContainer>
                <EmailField formik={formik} required height={"50px"} />
              </InputFieldContainer>
            </TitleAndInputContainer>
            <ButtonWrapper>
              <StyledButton onClick={formik.handleSubmit}>
                {t("forgotPassword.forgotPassword.label")}
              </StyledButton>
            </ButtonWrapper>
          </LowerContainer>
        ) : (
          <MessageAndButtonContainer>
            <SentEmailMessage>
              {t("forgotPassword.sentEmailMessage")}
            </SentEmailMessage>
            <BackButtonStyled onClick={() => setSentEmail(false)} />
          </MessageAndButtonContainer>
        )}
      </ForgotPasswordContainer>
    </MainLayout>
  );
};

export default ForgotPassword;
