import styled from "styled-components";
import { ReactComponent as ServiceIcon } from "assets/images/svg/default-service-image.svg";
import { ReactComponent as ClockIcon } from "assets/images/svg/clock-icon.svg";
import { ReactComponent as WalletIcon } from "assets/images/svg/wallet-icon.svg";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  height: 593px;
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 285px;
  width: 233px;
  border-radius: 16px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  gap: 15px;
`;

export const ServiceIconStyled = styled(ServiceIcon)`
  height: 100%;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const ServiceNameText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textBlack};
`;

export const ServiceDurationAndPriceText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.darkBlue};
`;

export const DurationAndPriceContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const DurationContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const ClockIconStyled = styled(ClockIcon)`
  height: 16px;
  width: 16px;
  align-self: center;
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const WalletIconStyled = styled(WalletIcon)`
  height: 16px;
  width: 16px;
  align-self: center;
`;

export const ScheduleButton = styled(Button)`
  width: 64px;
  height: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 8px;
  background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  color: ${(props) => props?.theme?.colors?.white};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  }
`;
