import React from "react";
import PropTypes from "prop-types";
import {
  ActionButton,
  ActionsContainer,
  CancelActionButton,
  Title,
  UpperContainer,
  Modal,
  LowerContainer,
  TitleContainer,
  CloseIcon,
  InputFieldContainer
} from "./SetWorkingHoursModal.styled";
import { useFormik } from "formik";
import { useValidationSchema } from "validations/workingHoursValidation";
import { useTranslation } from "react-i18next";
import timeInitalValue from "initialValues/timeInitalValue";
import TimeInputField from "components/InputFields/CustomFields/TimeInputField";
import { unsetShownModal } from "features/modal/modalSlice";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const SetWorkingHoursModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const { startTime, endTime } = formik.values;
    const newWorkingHour = {
      day: props.day,
      startTime,  
      endTime,   
    };

    props.onAddWorkingHour(newWorkingHour);
    dispatch(unsetShownModal());
  };


  const onBtnClose = () => {
    dispatch(unsetShownModal());
  };

  const formik = useFormik({
    initialValues: timeInitalValue,
    validationSchema: useValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  return (
      <Modal>
        <UpperContainer>
          <TitleContainer>
            <Title>{t("workingHours.workingHoursTitle")}</Title>
            <CloseIcon onClick={onBtnClose} />
          </TitleContainer>
          <InputFieldContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeInputField
                label={t("workingHours.from")}
                value={formik.values.startTime}
                onChange={(newTime) => formik.setFieldValue("startTime", newTime)}
                error={ formik.touched.startTime && Boolean(formik.errors.startTime)}
                helperText={formik.errors.startTime}
              />
            </LocalizationProvider>
          </InputFieldContainer>
          <InputFieldContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeInputField
                label={t("workingHours.to")}
                value={formik.values.endTime}
                onChange={(newTime) => formik.setFieldValue("endTime", newTime)}
                error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                helperText={formik.errors.endTime}
              />
            </LocalizationProvider>
          </InputFieldContainer>
        </UpperContainer>
        <LowerContainer>
          <ActionsContainer>
            <CancelActionButton onClick={onBtnClose} value={t("common.quit")} />
            <ActionButton
              onClick={formik.handleSubmit}
              value={t("common.save")}
            />
          </ActionsContainer>
        </LowerContainer>
      </Modal>
  );
};

SetWorkingHoursModal.propTypes = {
  day: PropTypes.number.isRequired,
  onAddWorkingHour: PropTypes.func.isRequired,
};

export default SetWorkingHoursModal;