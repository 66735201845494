import styled from "styled-components";

export const ClientServiceTableSectionContainer = styled.div`
  border-radius: 24px;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
`;

export const ClientServiceTableSectionStyled = styled.div`
  padding: 16px;
  border-radius: 24px;
  background-color: ${(props) => props?.theme?.colors?.white};
  max-height: fit-content;
  overflow-y: auto;
  box-shadow: 2px 2px 8px ${(props) => props?.theme?.colors?.shadowColor};
`;
export const ClientServiceTableSectionWithoutHeaderStyled = styled.div`
  padding: 0px 15px 15px 15px;
`;
