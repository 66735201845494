export const mapWorkingHours = (workingDaysHours) => {
  const workingHoursMap = new Map();

  workingDaysHours?.forEach((entry) => {
    const dayOfWeek = entry.dayOfWeek;
    const today = new Date();
    const todayDateString = today.toISOString().split("T")[0];

    const fromTimeParts = entry.fromTimeUtc.split("T");
    const toTimeParts = entry.toTimeUtc.split("T");
    const fromMilliseconds = fromTimeParts[1].split(".")[1] || "0";
    const toMilliseconds = toTimeParts[1].split(".")[1] || "0";

    const formattedFromMilliseconds =
      fromMilliseconds.length < 3
        ? fromMilliseconds.padEnd(3, "0")
        : fromMilliseconds.slice(0, 3);
    const formattedToMilliseconds =
      toMilliseconds.length < 3
        ? toMilliseconds.padEnd(3, "0")
        : toMilliseconds.slice(0, 3);

    const fromTimeWithZ =
      todayDateString +
      "T" +
      fromTimeParts[1].split(".")[0] +
      "." +
      formattedFromMilliseconds +
      "Z";
    const toTimeWithZ =
      todayDateString +
      "T" +
      toTimeParts[1].split(".")[0] +
      "." +
      formattedToMilliseconds +
      "Z";

    const fromDate = new Date(fromTimeWithZ);
    const toDate = new Date(toTimeWithZ);

    const fromHours = fromDate.getHours();
    const fromMinutes = fromDate.getMinutes();
    const toHours = toDate.getHours();
    const toMinutes = toDate.getMinutes();

    const workingHour = {
      fromTimeUtc: fromTimeWithZ,
      toTimeUtc: toTimeWithZ,
      range: `${fromHours}:${
        fromMinutes < 10 ? "0" : ""
      }${fromMinutes} - ${toHours}:${toMinutes < 10 ? "0" : ""}${toMinutes}`,
    };

    if (!workingHoursMap.has(dayOfWeek)) {
      workingHoursMap.set(dayOfWeek, []);
    }
    workingHoursMap.get(dayOfWeek).push(workingHour);
  });

  return Array.from({ length: 7 }, (_, i) => i + 1).map((day) => {
    const hours = workingHoursMap.get(day);
    return {
      day: day,
      hours: hours || [],
    };
  });
};
