import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import { SingleFormStyled } from "components/ClientEditTab/ClientContentTab/TabContent/SingleForm/SingleForm.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomMultipleSelectFieldStyled from "components/InputFields/CustomFields/CustomMultipleSelectField";

export const SecondaryServices = (props) => {
  const handleChange = (changedValue) => {
    const currentValues =
      props?.formik?.values.secondaryServiceCategoryIds || [];

    const newValues = [...currentValues, changedValue.primaryId];

    props?.formik?.setFieldValue("secondaryServiceCategoryIds", newValues);
  };

  const { t } = useTranslation();
  const { data, isLoading } = useGetAllServiceCategoriesQuery();

  return (
    <SingleFormStyled>
      <CustomFieldLabel
        isRequired={false}
        label={t("superAdmin.table.clients.details.secondaryServiceCategories")}
        isClient={true}
      />

      <CustomMultipleSelectFieldStyled
        fieldNames={"secondaryServiceCategoryNames"}
        fieldIds="secondaryServiceCategoryIds"
        formik={props?.formik}
        isClient={true}
        items={
          isLoading
            ? []
            : data?.data?.serviceCategories?.map((x) => ({
                name: x?.name,
                id: x?.name,
                primaryId: x?.id,
              }))
        }
        passValue={handleChange}
      />
    </SingleFormStyled>
  );
};

SecondaryServices.propTypes = {
  formik: PropTypes.any,
};
