import styled from "styled-components";
import { ReactComponent as QuestionMarkImg } from "assets/images/svg/question-mark.svg";
import { hexToRGB } from "util/colorHelper";
import Button from "components/Button/Button";

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  width: 460px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Divider = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 250px;
  gap: 20px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;
  align-self: center;
`;

export const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
  color: ${({ theme }) => theme.colors.textBlack};
  padding: 6px 24px 6px 24px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => hexToRGB(theme?.colors?.black, 0.3)};
  cursor: pointer;
  height: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 50%;
  align-self: center;
`;

export const ActionButton = styled(CloseButton)`
  background-color: ${({ theme }) => theme.colors.statusInactiveButton};
  &:hover {
    background-color: ${({ theme }) => theme.colors.statusInactiveButton};
  }
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledImage = styled.img`
  width: 170px;
  height: 121px;
  display: block;
  margin: 0 auto;
`;

export const StyledQuestionMarkImg = styled(StyledImage).attrs({
  as: QuestionMarkImg,
})`
  height: 55px;
  width: 55px;
  top: 7px;
  left: 7px;
  margin-top: 15px;
`;
