import { apiSlice } from "features/api/apiSlice";

export const lookupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCities: builder.query({
      query: () => "/lookup/cities",
    }),
    getAllCountries: builder.query({
      query: () => `/lookup/countries`,
    }),
  }),
});

export const { useGetAllCitiesQuery, useGetAllCountriesQuery } = lookupApi;
