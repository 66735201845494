import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const registerClientSlice = createSlice({
  name: "client",
  initialState: {
    attributesStepOne: {
      registrationName: "",
      fullName: "",
      country: "",
      city: "",
      address: "",
      phoneNumber: "",
      serviceCategoryId: "",
    },
    attributesStepTwo: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    id: "",
  },
  reducers: {
    setClientId: (state, action) => {
      state.id = action.payload;
    },
    setAttributesStepOne: (state, action) => {
      state.attributesStepOne = action.payload;
    },
    setAttributesStepTwo: (state, action) => {
      state.attributesStepTwo = action.payload;
    },
  },
});

export const { setClientId, setAttributesStepOne, setAttributesStepTwo } =
  registerClientSlice.actions;
export default registerClientSlice.reducer;

export const clientSelector = (state) => state.client;

export const selectClientAttributesStepOne = createSelector(
  clientSelector,
  (state) => state.attributesStepOne
);

export const selectClientAttributesStepTwo = createSelector(
  clientSelector,
  (state) => state.attributesStepTwo
);

export const selectClient = createSelector(clientSelector, (state) => state);

export const selectClientId = createSelector(
  clientSelector,
  (state) => state.id
);
