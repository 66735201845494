import styled from "styled-components";
import { Chip, FormHelperText, Select } from "@mui/material";
import { ReactComponent as DownArrow } from "../../../assets/images/svg/down-arrow.svg";
import { ReactComponent as ChipDeleteIcon } from "assets/images/svg/chip-delete-icon.svg";

export const CustomSelectFieldStyled = styled(Select)`
  padding-left: ${({ isClient }) => (isClient ? "0px" : "20px")};
  ${({ isClient, value, theme }) =>
    isClient
      ? `color: ${theme?.colors?.black};`
      : value
      ? `color: ${theme?.colors?.black};`
      : `color: ${theme?.colors?.placeholderText};`}

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }

  &.MuiOutlinedInput-root {
    min-height: ${(props) => (props?.height ? props?.height : "")};

    & fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient ? theme?.colors?.text3 : theme?.colors?.text3};
      border-width: 1px;
      min-height: ${(props) => (props?.height ? props?.height : "")};
    }

    &:hover fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient
          ? theme?.colors?.clientRegistration.Circle
          : theme?.colors?.secondaryColor};
    }

    &.Mui-focused fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient
          ? theme?.colors?.secondaryColor
          : theme?.colors?.secondaryColor};
      border-width: 2px;
    }
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
  }
  width: ${(props) => (props?.width ? props?.width : "100%")};
`;

export const ChipDelete = styled(ChipDeleteIcon)``;

export const ChipStyled = styled(Chip)`
  &.MuiChip-colorDefault {
    background-color: ${({ theme }) => theme?.colors?.serviceChipColor};
    color: ${({ theme }) => theme?.colors?.serviceChipTextColor};
  }

  font-size: 14px;
`;

export const CustomFormHelperText = styled(FormHelperText)`
  color: ${(props) => props?.theme?.colors?.errorRed};
  font-size: 12px;
  margin-left: 14px;
  margin-top: 0px;
`;

export const DownArrowStyled = styled(DownArrow)`
  margin-top: 5px;
  margin-right: 20px;
`;

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      maxWidth: 100,
    },
  },
};

export default CustomSelectFieldStyled;
