import React from "react";
import MiddlePageContent from "components/MiddlePage/MiddlePageContent";
import MainLayout from "layouts/MainLayout";

const MiddlePage = () => {
  return (
    <MainLayout isAuthLayout={true}>
      <MiddlePageContent />
    </MainLayout>
  );
};

export default MiddlePage;
