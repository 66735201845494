import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { serviceCategoryFormikParams } from "constants/entityFormikParams/serviceCategoryFormikParams";
import { Box } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";

const DescriptionField = (props) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    props?.clearErrorMessage?.();
    props?.formik?.handleChange(event);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props.label)}></CustomFieldLabel>
      <CustomTextField
        placeholder={t(props.placeholder)}
        type={props.type}
        name={props.name}
        formik={props.formik}
        width={props.width}
        onChange={handleChange}
        helperText={props?.helperText}
      ></CustomTextField>
    </Box>
  );
};

DescriptionField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.any,
  width: PropTypes.string,
  helperText: PropTypes.string,
  clearErrorMessage: PropTypes.func,
};

DescriptionField.defaultProps = {
  label: "serviceCategory.inputField.label.description",
  name: serviceCategoryFormikParams.description,
  placeholder: "serviceCategory.inputField.placeholder.description",
};

export default DescriptionField;
