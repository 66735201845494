import { useEffect } from "react";

const usePrompt = (shouldPrompt, message) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldPrompt) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldPrompt, message]);
};

export default usePrompt;