import { CheckBox } from "components/CheckBox/CheckBox";
import React from "react";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";
export const appointmentsTableConstants = [
  {
    field: "startTime",
    label: "Start Time",
    valueFormatter: (data) => convertUTCToLocalTime(data),
  },
  {
    field: "endTime",
    label: "End Time",
    valueFormatter: (data) => convertUTCToLocalTime(data),
  },
  {
    field: "serviceName",
    label: "Service Name",
    valueFormatter: (data) => data,
  },
  {
    field: "attributes",
    label: "Atribut Usluga",
    valueFormatter: (attributes) => {
      if (attributes && typeof attributes === "object") {
        return Object.values(attributes)
          .map((attributeObj) => attributeObj.name)
          .join(", ");
      }
      return "";
    },
  },
  {
    field: "resources",
    label: "Resource",
    valueFormatter: (resources) => {
      if (resources && typeof resources === "object") {
        return Object.values(resources)
          .map((resourceObj) => resourceObj.resource.name)
          .join(", ");
      }
      return "";
    },
  },
  {
    field: "checkmark",
    cellRenderer: (props) => <CheckBox {...props} />,
  },
];
