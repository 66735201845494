import React from "react";
import PropTypes from "prop-types";
import WithPermissions from "../WithPermissions";
import { USER_ROLES } from "constants/userRoles";

const WithAdminPermissions = (props) => {
  return (
    <WithPermissions roles={[USER_ROLES.SUPER_ADMIN.name]}>
      {props.children}
    </WithPermissions>
  );
};

WithAdminPermissions.propTypes = {
  children: PropTypes.node,
};

export default WithAdminPermissions;
