import React, { useEffect, useState } from "react";
import MainLayout from "layouts/MainLayout";
import PropTypes from "prop-types";
import ClientServiceContent from "components/ClientServiceContent/ClientServiceContent";
import { useDispatch } from "react-redux";
import {
  setClientService,
  setClientServiceWithClient,
} from "features/clientService/clientServiceSlice";
import {
  useGetClientByIdQuery,
  useGetClientServiceQuery,
} from "features/clients/clientsApiSlice";
import { useParams } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";
import { ClientsIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";

const ClientServicePage = () => {
  const dispatch = useDispatch();

  const { clientId, clientServiceId } = useParams();

  const isEditPage =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      clientServiceId
    );

  const [serviceId, setServiceId] = useState(clientServiceId ?? null);
  const { data: clientData, isLoading: isLoadingClient } =
    useGetClientByIdQuery(clientId);

  useEffect(() => {
    setClientService(clientServiceId);
  }, []);

  const { data, isLoading } = useGetClientServiceQuery({
    clientId: clientId,
    clientServiceId: isEditPage ? serviceId : null,
  });
  useEffect(() => {
    const clientService = isLoading ? null : data?.data?.clientService;
    if (!isLoading && isEditPage && clientService) {
      dispatch(
        setClientServiceWithClient({
          client: { ...clientData?.data?.client },
          clientService: { ...clientService },
        })
      );
    } else if (!isLoadingClient && !isEditPage && clientData) {
      const clientServiceEmpty = {
        name: "",
        description: "",
        durationInMinutes: null,
        price: null,
        isActive: false,
        attributes: [],
        client: { ...clientData?.data?.client },
        requiredResourceTypes: [],
      };
      dispatch(setClientService({ ...clientServiceEmpty }));
    }
  }, [clientData, isLoading, clientData]);

  return (
    <MainLayout>
      <PageHeader leftIcon={<ClientsIconSelected />} />
      <ClientServiceContent
        isEditPage={isEditPage}
        createdIdReturn={(id) => setServiceId(id)}
      />
    </MainLayout>
  );
};

ClientServicePage.propTypes = {
  theme: PropTypes.any,
};

export default ClientServicePage;
