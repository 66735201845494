import React, { useState } from "react";
import { StatusButtonContainer, StatusContainer } from "./SwitchButton.styled";
import { ActiveCircle } from "components/SuperAdmin/UserPageContent/UserPageContent.styled";
import PropTypes from "prop-types";

const SwitchButton = (props) => {
  const [isActiveFlag, setIsActiveFlag] = useState(false);
  const onClick = () => {
    setIsActiveFlag((oldValue) => !oldValue);
    if (isActiveFlag) {
      props.onDeselect();
    } else {
      props.onSelect();
    }
  };
  return (
    <StatusContainer>
      <StatusButtonContainer
        active={isActiveFlag}
        activeColor={props.activeColor}
        inactiveColor={props.inactiveColor}
        onClick={onClick}
      >
        <ActiveCircle>
          {isActiveFlag ? props.activeImg : props.inactiveImg}
        </ActiveCircle>
      </StatusButtonContainer>
    </StatusContainer>
  );
};

SwitchButton.propTypes = {
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  activeImg: PropTypes.node,
  inactiveImg: PropTypes.node,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
};

export default SwitchButton;
