import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";
import { ReactComponent as DetailsIcon } from "assets/images/svg/details-icon.svg";

const ClientDetailsRenderer = (props) => {
  return (
    <ActionButton
      startIcon={<DetailsIcon />}
      value={props?.isSuperAdmin ? "common.details" : "common.selectBtn"}
      onClick={props.onClickEdit}
    />
  );
};
ClientDetailsRenderer.propTypes = {
  onClickEdit: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
};

export default ClientDetailsRenderer;
