import React, { useEffect, useState } from "react";
import { ClientFormWrapper } from "./LeftInfoContent.styled";
import { PhoneField } from "components/ClientEditComponents/ClientPhoneField";
import { CountryField } from "components/ClientEditComponents/ClientCountryField";
import PrimaryServiceField from "components/ClientEditComponents/PrimaryServiceField";
import { ClientWebAdressField } from "components/ClientEditComponents/ClientWebAdressField";
import { SecondaryServices } from "components/ClientEditComponents/SecondaryServices";
import { ClientCityField } from "components/ClientEditComponents/ClientCityField";
import { ClientAdressField } from "components/ClientEditComponents/ClientAdressField";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useUpdateClientMutation } from "features/clients/clientsApiSlice";
import { clientParams } from "initialValues/entityFormikParams/clientFormikParams";
import { RegistrationNameField } from "components/ClientEditComponents/ClientRegistrationNameField";
import { useDispatch, useSelector } from "react-redux";
import {
  isSubmitting,
  resetFlag,
  resetSubmitFlag,
  toggleFlag,
} from "features/clients/clientSlice";
import { ClientValidationSchema } from "validations/clientInfoValidation";
import { useLazyCheckClientRegistrationNameQuery } from "features/register/registerApiSlice";
import { useTranslation } from "react-i18next";
import { makeToastMessage } from "util/toastMessage";

export const ClientForm = (props) => {
  const [updateClient] = useUpdateClientMutation();
  const [triggerCheckClientRegistrationName] =
    useLazyCheckClientRegistrationNameQuery();
  const dispatch = useDispatch();
  const submitFlag = useSelector(isSubmitting);
  const [regNameNotUniqueError, setRegNameNotUniqueError] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const data = {
      registrationName: formik.values.registrationName,
      fullName: props?.client?.fullName,
      country: formik.values.country,
      city: formik.values.city.name ?? formik.values.city,
      address: formik.values.address,
      webAddress: formik.values.webAddress,
      description: formik.values.description,
      appointmentStartTimeInMinutes: formik.values.appointmentStartTimeInMinutes,
      primaryServiceCategoryName: formik.values.primaryServiceCategoryName,
      primaryServiceCategoryId: formik.values.primaryServiceCategoryId,
      secondaryServiceCategoryIds: formik.values.secondaryServiceCategoryIds,
      publicAppointmentEnabled: formik.values.publicAppointmentEnabled,
      employeeIds: [],
      phoneNumbers: [formik.values.phoneNumber],
      workingDaysHours: formik.values.workingDaysHours.length
        ? formik.values.workingDaysHours
        : [],
    };

    let response = null;
    response = await updateClient({
      id: props?.client?.id,
      clientData: { ...data },
    });

    if (response?.error) {
      makeToastMessage(
        t("superAdmin.table.clients.unsuccessfullyUpdatedClient")
      );
      makeToastMessage(
        t("superAdmin.table.clients.unsuccessfullyUpadtedClient"),
        "error"
      );
    } else {
      makeToastMessage(
        t("superAdmin.table.clients.successfullyUpdatedClient"),
        "success"
      );
    }
  };

  const formik = useFormik({
    initialValues: clientParams(props?.client),
    validationSchema: ClientValidationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    
    if (Object.keys(formik.errors).length > 0) {
      dispatch(resetFlag());
    } else if (formik.dirty) {
      dispatch(toggleFlag());
    }
  }, [formik.errors, formik.dirty, dispatch]);

  useEffect(() => {
    if (submitFlag) {
      handleSubmit();
      dispatch(resetSubmitFlag());
      dispatch(resetFlag());
    }
  }, [submitFlag]);

  const handleBlurUsername = async () => {
    const result = await triggerCheckClientRegistrationName({
      name: formik.values.registrationName,
      clientId: props?.client?.id,
    });

    if (result.data.data.isNameUnique === false) {
      setRegNameNotUniqueError(t("register.usernameAlreadyTaken"));
      return;
    } else {
      setRegNameNotUniqueError(null);
    }
  };

  return (
    <ClientFormWrapper>
      <RegistrationNameField
        formik={formik}
        helperText={regNameNotUniqueError}
        onBlur={handleBlurUsername}
        clearErrorMessage={() => setRegNameNotUniqueError(null)}
      />

      <PrimaryServiceField formik={formik} />
      <SecondaryServices formik={formik} />
      <PhoneField formik={formik} />
      <ClientWebAdressField formik={formik} />
      <CountryField formik={formik} />
      <ClientCityField formik={formik} />
      <ClientAdressField lastInput={true} formik={formik} />
    </ClientFormWrapper>
  );
};

ClientForm.propTypes = {
  client: PropTypes.any,
};
