import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { useGetClientByIdQuery } from "features/clients/clientsApiSlice";
import ClientPanelForUsers from "components/ClientPanelForUsers/ClientPanelForUsers";
import { useParams } from "react-router-dom";
import { ControlTableIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { useDispatch } from "react-redux";
import { setClient } from "features/clients/clientSlice";

const SuperAdminPageClients = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { data, isLoading } = useGetClientByIdQuery(params.clientId);
  const client = isLoading ? null : data?.data?.client;

  useEffect(() => {
    if (!isLoading) {
      dispatch(setClient({ ...client }));
    }
  }, [client]);
  return (
    <MainLayout>
      <PageHeader leftIcon={<ControlTableIconSelected />} />
      {isLoading ? (
        <div>loading client details...</div>
      ) : (
        <ClientPanelForUsers client={client} />
      )}
    </MainLayout>
  );
};

SuperAdminPageClients.propTypes = {
  theme: PropTypes.any,
};

export default SuperAdminPageClients;
